.integration {
    width: 95%;
    margin: auto;
    margin-top: 80px;
    height: 417.134px;
    border-radius: 6.32px;
    border: 0.79px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 12.64042px 3.1601px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
}

.integration .intheader h2{
    color: #000;
    font-family: "Poppins";
    font-size: 18.961px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px 0px 16px ;
}
.integration .intcont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}
.integration .intcont h1{
    width: 381px;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 18.961px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.integration .intcont button{
    width: 179.835px;
    height: 32.391px;
    border-radius: 6.32px;
    background: #000;
    color: #FFF;
    font-family: "Poppins";
    font-size: 12.64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

@media screen and (max-width:600px) {
    .integration {
        width: 100%;
        height: 350px;
        margin-top: 30px !important;
    }
   
    .integration .intcont h1 {
        width: 100%;
        font-size: 15.961px;
        padding: 0 10px;
    }
    .integration .intcont {
        padding-top: 70px;
    }
    .integration .intcont .contbtn {
            padding-top: 20px;
    }
    
}