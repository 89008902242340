.getapi {
    max-width: 1239.52px;
    height: 122px;
    margin-top: 80px;
    border-radius: 6.507px;
border: 0.813px solid #B5B5B5;
background: #FFF;
box-shadow: 0px 0px 13.01333px 3.25333px rgba(0, 0, 0, 0.10);
padding: 14px 55px 33px 14px;
position: relative;
}
.getapi .apicont{
    display: flex;
    justify-content: space-between;
}
.getapi .apicont h2{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 19.52px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.getapi .apicont input{
    color: #BDBDBD;
    font-family: Poppins;
    font-size: 13.013px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 343.227px;
    height: 34.16px;
    flex-shrink: 0;
    border-radius: 6.507px;
    border: 0.813px solid #E0E0E0;
    background: #FFF;
    outline: none;
}
.getapi .apicont p{
    width: 250px;
    color: #000;
    font-family: Poppins;
    font-size: 11.387px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.getapi .apibtn {
    
    position: absolute;
    left: 27%;
    bottom: 20%;

}
.getapi .apibtn button  {
    color: #FFF;
    font-family: Poppins;
    font-size: 13.013px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 183.813px;
    height: 33.347px;
    flex-shrink: 0;
    border-radius: 6.507px;
    background: #000;
    border: none;


}

@media screen and (max-width:600px) {
    .getapi {
        padding: 14px 20px;
    }
    .getapi .apicont .head h2 {
        font-size: 22px !important;
    }
    .getapi .apicont{
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        justify-content: center;
        align-items: center;
    }
    .getapi {
        height: 350px;
        margin-top: 30px;
        position: relative;
        z-index: -1;
    }
    .getapi .apicont input {
        width: 220px;
        font-size: 12px;
        height: 30.16px;
       
    }
    .getapi .apicont p {
        width: 100%;
        font-size: 11px;
    }
    
    .getapi .btnmain .apibtn  {
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}
@media screen and (max-width:1300px) {
    .getapi{
        padding: 14px 14px 15px 14px;
    }
    .getapi .apicont input{
        width: 225px;
    }
    .getapi .apicont h2 {
        font-size: 18px !important;
    }
    .getapi .apibtn button {
        font-size: 10px ;
        width: 140px !important;
    }
    .getapi .apicont p{
        font-size: 10px;
    }
}