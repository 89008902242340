.test {
  text-align: center;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test .inner {
  /* width: 300px;
  border: 2px solid green; */
}

/* .test {
  height: 100vh;
  width: 100%;
  border: 2px solid blue;
  background-color: #fff;
  padding: 30px;
  display: flex;

  align-items: center;
  justify-content: flex-end;
}

.test .inner {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
