.introMessengerMainDiv {
  background-color: #f2f2f4;
  padding: 20px;
}
.introMessengerMainDiv .headingMain {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  align-self: center;
  margin: 0;
  margin-top: 50px;
}
.introMessengerMainDiv .headingSub {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: center;
  margin: 0;
  margin-top: 10px;
}

.introMessengerMainDiv .firstMainDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 23px;
  background: #f2f2f4;
  padding-left: 350px;
  padding-right: 350px;
  margin-top: 50px;
}
.introMessengerMainDiv .firstMainDiv .indust-card {
  width: 382.406px;
  height: 390.904px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  padding: 15px;
}
.introMessengerMainDiv .firstMainDiv .indust-card .imggg {
  object-fit: contain;
  width: 155.46px;
  height: 121.46px;
  margin-top: 50px;
}
.introMessengerMainDiv .firstMainDiv .indust-card .cardTitle {
  flex-shrink: 0;
  color: #000;
  font-family: "Poppins";
  font-size: 27.193px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 60%;
  text-align: center;
  margin: 0;
}

.introMessengerMainDiv .firstMainDiv .indust-card .cardText {
  width: 80%;
  flex-shrink: 0;
  color: #483f70;
  text-align: center;
  /* font-family: Poppins; */
  font-size: 20.395px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-top: 50px;
}
.introMessengerMainDiv .templateSection {
  margin-top: 25px;
  background-color: #33ffe0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.introMessengerMainDiv .templateSection .heading {
  color: #493f72;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  margin-top: 48px;
}
.introMessengerMainDiv .templateSection .headingTwo {
  color: #483f70;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 60%;
  align-self: center;
  margin: 0;
  margin-top: 5px;
}

.introMessengerMainDiv .templateSection .templateImage {
  margin-top: 30px;
}
.introMessengerMainDiv .templateSection .templateButton {
  display: inline-flex;
  padding: 1px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  border: 1px solid #000;
  margin-top: 20px;
}
.introMessengerMainDiv .templateSection .templateButton_text {
  color: #000;
  text-align: center;
  font-family: Coco Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.introMessengerMainDiv .courseExpertDiv {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.introMessengerMainDiv .courseExpertDiv .left {
  width: 724px;
  padding: 15px;
}
.introMessengerMainDiv .courseExpertDiv .left h4 {
  color: #000;
}
.introMessengerMainDiv .courseExpertDiv .right {
  width: 724px;
  padding: 15px;
}
.introMessengerMainDiv .courseExpertDiv .right h4 {
  color: #000;
}
.introMessengerMainDiv .courseExpertDiv .left .leftText {
  color: #000;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin: 0;
}

.introMessengerMainDiv .courseExpertDiv .right .rightText {
  color: #000;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.try {
  margin-top: 221px;
}

@media screen and (max-width: 1800px) {
  .introMessengerMainDiv .firstMainDiv {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media screen and (max-width: 1500px) {
  .introMessengerMainDiv .firstMainDiv {
    padding-left: 50px;
    padding-right: 50px;
  }

  .introMessengerMainDiv .firstMainDiv .indust-card {
    width: 100%;
  }
}
@media screen and (max-width: 1220px) {
  .introMessengerMainDiv .firstMainDiv {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (max-width: 800px) {
  .introMessengerMainDiv .firstMainDiv {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card {
    width: 90%;
    /* height: 360.904px; */
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
  .introMessengerMainDiv .courseExpertDiv .left {
    width: 100%;
    padding: 15px;
  }
  .introMessengerMainDiv .courseExpertDiv .right {
    width: 100%;
    padding: 15px;
  }
}

@media screen and (max-width: 770px) {
  .introMessengerMainDiv .courseExpertDiv {
    flex-direction: column;
  }

  .introMessengerMainDiv .firstMainDiv .indust-card {
    width: 80%;
    /* height: 360.904px; */
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }
}
@media screen and (max-width: 600px) {
  .introMessengerMainDiv .headingMain {
    font-size: 20px;
    font-weight: 600;
  }
  .introMessengerMainDiv .headingSub {
    font-size: 14px;
  }
  .introMessengerMainDiv .courseExpertDiv .right .rightText{
    font-size: 14px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card {
    width: 100%;
    padding: 0;
    height: 330px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card .cardTitle {
    font-size: 23px !important;
  }
  .introMessengerMainDiv .firstMainDiv {
    padding-left: 0px;
    padding-right: 0px;
  }
  .enterpreneur{
    padding: 0 !important;
    margin-top: 20px;
  }
  .introMessengerMainDiv .courseExpertDiv .left .leftText{
    font-size: 14px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card .cardText {
    font-size: 17px;
    margin-top: 25px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card .imggg {
    margin-top: 22px;
  }
  .introMessengerMainDiv .courseExpertDiv .left h4 {
    font-size: 23px;
    text-align: center;
  }
  .introMessengerMainDiv .courseExpertDiv .right h4 {
    font-size: 23px;
    text-align: center;
  }
  .introMessengerMainDiv .courseExpertDiv .left {
    padding: 0px;
    margin-top: 30px;
  }
  .introMessengerMainDiv .courseExpertDiv .right {
    padding: 0px;
    margin-top: 30px;
  }
  .try {
    margin-top: 10px;
  }
}
