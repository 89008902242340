.Messenger-node {
  color: black;
}

.Messenger-node .main {
  height: "250px";
  width: 200px;
  border: 1px dashed gray;
  border-radius: 10px;
  padding: 25px 0 25px 25px;
}

.Messenger-node .heading {
  padding-bottom: 10px;
}

.Messenger-node .content {
  color: gray;
  font-size: 12px;
  padding: 5px 0;
}

.Messenger-node .messenger-block {
  display: flex;
  /* align-items: "center"; */
}

.Messenger-node p {
  margin-bottom: 0;
}

.Messenger-node .block {
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 5px 0;
}

.Messenger-node .block p {
  color: black;
  padding-left: 15px;
}

.Messenger-node .block:hover {
  background-color: lightgray;
  border-radius: 30px;
  margin-right: 40px;
  padding-left: 10px;
}
