.ebook-example {
  background-color: #f2f2f4;
  padding: 101px 214px 60px 214px;
}
.ebook-example .headingMain {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  align-self: center;
  margin: 0;
  margin-top: 50px;
}
.ebook-example .headingSub {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: center;
  margin: 0;
  margin-top: 10px;
}
.ebook-example .subHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}
.ebook-example .subHeader .selectDivFirst {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.ebook-example .subHeader .selectDivFirst .selectDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}
.ebook-example .subHeader .selectDivFirst .selectDiv .selectDivText {
  color: #000;
  font-family: "Inter";
  font-size: 26.285px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.543px; /* 120% */
  margin-bottom: 0;
}
.ebook-example .subHeader .selectDivFirst .selectDiv .dropdownContent {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  height: 150px; /* Set a maximum height for the dropdown content */
  width: 100px;
  overflow-y: auto; /* Add a scrollbar when content overflows */
}
.ebook-example .subHeader .searchBar {
  display: flex;
  width: 600px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 57px;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ebook-example .subHeader .searchBar .inputStyle {
  width: 70%;
  height: 85%;
  border: none;
  outline: none;
  padding: 5px 5px 5px 20px;
  border-radius: 20px;
}

.ebook-example .subHeader .searchBar .seacrhButton {
  width: 30%;
  height: 100%;
  flex-shrink: 0;
  border-radius: 57px;
  background: #000;
  align-items: center;
  justify-content: center;
  display: flex;
}
.ebook-example .subHeader .searchBar .seacrhButton .searchButtonText {
  color: #fff;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.ebook-example .firstMainDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 23px;
  background-color: #edf2ed;
  margin-top: 20px;
}
.ebook-example .firstMainDiv .indust-card {
  background: white;
}
.firstMainDiv .indust-card img {
  width: 100%;
  /* min-height: 290px; */
}
.ebook-example .firstMainDiv .indust-card .cardTitle {
  color: #000;
  font-family: "Inter";
  font-size: 26.285px;
  font-style: normal;
  font-weight: 600;
  line-height: 31.543px; /* 120% */
  margin-left: 5px;
}

.ebook-example .templateSection {
  margin-top: 25px;
  background-color: #33ffe0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 46px;
}

.ebook-example .templateSection .heading {
  color: #493f72;
  text-align: center;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  margin-top: 48px;
}
.ebook-example .templateSection .headingTwo {
  color: #483f70;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 964px;
  align-self: center;
  margin: 0;
  margin-top: 5px;
}

.ebook-example .templateSection .templateImage {
  margin-top: 30px;
  width: 100%;
}
.ebook-example .templateSection .templateButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  border: 1px solid #000;
  margin-top: 20px;
}
.ebook-example .templateSection .templateButton button {
  background: transparent;
  border: none;
  color: #000;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 29px;
}
.ebook-example .templateSection .templateButton_text {
  color: #000;
  text-align: center;
  font-family: "Coco Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

@media screen and (max-width: 1500px) {
  .ebook-example {
    background-color: #f2f2f4;
    padding: 101px 150px 60px 150px;
  }
  .ebook-example .subHeader .searchBar {
    width: 350px;
    height: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .ebook-example {
    padding: 25px 60px 25px 60px;
  }
  .ebook-example .subHeader {
    /* flex-direction: column; */
    justify-content: space-between;
  }

  .ebook-example .subHeader .selectDivFirst {
    flex-direction: row;
    /* justify-content: space-between; */
    gap: 30px;
    padding-left: 10px;
    padding-right: 10px;
    width: 50%;
  }
  .ebook-example .subHeader .searchBar {
    width: 50%;
    align-self: center;
    height: 40px;
  }
  .ebook-example .firstMainDiv {
    margin-top: 50px;
  }
  .ebook-example .firstMainDiv .indust-card .cardTitle {
    font-size: 22.285px;
    padding: 15px;
  }
}
@media screen and (max-width: 825px) {
  .ebook-example {
    padding: 10px 10px 20px 10px;
  }
  .ebook-example .headingMain {
    font-size: 22px;
  }

  .ebook-example .headingSub {
    font-size: 18px;
  }

  .ebook-example .firstMainDiv {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .ebook-example .subHeader .searchBar .seacrhButton .searchButtonText {
    font-size: 13px;
  }
  .ebook-example .subHeader .selectDivFirst .selectDiv .selectDivText {
    font-size: 15.285px;
  }
  .ebook-example .subHeader .searchBar .inputStyle {
    font-size: 13px;
  }
  .ebook-example .subHeader{
    flex-direction: column;
    row-gap: 20px;
  }
  .ebook-example .subHeader .selectDivFirst {
    width: 100%;
    justify-content: space-between;
  }
  .ebook-example .subHeader .selectDivFirst {
    width: 100%;
  }
  .ebook-example .subHeader .searchBar {
    width: 100%;
    height: 35px;
  }
  .ebook-example .firstMainDiv .indust-card .cardTitle{
    font-size: 18px;
  }
  .ebook-example .templateSection {
    padding: 20px 15px;
  }
  .ebook-example .templateSection .heading{
    font-size: 23px;
    margin-top: 0;
  }
  .ebook-example .templateSection .headingTwo{
    font-size: 17px;
  }
  .ebook-example .templateSection .templateButton button{
    font-size: 13px;
  }
}
