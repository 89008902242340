.chat .chattop {
    margin-top: 63px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chat .chattop .row1 {
    width: 95%;
    height: 84px;
    border-radius: 4px;
    background: #F3F2F2;
    padding: 19px 51px 19px 33px;
    display: flex;
    justify-content: center;
}

.chat .chattop .row1 .ist{
    width: 30%;
}
.chat .chattop .row1 .ist p{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chattop .row1 .sec{
    width: 35%;
    display: flex;
    flex-direction: column;
}
.chat .chattop .row1 .sec .chk1 {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.chat .chattop .row1 .sec .chk2 {
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.chat .chattop .row1 .sec span{
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.chat .chattop .row1 .thi{
    width: 35%;
}
.chat .chattop .row1 .thi p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chattop .row2 {
    width: 95%;
    height: 84px;
    border-radius: 4px;
    background: #F3F2F2;
    display: flex;
    column-gap: 35px;
    padding: 22px 0px 44px 33px;
    
}
.chat .chattop .row2 .r2para p{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chattop .row2 .r2btn button  {
    width: 93px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #000;
    color: #FFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
}
.chat .chattop .row3 {
    display: flex;
    padding: 19px 51px 12px 33px;
    height: 140px;
    width: 95%;
    border-radius: 4px 4px 0px 0px;
    background: #F3F2F2;
}
.chat .chattop .row3 .r3col1 {
    width: 30%;
}
.chat .chattop .row3 .r3col2 {
    width: 35%;
}
.chat .chattop .row3 .r3col3 {
    width: 35%;
}
.chat .chattop .row3 .r3col1 p{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chattop .row3 .r3col2 .inpfield {
    column-gap: 10px;
    display: flex;
}
.chat .chattop .row3 .r3col2 .inpfield input {
    width: 111px;
    height: 24px;
    color: #000;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    border-radius: 4px;
    border: 1px solid #DDD;
    padding: 0 10px;
}
.chat .chattop .row3 .r3col2 .inpfield input::placeholder{
    padding-left: 8px;
}
.chat .chattop .row3 .r3col2 .updatebtn .butn {
    padding-top: 15px;
}
.chat .chattop .row3 .r3col2 .updatebtn p {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 7px;
} 
.chat .chattop .row3 .r3col2 .updatebtn button {
    width: 93px;
    height: 24px;
    border-radius: 4px;
    background: rgba(106, 106, 106, 0.66);
    color: #FFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
}
.chat .chattop .row3 .r3col3 p {
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.chat .chatbott .row4 .r4col1 {
    width: 30%;
}
.chat .chatbott .row4 .r4col2 {
    width: 35%;
}
.chat .chatbott .row4 .r4col3 {
    width: 35%;
}
.chat .chatbott .row4  {
    display: flex;
    justify-content: center;
    height: 70px;
    width: 95%;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid rgba(221, 221, 221, 0.87);
    background: #F3F2F2;
    padding: 19px 51px 10px  33px;
}
.chat .chatbott .row4 .r4col1 p {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chatbott .row4 .r4col2 label {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott .row4 .r4col2 .form-switch .btn{
       margin-top: 6px;
       
}
.chat .chatbott .row4 .r4col3 p {
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott .row5 .r5col1 {
    width: 30%;
}
.chat .chatbott .row5 .r5col2 {
    width: 35%;
}
.chat .chatbott .row5 .r5col3 {
    width: 35%;
}
.chat .chatbott .row5  {
    display: flex;
    justify-content: center;
    height: 70px;
    width: 95%;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid rgba(221, 221, 221, 0.87);
    background: #F3F2F2;
    padding: 19px 51px 10px  33px;
}
.chat .chatbott .row5 .r5col1 p {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chatbott .row5 .r5col2 label {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott .row5 .r5col2 .form-switch .btn{
       margin-top: 6px;
       
}
.chat .chatbott .row5 .r5col3 p {
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott .row6 {
    display: flex;
    height: 70px;
    width: 95%;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid rgba(221, 221, 221, 0.87);
    background: #F3F2F2;
    padding: 19px 51px 10px  33px;
}
.chat .chatbott .row6 .r6col1 {
    width: 30%;
}
.chat .chatbott .row6 .r6col2 {
    width: 35%;
}
.chat .chatbott .row6 .r6col1 p {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chatbott .row6 .r6col2 label {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chat .chatbott .row7 {
    display: flex;
    justify-content: space-between;
    height: 70px;
    width: 95%;
    border-radius: 4px 4px 0px 0px;
    border-top: 1px solid rgba(221, 221, 221, 0.87);
    background: #F3F2F2;
    padding: 19px 51px 10px  33px;
    align-items: center;
}
.chat .chatbott .row7 .r7col1 p {
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.chat .chatbott .row7 .r7col2 p {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 120px;
}
.chat .chatbott .row7 .r7col3 button {
    color: #FFF;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 176px;
    height: 24px;
    border-radius: 4px;
    background: #000;
    border: none;
}

@media screen and (max-width:1500px) {
    .chat .chatbott .row4 {
        height: 110px;
    }
    .chat .chatbott .row5 .r5col3 p{
        margin-top: 0;
    }
    .chat .chatbott .row5{
        height: 80px;
    }
    .chat .chattop .row3 .r3col2 .inpfield input{
        width: 73px !important;
    }
    .chat .chattop .row1{
        column-gap: 10px;
    }
    .chat .chattop .row3{
        column-gap: 10px;
    }
    .chat .chatbott .row4{
        column-gap: 10px;
    }
    .chat .chatbott .row5{
        column-gap: 10px;
    }
    .chat .chatbott .row6 {
        column-gap: 5px;
    }
    .chat .chatbott .row7 .r7col1 p {
        font-size: 14px;
    }
    .chat .chatbott .row6 .r6col1 p {
        font-size: 14px;
    }
    .chat .chatbott .row5 .r5col1 p {
        font-size: 14px;
    }
    .chat .chatbott .row4 .r4col1 p {
        font-size: 14px;
    }
    .chat .chattop .row3 .r3col1 p  {
        font-size: 14px;
    }
    .chat .chattop .row2 .r2para p  {
        font-size: 14px;
    }
    .chat .chattop .row1 .ist p {
        font-size: 14px;
    }
    .chat .chatbott .row7 .r7col2 p {
        padding-right: 0;
    }
}
@media screen and (max-width:1300px) {
    .chat .chattop .row1 {
        height: 100px;
    }
  
    
}
@media screen and (max-width:600px) {
    .chat .chattop {
        margin-top: 30px;
    }
    .chat .chattop .row1 {
        padding: 10px;
        height: 100%;
        flex-direction: column;
        row-gap: 8px;
    }
    .chat .chattop .row1 .ist{
        width: 100%;
    }
    .chat .chattop .row1 .ist p {
        margin-bottom: 5px;
    }
    .chat .chatbott .row5 .r5col3 p{
        margin-bottom: 0;
    }
    .chat .chattop .row1 .sec{
        width: 100%;
    }
    .chat .chattop .row1 .thi{
        width: 100%;
    }
    .chat .chattop .row2 {
        padding: 5px 10px;
        height: 100%;
        align-items: center !important;
    }
    .chat .chattop .row2 .r2para p {
        font-size: 12px;
    }
    .chat .chattop .row3 {
        padding: 10px;
        flex-direction: column;
        height: 100%;
        margin-top: 0px;
    }
    .chat .chattop .row3 .r3col1 {
        width: 100%;
    }
    .chat .chattop .row3 .r3col1 p{
        font-size: 12px;
    }
    .chat .chattop .row3 .r3col2 {
        width: 100%;
    }
    .chat .chattop .row3 .r3col2 .updatebtn p{
        font-size: 12px;
    }
    .chat .chattop .row3 .r3col3 {
        width: 100%;
    }
    .chat .chattop .row3 .r3col2 .updatebtn .butn {
        padding-top: 5px;
        padding-bottom: 8px;
    }

    .chat .chatbott .row4 {
        height: 100%;
        flex-direction: column;
        row-gap: 10px;
        padding: 10px ;
    }
    .chat .chatbott .row4 .r4col1 p {
        font-size: 12px;
        margin-bottom: 4px;
    }
    .chat .chatbott .row4 .r4col1 {
        width: 100%;
    }
    .chat .chatbott .row4 .r4col2 {
        width: 100%;
    }
    .chat .chatbott .row4 .r4col3 {
        width: 100%;
    }
    .chat .chatbott {
        margin-top: 25px;
    }
    .chat .chatbott .row4 .r4col2 label{
        font-size: 10px;
    }
    .chat .chatbott .row5{
        height: 100%;
        padding: 10px ;
        flex-direction: column;
        row-gap: 10px;
    }
    .chat .chatbott .row5 .r5col1 {
        width: 100%;
    }
    .chat .chatbott .row5 .r5col2 {
        width: 100%;
    }
    .chat .chatbott .row5 .r5col3 {
        width: 100%;
    }
    .chat .chatbott .row5 .r5col1 p{
        font-size: 12px;
        margin-bottom: 0;
    }
    .chat .chatbott .row5 .r5col2 label{
        font-size: 10px;
    }
    .chat .chatbott .row6 {
        height: 100%;
        padding: 10px ;
        flex-direction: column;
        row-gap: 10px;
    }
    .chat .chatbott .row6 .r6col1 {
        width: 100%;
    }
    .chat .chatbott .row6 .r6col2 {
        width: 100%;
    }
    .chat .chatbott .row6 .r6col1 p{
        font-size: 12px;
        margin-bottom: 0;
    }
    .chat .chatbott .row7 {
        height: 100%;
        padding: 10px ;
        align-items: start;
        flex-direction: column;
        row-gap: 10px;
    }
    .chat .chatbott .row7 .r7col1 p {
        font-size: 12px;
        margin-bottom: 0;
    }
    .chat .chatbott .row7 .r7col2 p {
        font-size: 10px;
        margin-bottom: 0;
        padding: 0;
    }
    .chat .chatbott .row7 .r7col3 button{
        font-size: 10px;
    }
}