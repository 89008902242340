// Blog-Tabs
.blog-tabs {
  margin: auto;
  width: 1593px;
  border-bottom: 2px solid black;
  padding-bottom: 26px;
  padding-top: 60px;
  //   .abba {
  //     .left {
  //       width: 100%;
  //       .tab-content {
  //         border-top: 2px solid black;
  //         //   padding: 26px 298px 67px 336px;
  //       }
  //       .nav-tabs {
  //         gap: 43px;
  //         border-bottom: none;
  //         .nav-item {
  //           button {
  //             color: #000;
  //             text-align: center;
  //             font-size: 16px;
  //             font-style: normal;
  //             font-weight: 400;
  //             line-height: normal;
  //             &:hover {
  //               text-decoration-line: underline;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .right {
  //       width: 297px;
  //       input {
  //         padding: 8px 0px 8px 240px;
  //         width: 100%;
  //         border: 1px solid #000;
  //         border-radius: 24px;
  //         padding-left: 20px;
  //       }
  //     }
  //   }
  .left-content {
    ul {
      display: flex;
      align-items: center;
      gap: 45px;
      li {
        font-family: "Coco Gothic";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        cursor: pointer;
        &.active {
          text-decoration: underline;
        }
        .dropdown {
          .btn-success {
            background: transparent;
            color: #000;
            border: none;
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  .right-content {
    input {
      padding: 8px 0px 8px 240px;
      width: 100%;
      border: 1px solid #000;
      border-radius: 24px;
      padding-left: 20px;
      padding-right: 20px;
      border: none;
    }
  }
}
// Tabs content SCSS
.blog-home {
  padding: 26px 100px 67px 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) !important;
      }
    }
  }
}
// Blog Home inner sections
.latest {
  padding: 25px 200px 71px 200px;
  background: #000;
  h3 {
    text-transform: uppercase;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left:50%;
        transform: translate(-50%,-50%) !important;
      }
    }
  }
}
.instagram {
  padding: 33px 200px 71px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.facebook {
  padding: 25px 200px 71px 200px;
  background: #000;
  h3 {
    text-transform: uppercase;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.email {
  padding: 33px 200px 71px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.sms {
  padding: 25px 200px 71px 200px;
  background: #000;
  h3 {
    text-transform: uppercase;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 70%;
        left: 16%;
      }
    }
  }
}
.trending {
  padding: 33px 200px 71px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
// E-commerce inner section
.eCommerce-banner {
  .freebotdm-title {
    text-align: center;
    padding: 45px 200px 117px 200px;
    p {
      width: 972px;
    }
    video {
      width: 1047px;
      margin-top: 50px;
    }
  }
}
.editor {
  padding: 33px 200px 124px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.latest-posts {
  padding: 33px 200px 124px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
    text-align: center;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 19px;
    .home-cards {
      width: 331.156px;
      // height: 314.307px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 226px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
// Creator
.creator-banner {
  background: #f5f5f7;
  .freebotdm-title {
    text-align: center;
    padding: 45px 200px 117px 200px;
    p {
      width: 972px;
    }
    video {
      width: 1047px;
      margin-top: 50px;
    }
  }
}
.creative-editor {
  padding: 33px 200px 124px 200px;
  background: #f5f5f7;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.creative-posts {
  padding: 33px 150px 124px 150px;
  background: #f5f5f7;
  h3 {
    text-transform: uppercase;
    color: #000;
    text-align: center;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 19px;
    .home-cards {
      width: 331.156px;
      // height: 314.307px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 226px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 50%;
        left: 16%;
      }
    }
  }
}
// Partner
.partner-banner {
  background: #f5f5f7;
  .freebotdm-title {
    text-align: center;
    padding: 45px 200px 117px 200px;
    p {
      width: 972px;
    }
    video {
      width: 1047px;
      margin-top: 50px;
    }
  }
}
.partner-editor {
  padding: 33px 200px 124px 200px;
  background: #f5f5f7;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.partner-posts {
  padding: 33px 150px 124px 150px;
  background: #f5f5f7;
  h3 {
    text-transform: uppercase;
    color: #000;
    text-align: center;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 19px;
    .home-cards {
      width: 331.156px;
      // height: 314.307px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 226px;
        color: #000;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 70%;
        left: 16%;
      }
    }
  }
}
// Event
.event-banner {
  .freebotdm-title {
    text-align: center;
    padding: 45px 200px 117px 200px;
    p {
      width: 972px;
    }
    video {
      width: 1047px;
      margin-top: 50px;
    }
  }
}
.event-editor {
  padding: 33px 200px 124px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    .home-cards {
      width: 411.873px;
      //   height: 249.864px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 282px;
        color: #fff;
        text-align: center;
        font-size: 16.12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 60%;
        left: 16%;
      }
    }
  }
}
.event-posts {
  padding: 33px 200px 124px 200px;
  // background: #000;
  h3 {
    text-transform: uppercase;
    color: #000;
    text-align: center;
  }
  .blog-parent {
    // display: flex;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 19px;
    .home-cards {
      width: 331.156px;
      // height: 314.307px;
      position: relative;

      img {
        position: relative;
      }
      p {
        width: 226px;
        color: #000;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        position: absolute;
        top: 70%;
        left: 16%;
      }
    }
  }
}
@media screen and (max-width: 1700px) {
  .blog-tabs {
    width: 100%;
    padding: 60px 144px;
  }
  .bloghome-abba {
    .latest {
      padding: 25px 150px 71px 150px;
    }
    .instagram {
      padding: 33px 100px 71px 100px;
    }
    .facebook {
      padding: 25px 150px 71px 150px;
    }
    .email {
      padding: 33px 100px 71px 100px;
    }
    .sms {
      padding: 25px 150px 71px 150px;
    }
    .trending {
      padding: 33px 100px 71px 100px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .blog-tabs {
    width: 100%;
    padding: 50px;
  }
  .bloghome-abba {
    .blog-home {
      padding: 26px 50px 67px 50px;
    }
    .blog-parent {
      .home-cards {
        width: 100%;
        p {
          font-size: 15px;
          // left: 15%;
        }
      }
    }
    .latest {
      padding: 25px 50px 71px 50px;
    }
    .instagram {
      padding: 33px 50px 71px 50px;
    }
    .facebook {
      padding: 25px 50px 71px 50px;
    }
    .email {
      padding: 33px 50px 71px 50px;
    }
    .sms {
      padding: 25px 50px 71px 50px;
    }
    .trending {
      padding: 33px 50px 71px 50px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .blog-tabs {
    padding: 30px 50px;
    .left-content {
      ul {
        gap: 30px;
        font-size: 17px;
        li {
          font-size: 17px !important;
        }
      }
    }
  }
  .bloghome-abba {
    .blog-home {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            // top: 40%;
          }
        }
      }
    }
    .latest {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
          }
        }
      }
    }
    .instagram {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            top: 50%;
          }
        }
      }
    }
    .facebook {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            top: 50%;
          }
        }
      }
    }
    .email {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            top: 50%;
          }
        }
      }
    }
    .sms {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            top: 50%;
          }
        }
      }
    }
    .trending {
      .blog-parent {
        .home-cards {
          p {
            width: 230px;
            top: 50%;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .blog-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 20px;
    .left-content {
      ul {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
        li {
          .dropdown {
            button {
              padding: 0;
            }
          }
        }
      }
    }
    .right-content {
      width: 100%;
      margin-top: 30px;
    }
  }
  .bloghome-abba {
    .blog-home {
      padding: 50px 20px;
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;

        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            // left: 26%;
          }
        }
      }
    }
    .latest {
      padding: 50px 20px;
        h3{
          text-align: center;
        }

      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        
        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;

          p{
            // left: 30%;
            // top: 50%;
          }
        }
      }
    }
    .instagram {
      padding: 50px 20px;

      h3{
        text-align: center !important;
      }
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            left: 30%;
          }
        }
      }
    }
    .facebook {
      padding: 50px 20px;
      h3{
        text-align: center;
      }
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            left: 30%;
          }
        }
      }
    }
    .email {
      padding: 50px 20px;
h3{
  text-align: center;
}
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .home-cards{
          display: flex;
          justify-content: center;
          align-content: center;
          p{
            left: 30%;
          }
        }
      }
    }
    .sms {
      padding: 50px 20px;
      h3{
        text-align: center;
      }
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            left: 30%;
          }
        }
      }
    }
    .trending {
      padding: 50px 20px;
      h3{
        text-align: center;
      }
      .blog-parent {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 24px;
        .home-cards{
          display: flex;
          justify-content: center;
          align-items: center;
          p{
            left: 30% !important;
          }
        }
      }
    }
  }
}
// Responsiveness of Inner pages
@media screen and (max-width: 1700px) {
  .eCommerce-banner {
    width: 100%;
  }
  .editor,
  .partner-editor,
  .creative-editor {
    padding: 33px 100px 124px 100px;
  }
  .latest-posts,
  .creative-posts,
  .partner-posts {
    padding: 33px 100px 124px 100px;
    .blog-parent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .home-cards {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .creator-banner,
  .event-banner,
  .partner-banner {
    .freebotdm-title {
      padding: 50px;
    }
  }
  .editor,
  .creative-editor,
  .partner-editor {
    padding: 33px 80px 124px 80px;
    .blog-parent {
      .home-cards {
        width: 100%;
        p {
          width: 250px;
          top: 50%;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .eCommerce-banner,
  .creator-banner,
  .partner-banner,
  .event-banner {
    .freebotdm-title {
      padding: 50px 20px;
      video {
        width: 100%;
      }
    }
  }
  .editor,
  .creative-editor,
  .partner-editor {
    padding: 33px 50px 80px 50px;
    .blog-parent {
      .home-cards {
        p {
          width: 190px;
          top: 40%;
          font-size: 14px;
        }
      }
    }
  }
  .latest-posts,
  .creative-posts,
  .partner-posts {
    padding: 33px 50px 80px 50px;
    .blog-parent {
      .home-cards {
        p {
          top: 50%;
          left: 11%;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .eCommerce-banner,
  .creator-banner,
  .event-banner,
  .partner-banner {
    .freebotdm-title {
      padding: 50px 20px 5px 20px;
      p {
        width: 100%;
        font-size: 22px;
        font-weight: 500;
      }
      h2 {
        font-size: 30px;
      }
      video {
        width: 100%;
      }
    }
  }
  .editor,
  .creative-editor,
  .partner-editor {
    padding: 50px 20px;

    h3{
      text-align: center;
      font-size: 30px;
    }
    .blog-parent {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      .home-cards{
        display: flex;
        justify-content: center;
        align-items: center;
        p{
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: #fff;
        }
      }
    }
  }
  .latest-posts,
  .creative-posts,
  .partner-posts {
    padding: 50px 20px;

    .blog-parent {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
      .home-cards {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }

}
