.actionCanvas {
  padding: 0px;
  margin: 0px;
  .actionCanvas {
    width: 100%;
    .title {
      width: 100%;
      height: 100px;
      background: #fdf0d0;
      display: flex;
      justify-content: center;
      align-items: center;
      h6 {
        font-size: 24px;
        font-family: "poppins";
        font-weight: 600;
      }
    }
    .MainContent {
      padding: 20px 10px 20px 10px;
      .titles {
        padding: 0px 0px 20px 0px;
        h6 {
          color: #414a59;
          font-size: 18px;
          font-weight: 400;
          font-family: "poppins";
        }
        button {
          border-color: #ffc302;
          color: #ffc302;
          font-size: 20px;
          font-weight: 500;
          font-family: "poppins";
          &:hover {
            background-color: #fdf0d0;
            color: #ffc302;
            border: 2px dashed #ffca1f;
          }
        }
      }
    }
  }
}
// New Modal
.modal-header {
  .header-add {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
  }
  .inputStyles {
    border: 1px solid #0084ff;
    height: 40px;
    width: 25%;
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    outline-color: #0084ff;
  }
}
.modal-body {
  .menuModal-add {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    .left {
      width: 20%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .top {
        .divOne {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          gap: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          .text {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 1;
            margin: 0;
          }
          &:hover {
            background: #d9d9d9;
          }
        }
        .divTwo {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          .text {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 1;
            margin: 0;
          }
          &:hover {
            background: #d9d9d9;
          }
        }
      }
      .bottom {
        background: #d0deeb;
        padding: 10px;
        border-radius: 8px;
        margin-right: 10px;
        .first-para {
          color: #0084ff;
          cursor: pointer;
        }
        p {
          font-size: 12px;
        }
      }
    }
    .right {
      height: 600px;
      width: 80%;
      padding: 5px 5px 50px 10px;
      overflow: scroll;
      // border: 2px solid red;
      box-shadow: 0px 6.29207px 18.87622px 0px rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      .heading {
        color: #000;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
        margin-top: 10px;
      }
      .subHeading {
        color: grey;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin: 0;
        margin-top: 2px;
      }
      .mapDiv {
        height: 70px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        border: 1px solid grey;
        padding: 8px;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          border: 2px solid #0084ff;
        }
      }
      .mapDivInner {
        height: 100%;
        .textOne {
          color: #000;
          font-family: "Poppins";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin: 0;
        }
        .textTwo {
          color: grey;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          margin: 0;
        }
      }
    }
  }
}


@media screen and (max-width:600px) {
  .modal-body{
    .menuModal-add{
      flex-direction: column !important;
      padding: 10px;

      .left{
        width: 100% !important;
        .top{
          .divOne{
            .text{
              font-size: 13px !important;
            }
          }
          .divTwo{
            p{
              font-size: 13px !important;
            }
          }
        }
        .bottom{
          margin: 13px;
          p{
            font-size: 11px;
          }
        }
      }
      .right{
        width: 100% !important;
        height: auto;
        .heading{
          font-size: 13px;
        }
        .subHeading{
          font-size: 11px;
        }
        .mapDivInner{
          .textOne{
            font-size: 13px;
          }
          .textTwo{
            font-size: 10px;
          }
        }
      }
    }
  }


  .actionCanvas{
    .actionCanvas{
      .title{
        height: 70px !important;
        h6{
          font-size: 18px !important;
        }
      }
    }
    .MainContent{
      .titles{
        button{
          font-size: 15px !important;
        }
        h6{
          font-size: 17px !important;
        }
      }
    }
  }
}
