.installtemp {
 
    width: 95%;
    height: 417.134px;
    margin: auto;
    margin-top: 80px;
    border-radius: 6.32px;
    border: 0.79px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 12.64042px 3.1601px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
}

.installtemp .tempheader h2{
    color: #000;
    font-family: Poppins;
    font-size: 18.961px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px 0px 16px ;
    border-bottom: 1px solid #B7B4B4;
}

.installtemp .tempcont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
}
.installtemp .tempcont h1{
    width: 381px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 18.961px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-bottom: 20px;
}
.installtemp .tempcont button{
    width: 186.614px;
    height: 32.283px;
    border-radius: 6.32px;
    background: #000;
    color: #FFF;
    font-family: Poppins;
    font-size: 12.64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}

@media screen and (max-width:600px) {
    .installtemp {
        width: 100%;
        height: 350px;
        margin-top: 30px;
    }
    .installtemp .tempheader h2 {
        font-size: 15px;
    }
    .installtemp .tempcont h1 {
        width: 100%;
        font-size: 15px;
    }
    .installtemp .tempcont {
        padding-top: 86px;
    }
    .installtemp .tempcont button {
        font-size: 10px;
    }
}