.footer-content {
  background: #f5f5f7;

  .Footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 236px;
    padding-right: 239px;
    padding-top: 90px;
    padding-bottom: 111px;

    .first-div {
      .footer-title {
        h3 {
          color: #000;

          font-family: "Montserrat";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      .footer-paragraph {
        width: 288px;
        margin-top: 26px;

        p {
          color: #000;
          font-family: "Montserrat";
          font-size: 15px;
          font-style: normal;
          font-weight: 500;
          line-height: 180%;
          /* 27px */
        }
      }

      .footer-links {
        ul {
          li {
            padding-top: 26px;
            .social-icons {
              display: flex;
              justify-content: space-between;
            }
            a {
              color: #000;
              font-family: "Montserrat";
              font-size: 15px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .footer-copyright {
    text-align: center;
    padding-bottom: 14px;

    span {
      color: #000;
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1400px) {
  .footer-content {
    background: #f5f5f7;

    .Footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 50px;

      .first-div {
        .footer-title {
          h3 {
            color: #000;

            font-family: "Montserrat";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .footer-paragraph {
          width: 288px;
          margin-top: 26px;

          p {
            color: #000;
            font-family: "Montserrat";
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 180%;
            /* 27px */
          }
        }

        .footer-links {
          ul {
           display: flex;
           flex-direction: column;
            margin-top: 10px;
            gap: 15px;
            li {
              padding-top: 0px;
              padding-bottom: 0px;

              .social-icons {
                display: flex;
                justify-content: space-between;
              }

              a {
                color: #000;
                font-family: "Montserrat";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .footer-copyright {
      text-align: center;
      padding-bottom: 14px;

      span {
        color: #000;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .footer-content {
    background: #f5f5f7;

    .Footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-left: 50px;
      padding-right: 50px;
      padding-top: 90px;
      padding-bottom: 111px;

      .first-div {
        .footer-title {
          h3 {
            color: #000;

            font-family: "Montserrat";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .footer-paragraph {
          width: 288px;
          margin-top: 26px;

          p {
            color: #000;
            font-family: "Montserrat";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 180%;
            /* 27px */
          }
        }

        .footer-links {
          ul {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            gap: 15px;
            li {
              padding-top: 0px;
              padding-bottom: 0px;
              
              .social-icons {
                display: flex;
                justify-content: space-between;
              }

              a {
                color: #000;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .footer-copyright {
      text-align: center;
      padding-bottom: 14px;

      span {
        color: #000;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .footer-content {
    background: #f5f5f7;

    .Footer {
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: center;
      width: 100%;
      // justify-content: space-between;
      padding: 50px 20px;

      .first-div {
        text-align: center;

        .footer-title {
          h3 {
            color: #000;

            font-family: "Montserrat";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        .footer-paragraph {
          width: 288px;
          margin-top: 26px;

          p {
            color: #000;
            font-family: "Montserrat";
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 180%;
            /* 27px */
          }
        }

        .footer-links {
          ul {
            li {
              padding-top: 0px;
              padding-bottom: 0px;

              .social-icons {
                display: flex;
                justify-content: space-between;
              }

              a {
                color: #000;
                font-family: "Montserrat";
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .footer-copyright {
      text-align: center;
      padding-bottom: 14px;

      span {
        color: #000;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
