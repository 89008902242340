.helpMainDiv {
  background-color: #f2f2f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.helpMainDiv .headingMain {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.helpMainDiv .searchBar {
  width: 70%;
  height: 60px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  align-self: center;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.helpMainDiv .searchBar .inputStyle {
  width: 100%;
  height: 100% !important;
  border: none;
  outline: none;
  padding: 5px 5px 5px 20px;
  border-radius: 30px;
}
.helpMainDiv .imagesDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 42px;
  padding: 10px;
  gap: 15px;
}
.helpMainDiv .imagesDiv .imagesTop {
  width: 30%;
}
.helpMainDiv .firstMainDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 75px 400px;
  gap: 38px;
  align-items: center;
  justify-content: center;
  /* background: #a7ecda; */
  background-image: url("../../assets/images/background.png");
}

.helpMainDiv .firstMainDiv .cardDivs {
  width: 327.81px;
  height: 175.45px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 7.21053px 21.63158px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.helpMainDiv .firstMainDiv .cardDivs .cardHeading {
  color: #2c2d30;
  text-align: center;
  font-family: Lato;
  font-size: 18.72px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  margin-top: 20px;
  width: 90%;
}
.helpMainDiv .firstMainDiv .cardDivs .cardText {
  color: #2c2d30;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
  margin-top: 20px;
  width: 70%;
}

@media screen and (max-width: 1250px) {
  .helpMainDiv .firstMainDiv {
    grid-template-columns: repeat(3, 1fr);
    padding: 75px 30px;
    gap: 30px;
  }
}

@media screen and (max-width: 1110px) {
  .helpMainDiv .firstMainDiv {
    grid-template-columns: repeat(2, 1fr);
    padding: 50px 20px;
    gap: 30px;
    place-items: center;
  }
}

@media screen and (max-width: 750px) {
  .helpMainDiv .headingMain {
    font-size: 25px;
    margin-top: 10px;
  }
  .helpMainDiv .searchBar {
    width: 70%;
    height: 42px;
  }
  .helpMainDiv .firstMainDiv {
    padding: 50px 10px;
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .helpMainDiv .firstMainDiv .cardDivs {
    width: 90%;
  }
}
