.broadcasting {
  padding: 55px 64px 421px 64px;
}

.broadcasting .bannerMain {
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background: #fff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.broadcasting .bannerMain .heading {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 140px;
}
.broadcasting .bannerMain .text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 400px;
  margin: 0;
  margin-top: 5px;
}

.broadcasting .bannerMain .button {
  width: 205px;
  height: 41px;
  border-radius: 8px;
  background: #000;
  padding: 9px 21px 9px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 140px;
}

.broadcasting .bannerMain .button .buttonText {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .broadcasting {
    padding: 20px 10px 20px 10px;
  }

  .broadcasting .bannerMain .heading {
    font-size: 18px;
    margin-top: 50px;
    text-align: center;
  }
  .broadcasting .bannerMain .text {
    font-size: 14px;
    width: 100%;
    margin-top: 5px;
  }

  .broadcasting .bannerMain .button .buttonText {
    font-size: 10px;
  }
}
