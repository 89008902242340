
.top-text h2 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}
.top-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile {
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile .outborder {
    position: relative;
    width: 317px;
    height: 604px;
    background: #fff;
    border: 1px solid lightgray;
    border-radius: 42px;
}
.profile .outborder .inrborder{
    width: 303px;
    height: 590px;
    background: #fff;
    border: 1px solid lightgray;
    border-radius: 36px;
    border: 8px solid lightgray;
    z-index: 1;
    position: absolute;
    top: 6px;
    left: 6px;
}
.modal-body {
    flex: 0 auto;
    padding: var(--bs-modal-padding);
}
.profile .outborder .inrborder .timetop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 20px;

}
.profile .outborder .inrborder .timetop p {
    font-size: 11px;
    font-weight: 400;
    color: #000;
    margin-bottom: 0;
}
.profile .outborder .inrborder .profilename {
    display: flex;
    column-gap: 10px;
    align-items: center;
    padding-left: 6px;
    align-items: center;
}
.profile .outborder .inrborder .profilename .pflogo img {
    background-color: brown ;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 25px;
}
.profile .outborder .inrborder .profilename h2 {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    font-family: "poppins";
    display: flex;
}
.profile .outborder .inrborder .profilename p {
    font-size: 11px;
    color: #8592A3;
    font-weight: 500;
    font-family: "poppins";
    margin-bottom: 0;
}
.profile .outborder .inrborder .pfdetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.profile .outborder .inrborder .pfdetail .pfimage img {
        width: 60px;
        height: 60px;
        background-color: brown;
        border-radius: 30px;
        padding: 10px;
}
.profile .outborder .inrborder .pfdetail h2 {
        font-size: 17px;
        color: #000;
        font-weight: 500;
        font-family: "poppins";
}
.profile .outborder .inrborder .pfdetail p {
        font-size: 13px;
        color: #000;
        font-family: "poppins";
}
.profile .outborder .inrborder .profilemsg {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-left: 19px;
}
.profile .outborder .inrborder .profilemsg .firstmsg {
    display: flex;
    align-items: center;
    column-gap: 13px;
}
.profile .outborder .inrborder .profilemsg .firstmsg  img {
    width: 25px;
    height: 25px;
}
.profile .outborder .inrborder .profilemsg .firstmsg p {
        font-size: 13px;
        color: #000;
        font-weight: 500;
        margin-bottom: 0;

}

.profile .outborder .inrborder .profilemsg .secondmsg  img {
    width: 25px;
    height: 25px;
}
.profile .fotor {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 2%;
}
.profile .fotor p {
    font-size: 11px;
    font-weight: 400;
    color: #B5B5B5;
    border-top: 1px solid lightgray;
    padding: 10px 15px 2px 15px;
    text-align: center;

}
.profile .fotor .started {
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile .fotor .started button {
    width: 270px;
    height: 34px;
    background-color: #007AFF;
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    font-family: "poppins";
    border-radius: 7px;
    border: none;
}