// popular  start
.popular {
    background: var(--dull-white);

    .freebotdm {

        .freebotdm-title {

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            p {
                color: #000;
                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            // .freestartBtn {
            //     padding: 12px 29px;
            //     border-radius: 36px;
            //     border: 1px solid #000;
            //     background: #000;
            // }
        }
    }

    .topContent {
        padding: 81px 200px 57px 280px;

        .title {
            h6 {
                color: var(--black);

                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .cards {

            display: flex;
            width: 100%;
            gap: 30px;

            .cardleft {
                width: 100%;
                // height: 344px;
                border-radius: 9px;
                background: #FFF;

                .cardTop {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 42px 0px 41px 0px;
                }

                .cardbottom {
                    padding: 0px 64px 30px 64px;

                    h6 {
                        color: #483F70;
                        font-family: "Poppins";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    p {
                        color: #483F70;
                        font-family: "Poppins";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .popular {
        background: var(--dull-white);



        .topContent {
            padding: 81px 20px 57px 20px;


        }
    }
}

@media screen and (max-width: 1000px) {
    .popular {
        background: var(--dull-white);

        .freebotdm {

            .freebotdm-title {

                h2 {
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                // .freestartBtn {
                //     padding: 12px 29px;
                //     border-radius: 36px;
                //     border: 1px solid #000;
                //     background: #000;
                // }
            }
        }

        .topContent {
            padding: 81px 20px 57px 20px;

            .title {
                h6 {
                    color: var(--black);

                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .cards {

                display: flex;
                width: 100%;
                gap: 10px;

                .cardleft {
                    width: 100%;
                    // height: 344px;
                    border-radius: 9px;
                    background: #FFF;

                    .cardTop {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 42px 0px 41px 0px;
                    }

                    .cardbottom {
                        padding: 0px 10px 30px 10px;

                        h6 {

                            font-size: 22px;

                        }

                        p {

                            font-size: 20px;

                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .popular {
        background: var(--dull-white);

        .freebotdm {


            .freebotdm-title {

                h2 {
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 27px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                // .freestartBtn {
                //     padding: 12px 29px;
                //     border-radius: 36px;
                //     border: 1px solid #000;
                //     background: #000;
                // }
            }
        }

        .topContent {
            padding: 81px 20px 57px 20px;

            .title {
                h6 {
                    color: var(--black);
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .cards {

                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 10px;

                .cardleft {
                    width: 100%;
                    // height: 344px;
                    border-radius: 9px;
                    background: #FFF;

                    .cardTop {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 42px 0px 41px 0px;
                    }

                    .cardbottom {
                        padding: 0px 0px 30px 0px;

                        h6 {

                            font-size: 22px;
                            text-align: center;

                        }

                        p {

                            font-size: 15px;

                        }
                    }
                }
            }
        }
    }
}

// popular end

// integrations start
.integrations {
    background: var(--black);
    width: 100%;
    padding: 32px 200px 106px 280px;

    .integration-content {
        .title {
            padding-bottom: 46px;

            h6 {
                color: var(--white);
                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            // flex-wrap: wrap;
            gap: 30px;

            .firstcard {
                border-radius: 9px;
                background: #FFF;
                box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
                width: 100%;
                // width: 705px;
                // height: 233px;

                .cardContent {
                    display: flex;
                    padding: 73px 29px 52px 48px;
                    justify-content: space-between;
                    // align-items: center;
                    gap: 39px;

                    .right {
                        h6 {
                            color: #483F70;
                            font-family: "Poppins";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        p {
                            color: #483F70;
                            font-family: "Poppins";
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .integrations {
        background: var(--black);
        width: 100%;
        padding: 32px 50px 106px 50px;


    }
}

@media screen and (max-width: 1000px) {
    .integrations {
        background: var(--black);
        width: 100%;
        padding: 32px 20px 106px 20px;

        .integration-content {
            .title {
                padding-bottom: 46px;

                h6 {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .cards {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                // flex-wrap: wrap;
                gap: 30px;

                .firstcard {
                    border-radius: 9px;
                    background: #FFF;
                    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
                    width: 100%;
                    // width: 705px;
                    // height: 233px;

                    .cardContent {
                        display: flex;
                        padding: 73px 10px 52px 10px;
                        justify-content: space-between;
                        // align-items: center;
                        gap: 20px;

                        .right {
                            h6 {

                                font-size: 20px;

                            }

                            p {

                                font-size: 19px;

                            }
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .integrations {
        background: var(--black);
        width: 100%;
        padding: 32px 20px 106px 20px;

        .integration-content {
            .title {
                padding-bottom: 46px;

                h6 {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 23px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .cards {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                // flex-wrap: wrap;
                gap: 20px;

                .firstcard {
                    border-radius: 9px;
                    background: #FFF;
                    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
                    width: 100%;
                    // width: 705px;
                    // height: 233px;

                    .cardContent {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 73px 0px 52px 5px;
                        justify-content: space-between;
                        // align-items: center;
                        gap: 10px;

                        .right {
                            h6 {

                                font-size: 20px;
                                text-align: center;

                            }

                            p {

                                font-size: 14px;
                                padding: 0 10px;

                            }
                        }

                    }
                }
            }
        }
    }
}

// integrations end 