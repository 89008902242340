.relation  {
    width: 97%;
    margin: auto;
    margin-top: 80px;
    height: 429px;
    border-radius: 6.502px;
    border: 0.813px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 13.00467px 3.25117px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.relation .cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.relation .cont h2{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.relation .cont span a{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
.relation .cont p{
    width: 481px;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.relation .cont .btn {
   
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.relation .cont button{
    color: #FFF;
    font-family: Poppins;
    font-size: 13.005px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border: none;
    width: 143.864px;
    height: 33.324px;
    text-align: center;
    border-radius: 8px;
    background: #000;
    
}
@media screen and (max-width:600px) {
    .relation .cont h2 {
        font-size: 18px;
        padding: 6px;
    }
    .relation .cont p {
        width: 100%;
        font-size: 12px;
        padding: 4px 8px;
    }
    .relation .cont .btn {
        width: 100px;
        height: 30px;
    }
    .relation {
        height: 350px;
        margin-top: 30px;
    }
}