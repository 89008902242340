// usebot start
.usebot {
    background: var(--dull-white);
    width: 100%;
    padding: 68px 474px 68px 474px;

    h6 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }

}

@media only screen and (max-width: 1600px) {
    .usebot {

        padding: 68px 100px 68px 100px;


    }
}

@media only screen and (max-width: 600px) {
    .usebot {
        background: var(--dull-white);
        width: 100%;
        padding: 68px 20px 6px 20px;

        h6 {

            font-size: 30px;

        }

        p {

            font-size: 14px;

        }

    }
}

//usebot end 

//imagegallery start
.imagegallery {
    width: 100%;
    background: var(--black);
    padding: 115px 268px 74px 268px;

    .gallery-content {
        display: grid;
        // gap: 19px;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);

        .firstImage {

            border-radius: 5.184px;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
            position: relative;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;


            }

            span {
                text-align: center;
                position: absolute;
                left: 0;
                // top: 70px;
                bottom: 70px;
                z-index: 3;
                color: #FFF;
                // text-align: center;
                font-family: "Poppins";
                font-size: 12.961px;
                font-style: normal;
                line-height: normal;
                width: 100%;
                padding: 0px 52px 0px 52px;

            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .imagegallery {

        padding: 115px 100px 74px 100px;

        .gallery-content {

            grid-template-columns: repeat(3, 1fr);

            .firstImage {





                span {

                    font-size: 10.961px;


                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .imagegallery {
        width: 100%;
        background: var(--black);
        padding: 115px 50px 74px 50px;

        .gallery-content {
            display: grid;
            // gap: 19px;
            width: 100%;
            grid-template-columns: repeat(2, 1fr);


        }
    }
}

@media only screen and (max-width: 600px) {
    .imagegallery {
        width: 100%;
        background: var(--black);
        padding: 115px 20px 74px 20px;

        .gallery-content {

            grid-template-columns: repeat(1, 1fr);


        }
    }
}

//imagegallery end
//courses-guide start
.courses-guide {


    .courses {
        padding: 70px 300px 72px 300px;
        background: var(--dull-white);

        .title {
            padding-top: 30px;
            background: white;

            h6 {
                color: #493F72;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
        }

        .courses-contents {
            width: 100%;
            // border-radius: 9px;
            background: #FFF;
            // height: 560px;
            display: flex;
            padding: 81px 71px 81px 71px;

            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 50px;
                // align-items: center;

                h6 {
                    color: #493F72;
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }

                p {
                    color: #483F70;
                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }

            .right {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .courses-guide {


        .courses {
            padding: 70px 100px 72px 100px;
            background: var(--dull-white);

        }
    }
}

@media only screen and (max-width: 1100px) {
    .courses-guide {


        .courses {
            padding: 70px 20px 72px 20px;
            background: var(--dull-white);

            .title {
                padding-top: 30px;
                background: white;

                h6 {

                    font-size: 30px;

                }
            }

            .courses-contents {
                width: 100%;
                // border-radius: 9px;
                background: #FFF;
                // height: 560px;
                display: flex;
                padding: 81px 71px 81px 71px;

                // .left {
                //     width: 50%;
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: center;
                //     padding-left: 50px;
                //     // align-items: center;

                //     h6 {

                //         font-size: 28px !important;

                //     }

                //     p {
                //         color: #483F70;
                //         font-family: "Poppins";
                //         font-size: 20px;
                //         font-style: normal;
                //         font-weight: 300;
                //         line-height: normal;
                //     }
                // }

                // .right {
                //     width: 50%;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;

                //     video {
                //         width: 100%;
                //         height: 100%;
                //     }
                // }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .courses-guide {


        .courses {
            padding: 70px 20px 72px 20px;
            background: var(--dull-white);

            .title {
                padding-top: 30px;
                background: white;

                h6 {

                    font-size: 30px;

                }
            }

            .courses-contents {
                width: 100%;
                // border-radius: 9px;
                background: #FFF;
                // height: 560px;
                display: flex;
                padding: 81px 71px 81px 71px;

                // .left {
                //     width: 50%;
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: center;
                //     padding-left: 50px;
                //     // align-items: center;

                //     h6 {

                //         font-size: 28px !important;

                //     }

                //     p {
                //         color: #483F70;
                //         font-family: "Poppins";
                //         font-size: 20px;
                //         font-style: normal;
                //         font-weight: 300;
                //         line-height: normal;
                //     }
                // }

                // .right {
                //     width: 50%;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;

                //     video {
                //         width: 100%;
                //         height: 100%;
                //     }
                // }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .courses-guide {


        .courses {


            .title {


                h6 {

                    font-size: 20px;

                }
            }

            .courses-contents {
                width: 100%;
                // border-radius: 9px;
                background: #FFF;
                // height: 560px;
                display: flex;
                padding: 81px 71px 81px 71px;

                // .left {
                //     width: 50%;
                //     display: flex;
                //     flex-direction: column;
                //     justify-content: center;
                //     padding-left: 50px;
                //     // align-items: center;

                //     h6 {

                //         font-size: 28px !important;

                //     }

                //     p {
                //         color: #483F70;
                //         font-family: "Poppins";
                //         font-size: 20px;
                //         font-style: normal;
                //         font-weight: 300;
                //         line-height: normal;
                //     }
                // }

                // .right {
                //     width: 50%;
                //     display: flex;
                //     justify-content: center;
                //     align-items: center;

                //     video {
                //         width: 100%;
                //         height: 100%;
                //     }
                // }
            }
        }
    }
}

//courses-guide end

//guide-gotback start
.guide-gotback {
    background: var(--dull-white);
}

//guide-gotback end

//guideFreebot  start
.guideFreebot {
    // background: var(--dull-white);

    .freebot-guide {
        background: var(--dull-white);
    }
}

//guideFreebot end