.billing {
    width: 97%;
    height: 274px;
    border-radius: 4px;
    background: #F3F2F2;
    margin: auto;
    margin-top: 63px;
    padding: 14px 22px 50px 18px;
}
.billing .subfee h2{
    display: flex;
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-left: 10px;
    border-bottom: 1px solid #B7B4B4;
}

.billing .inbox .ibhead {
    display: flex;
    align-items: flex-start;
    padding-left: 15px;
    margin-top: 7px;
}
.billing .inbox .ibhead p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 2px;
}
.billing .inbox .ibhead .prod{
    width: 30%;
}
.billing .inbox .ibhead .detls{
    width: 30%;
}
.billing .inbox .ibhead .price{
    width: 20%;
}
.billing .inbox .ibhead .status{
    width: 20%;
}
.billing .inbox .ibbott{
    display: flex;
    height: 118px;
    border-radius: 4px;
    border: 1px solid #B7B4B4;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
}
.billing .inbox .ibbott .firstcol{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.billing .inbox .ibbott .firstcol p{
    color: #000;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.billing .inbox .ibbott .secondcol{
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.billing .inbox .ibbott .secondcol p{
    color: #000;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.billing .inbox .ibbott .thridcol{
    width: 20%;
}
.billing .inbox .ibbott .thridcol p{
    color: #000;
    font-family: "Poppins";
    font-size: 8px;
    font-style: normal;
    font-weight:400;
    line-height: normal;
}
.billing .inbox .ibbott .thridcol span{
    color: #000;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.billing .inbox .ibbott .forthcol{
    width: 20%;
    display: flex;
    flex-direction: column;
}
.billing .inbox .ibbott .forthcol .swichbtn{
    margin: 0;
    position: relative;
    bottom: 10px;    
}
.billing .inbox .ibbott .forthcol .swichbtn2{
    margin: 0;
    position: relative;
    bottom: 5px;    
}



@media screen and (max-width:600px) {
    .billing{
        margin-top: 30px;
        width: 100%;
        padding: 10px;
    }
    .billing .subfee h2{
        margin-bottom: 8px;
    
    }
    .billing .inbox .ibbott .firstcol p{
        font-size: 11px;
    }
    .billing .inbox .ibbott .secondcol p{
        font-size: 9px;
    }
    .billing .inbox .ibbott .thridcol span{
        font-size: 9px;
    }
}