.mainbody {
    padding: 0px;

    .canvasBody {


        .conditionTitle {
            width: 100%;
            height: 80px;
            background-color: #9DEFE1;
            display: flex;
            justify-content: center;
            align-items: center;

            h6 {
                font-size: 22px;
                font-family: "Poppins";
            }
        }

        .middle {
            width: 100%;


            .content {
                padding: 30px 70px 20px 70px;

                p {
                    font-size: 20px;
                    font-family: "Poppins";
                    color: #383F4C;

                }

                span {
                    font-size: 15px;
                    font-family: "Poppins";
                    color: #0084FF;
                    cursor: pointer;
                }


            }

            .conditionSection {
                padding: 0px 10px 0px 10px;

                .conditionButton {

                    button {
                        border: 1px dashed #26DABC;
                        background: white;
                        color: #26DABC;
                        font-size: 15px;
                        font-family: "Poppins";
                    }

                    .bottom-divescancel {
                        width: 100%;
                        background: var(--dull-white);
                        // height: 200px;
                        border-radius: 5px;
                        border: 1px solid white;

                        .searchBar {
                            width: 100%;
                            padding: 10px 0px 0px 0px;

                            input {
                                width: 100%;
                                border: 1px solid gray;
                                height: 42px;
                                border-radius: 5px;

                                &:focus {
                                    border: 1px solid #0084FF;
                                }

                                &::placeholder {
                                    padding-left: 10px;
                                    font-size: 15px;
                                    font-family: "Poppins";
                                }

                            }
                        }

                        .filterSection {
                            border: 1px solid #E1E5EA;
                            padding: 10px 0px 10px 0px;
                            margin-top: 5px;
                            // display: flex;
                            width: 100%;

                            .leftSection {
                                width: 100%;

                                display: flex;
                                gap: 10px;
                                padding: 0px 10px 0px 10px;


                            }

                            .rightSection1 {
                                width: 100%;
                                padding: 0px 10px 0px 15px;

                                .innerText {
                                    .a {
                                        margin-block: 5px;
                                        cursor: pointer;

                                        // background: #E1E5EA;
                                        &:hover {
                                            background: #E1E5EA;
                                        }

                                        span {
                                            font-size: 15px;
                                            font-family: "Poppins";
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }

                            .rightSection2 {
                                width: 100%;
                                padding: 0px 10px 0px 15px;

                                .innerText {
                                    .a {
                                        margin-block: 5px;
                                        cursor: pointer;

                                        // background: #E1E5EA;
                                        &:hover {
                                            background: #E1E5EA;
                                        }

                                        span {
                                            font-size: 15px;
                                            font-family: "Poppins";
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }

                            .hidden {
                                display: none;
                            }


                        }
                    }
                }
            }

            .conditionc-step {
                width: 100%;
                padding: 5px 10px 5px 10px;

                .title {
                    margin-block: 10px;

                    h6 {
                        font-size: 15px;
                        font-family: "Poppins";
                        font-weight: 500;
                        color: #323946;
                    }
                }

                .anotherCondition {
                    width: 100%;
                    padding: 5px 10px 5px 10px;

                    .contents {

                        .firstSection {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .left {


                                .hr {
                                    width: 120px;
                                    color: #AAAEB3;
                                }
                            }

                            .middle {

                                text-align: center;

                                span {
                                    font-size: 15px;
                                    font-family: "Poppins";
                                    font-weight: 500;
                                    color: #323946;
                                }
                            }
                        }
                    }

                }
            }

            .nextstep-btn {
                width: 100%;
                padding: 5px 10px 5px 10px;
            }
        }
    }
}

@media screen and (max-width:600px) {
    .mainbody{
        .canvasBody{
            .conditionTitle{
                height: 50px !important;
                h6{
                    font-size: 18px;
                }
            }
            .middle{
                .content{
                    padding: 18px;
                    p{
                        font-size: 16px;
                    }
                }
                .conditionSection{
                    .conditionButton{
                        button{
                            font-size: 13px;
                            margin-top: 7px;
                        }
                    }
                }
            }
        }
    }
}