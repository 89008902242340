.randombody-canvas {
    padding: 0px;

    .randomBody {



        .randombody-content {
            background: #E0D7FB;
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            .randomTopbar {


                h6 {
                    font-size: 18px;
                    font-family: "Poppins";
                    font-weight: 700;
                }
            }
        }

        .randomMiddle {
            padding: 50px 34px 5px 34px;

            .middleContent {

                h6 {
                    color: #525964;
                    font-size: 15px;
                    font-weight: 600;
                    font-family: "Poppins";
                }

                p {
                    color: #232B39;
                    font-size: 13px;
                    font-weight: 400;
                    font-family: "Poppins";
                }

                .checkbox {
                    .checkboxContent {
                        span {
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }


                }

                .range {

                    .multi-range-field {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        color: #147FE3;
                        font-size: 15px;
                        font-weight: 600;
                        font-family: "Poppins";
                        padding-block: 30px;


                        .firstRange {
                            height: 6px !important;









                        }

                        // .scale {

                        //     background: red;

                        //     .rangeColor {
                        //         height: 10px;
                        //         background: red;
                        //     }
                        // }



                        .percentage {
                            padding: 10px 10px 10px 10px;
                            // width: 15%;
                            // height: 15%;
                            border: 1px solid #232B39;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;

                            span {
                                color: #232B39;
                                font-size: 15px;
                                font-weight: 600;
                                font-family: "Poppins";
                            }
                        }
                    }
                }

                .rangeSecond {

                    .multi-range-field {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        color: #00D7AA;
                        font-size: 15px;
                        font-weight: 600;
                        font-family: "Poppins";
                        padding-block: 30px;

                        .slider-div {
                            position: relative;

                            ._slider_1f5a9_9 {
                                color: red;
                                margin: 0;
                                // width: 100%;
                                height: 4px;
                                outline: none;
                                border-radius: 2px;
                                background: #c0c0c0;
                                -webkit-appearance: none;
                                appearance: none;

                            }

                            .span-btn {
                                position: absolute;
                                top: 65%;
                                left: 0%;
                                height: 4px;
                                width: 30%;
                                border-radius: 100px;

                                transform: translateY(-50%);
                                pointer-events: none;
                            }
                        }

                        .runnableTrack_1f5a9_120 {
                            position: absolute;
                            background: red !important;
                            background: red;
                            color: red;
                        }

                        .percentage {
                            padding: 10px 10px 10px 10px;
                            // width: 15%;
                            // height: 15%;
                            border: 1px solid #232B39;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 10px;

                            span {
                                color: #232B39;
                                font-size: 15px;
                                font-weight: 600;
                                font-family: "Poppins";
                            }
                        }
                    }
                }

                .newVariation {
                    margin-block: 30px;
                }

            }
        }
    }
}

@media screen and (max-width:600px) {
    .randombody-canvas{
        .randomBody{
            .randombody-content{
                height: 70px;
                .randomTopbar{
                    h6{
                        font-size: 16px;
                    }
                }
            }
            .randomMiddle{
                padding: 20px;
                .middleContent{
                    .range{
                        .multi-range-field{
                            padding-block: 20px;
                            .percentage{
                                padding: 6px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}