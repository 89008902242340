.profile-info {
  padding: 20px;
  background: #f5f5f7;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.profile-info .two-dropdowns {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  margin-bottom: 100px;
}
.profile-info .two-dropdowns .left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}
/* Modal 1*/
.modal-content {
  width: 100%;
}
.modal-title .logout-btn {
  background: transparent;
  border: none;
}
.modal-title .logout-btn:hover {
  color: red;
}
.modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-body .profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body .profile-image img {
  width: 100px;
  height: 100px;
  background: black;
  border-radius: 54px;
}
.modal-body .input-fields .for-name input {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #c9caca;
  padding-left: 20px;
  padding-right: 20px;
}
.modal-body .input-fields .for-name h6 {
  display: flex;
}
.modal-body .input-fields .for-email {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.modal-body .input-fields .for-email input {
  width: 80%;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #c9caca;
  padding-left: 20px;
  padding-right: 20px;
}
.modal-body .input-fields .for-email h6 {
  display: flex;
}
.modal-body .input-fields .for-email .verified {
  color: #87c6a6;
}
.modal-body .input-fields .for-email .two {
  display: flex;
  align-items: center;
}
.modal-body .input-fields .for-email button {
  width: 20%;
  border-radius: 4px;
  background: #0084ff;
  border: none;
  height: 35px;
  margin-left: 10px;
  color: white;
  font-family: "Coco Gothic";
}
/* Modal 2 */
.modal-body .body2 .body-para {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body .body2 .body-para p {
  width: 576px;
  text-align: center;
}
.modal-body .body2 .three-things {
  padding: 20px 0px;
  /* background: red; */
  display: flex;
  justify-content: space-around;
}
.modal-body .body2 .three-things .profile-sec {
  display: flex;
  gap: 20px;
}
.modal-body .body2 .three-things .profile-sec img {
  width: 30px;
  height: 30px;
  background: black;
  border-radius: 54px;
}
.modal-body .body2 .three-things .profile-sec .names p {
  margin-bottom: 0;
  font-size: 14px;
}
.modal-body .body2 .three-things .button-sec {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-body .body2 .three-things .button-sec button {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #0084ff;
  border: none;
  color: white;
  font-size: 20px;
}
.profile-info .two-dropdowns .dropdown .btn-success {
  background-color: transparent;
  color: black;
  border: none;
}
.modal-footer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modal-footer .bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.modal-footer .bottom button {
  border: none;
  background: transparent;
  color: #0084ff;
}
.modal-footer .bottom .icons {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-info .mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-info .mainDiv .add-account {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.profile-info .mainDiv .add-account .search label {
  display: flex;
  gap: 10px;
}
.profile-info .mainDiv .add-account .search label .search-input {
  padding-left: 20px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #adb4bf;
  height: 40px;
}

.profile-info .mainDiv .add-account button {
  padding: 5px 15px;
  border: none;
  border-radius: 8px;
  background-color: #3eb095;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.profile-info .mainDiv .first {
  /* display: grid; */
  /* grid-template-columns: 250px 250px 250px 250px 250px 250px 250px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* padding-bottom: 20px; */
  border: 1px solid #adb4bf;
  padding: 20px;
  border-radius: 8px;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.profile-info .mainDiv .first .text {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-info .mainDiv .first p {
  font-weight: bold !important;
  margin-bottom: 0;
}

.profile-info .mainDiv .first .textSubscribe {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-left: 80px; */
}

.profile-info .mainDiv .first .textPin {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin-right: 40px; */
}

.profile-info .mainDiv .first .button {
  width: 155px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  background: #000;
  align-items: center;
  justify-content: center;
}

.profile-info .mainDiv .first .button .text {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}

.profile-info .mainDiv .second {
  display: wrap;
  flex-direction: column;
  background: none !important;
  border: 1px solid #adb4bf;
  border-radius: 8px;
  width: 100%;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.profile-info .mainDiv .second .secondInner {
  /* display: grid; */
  /* grid-template-columns: 250px 250px 250px 250px 250px 250px 250px; */
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-around;
  padding-top: 30px;
  padding-bottom: 30px;
}

.profile-info .mainDiv .second .secondInner:hover {
  background-color: #c9caca;
  cursor: pointer;
}

.profile-info .mainDiv .second img {
  border-radius: 50%;
}

.profile-info .mainDiv .second p {
  margin-bottom: 0;
}

.profile-info .mainDiv .second .secondInner .text {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-info .mainDiv .third {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
}

.profile-info .mainDiv .third .button {
  width: 105px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  background: #000;
  align-items: center;
  justify-content: center;
}

.profile-info .mainDiv .third .button .text {
  color: #fff;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}
/* Help Modal  */
.help-modal .help-btn {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.help-modal .help-btn button {
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  gap: 5px;
}
.help-modal .help-btn p {
  margin-bottom: 0;
}
.help-body .bottom .two {
  gap: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 10px;
}
.help-body .bottom .two p {
  margin-bottom: 0;
}
.help-body .bottom .two:hover {
  background: #c9caca;
}
.modal-footer .help-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.modal-footer .help-footer button {
  background: transparent;
  color: #000;
  border: none;
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
}
.modal-footer .help-footer button:hover {
  background: #c9caca;
}
/* For search bar */
.search .container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search .container label {
  display: flex;
  align-items: center;
}
.search input[type="text"] {
  position: relative;
  padding: 15px 40px 15px 20px;
  width: 20px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
  border: none;
  border-radius: 5px;
  background-color: #3eb095;
  transition: width 0.4s ease;
  outline: none;

  &:focus {
    width: 200px;
  }
  &::placeholder {
    color: #fff;
  }
}
.search-icon {
  position: relative;
  left: -52px;
  color: #fff !important;
}

.social {
  position: absolute;
  right: 35px;
  bottom: 35px;

  img {
    display: block;
    width: 32px;
  }
}
@media screen and (max-width: 1400px) {
  .profile-info {
    padding: 100px 15px 40px 15px;
  }

  .profile-info .mainDiv .first .button {
    width: 120px;
  }

  .profile-info .mainDiv .first .button .text {
    font-size: 11px;
  }

  .profile-info .mainDiv .first .textPin {
    /* margin-right: 50px; */
  }
}

@media screen and (max-width: 1000px) {
  .profile-info .mainDiv .first .text {
    font-size: 13px;
  }
  .profile-info .mainDiv .first .textSubscribe {
    font-size: 13px;
  }
  profile-info .mainDiv .first .textPin {
    margin-right: 0;
    font-size: 13px;
  }
  .profile-info .mainDiv .first .textPin {
    font-size: 13px;
  }
}

@media screen and (max-width: 600px) {
  .profile-info .two-dropdowns {
    margin-bottom: 10px;
    gap: 0;
  }
  .profile-info .mainDiv .add-account button {
    width: 170px;
    height: 40px;
    font-size: 12px;
  }
  .profile-info .two-dropdowns img {
    width: 132px;
    height: 32px;
  }
  .search input[type="text"] {
    padding: 7px 40px 7px 20px;
  }
  .profile-info .two-dropdowns .left {
    gap: 7px;
  }
  .profile-info .mainDiv {
    flex-direction: column;
  }
  .profile-info {
    height: 100%;
    padding: 15px 10px;
    padding: 20px;
  }
  .profile-info .mainDiv .first {
    display: flex;
  }
  .search .container {
    padding: 0;
  }
  .profile-info .mainDiv .first {
    flex-direction: column;
    height: 100%;
    align-items: start;
    justify-content: flex-start;
    row-gap: 17px;
    padding: 16px;
    border-bottom: 1px solid #adb4bf;
  }
  .profile-info .mainDiv .second {
    display: none;
  }
  .profile-info .mainDiv .third {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .profile-info .mainDiv .third .button {
    position: relative;
    margin-right: 0;
  }
  .profile-info .mainDiv .first p {
    font-weight: 500 !important;
  }
  .profile-info .mainDiv .first .textPin {
    font-size: 13px;
  }
  .modal-body .input-fields {
    padding: 0 12px;
  }
}
