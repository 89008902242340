.whatsapp  {
    width: 97%;
    margin: auto;
    margin-top: 80px;
    height: 429px;
    border-radius: 6.502px;
    border: 0.813px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 13.00467px 3.25117px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.whatsapp .cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.whatsapp .cont h2{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.whatsapp .cont p{
    width: 470px;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.whatsapp .cont span a{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
.whatsapp .cont .btn {
    width: 130px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;
    display: flex;
    justify-self: center;
    align-items: center;
}
.whatsapp .cont button{
    color: #FFF;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border: none;
}
.whatsapp .cont .gethelp {
    padding-top: 10px;
}

.whatsapp .cont .gethelp a{
    color: #0057FF;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-decoration: none;
}

@media screen and (max-width:600px) {
    .whatsapp .cont p {
        width: 100%;
        font-size: 12px;
        padding: 4px 8px;
    }
    .whatsapp .cont h2 {
        font-size: 22px;
    }
    .whatsapp {
        /* width: 100%; */
        height: 350px;
        margin-top: 30px;
        padding: 10px;
    }
    .whatsapp .cont .btn {
        width: 100px;
        height: 30px;
    }
    .whatsapp .cont .gethelp a {
        font-size: 12px;
    }
}