// Messanger Banner
.messanger-banner {
  padding: 100px 228px;
  background: #f5f5f7;
  .left {
    width: 70%;
    h4 {
      width: 800px;
    }
  }
  .right {
    width: 30%;
    video {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.3);
    }
  }
}
// Deliver
.deliver {
  background: #f5f5f7;
  .deliver-content {
    padding: 26px 242px 83px 242px;
    .deliver-list {
      margin-top: 80px;
      width: 100%;
      ul {
        color: #000;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 38px;
        li {
          border-radius: 50.603px;
          background: #fff;
          width: 566px;
          height: 65.251px;
          display: flex;
          align-items: center;
          padding-left: 30px;
        }
      }
    }
  }
}
// Grow
.grow {
  padding: 64px 98px;
  background: #f5f5f7;
  .usechat-content {
    .right {
      margin-left: 15px;
      width: 50%;
    }
    .left {
      width: 50%;
    }
  }
}
// Drag
.drag {
  padding: 50px 247px;
  background: #000;

  .drag-content {
    p {
      max-width: 1246px;
    }
    .pic {
      border-radius: 9px;
      background: #000;
      padding: 50px 57px;
    }
  }
  .logos {
    padding: 46px 200px;
    gap: 60px;
  }
}
// Acticards
.acticards {
  // background: var(--dull-white);
  padding: 70px 300px;
  position: relative;
  p {
    max-width: 1219px;
  }
  .customer-cards {
    gap: 41px;
    .card {
      padding: 6px;
      width: 416.229px;
      height: 357.211px;
      border-radius: 6.212px;
      background: #fff;
      box-shadow: 0px 6.21237px 18.63711px 0px rgba(0, 0, 0, 0.2);

      .card-body {
        p {
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    .community {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 60%;
    }
    .blog {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 60%;
    }
    .agencies {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 60%;
    }
  }
}
// FAQ's
.faq {
  padding: 42px 281px 145px 281px;
  .accor {
    margin-top: 125px;
    .accordion {
      .accordion-item {
        border: none;
        border-bottom: 2px solid black;
      }
    }
  }
}
// Media Queries
@media screen and (max-width: 1700px) {
  .messanger-banner {
    padding: 100px 130px;
  }
  .deliver {
    .deliver-content {
      padding: 26px 150px 83px 150px;
      .deliver-list {
        ul {
          li {
            width: 100%;
          }
        }
      }
    }
  }
  .grow {
    padding: 64px 150px;
  }
  .drag {
    .logos {
      padding: 46px 20px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .messanger-banner {
    padding: 80px 100px;
    .left {
      width: 60%;
      h1 {
        font-size: 50px;
      }
      h4 {
        width: 100%;
        font-size: 30px;
      }
    }
    .right {
      width: 40%;
    }
  }
  .trusted .img-sec {
    gap: 60px;
  }
  .deliver {
    .deliver-content {
      padding: 26px 100px 83px 100px;
    }
  }
  .home-stepcards {
    .stepCard-title {
      h1 {
        font-size: 50px;
        .main-header {
          max-width: 60%;
        }
      }
    }
  }
  .grow {
    padding: 64px 100px;

    .usechat-content {
      .right {
        margin-left: 18px;
      }
    }
  }
  .drag {
    padding: 50px 100px;
    .logos {
      padding: 0;
      gap: 40px;
    }
    .drag-content {
      p {
        font-size: 20px;
      }
    }
  }
  .acticards {
    padding: 70px 100px;
    .customer-cards {
      gap: 30px;
      .card {
        width: 100%;
        height: 100%;
        .card-body {
          p {
            font-size: 20px;
          }
        }
      }
    }
  }
  .faq {
    padding: 42px 100px 145px 100px;
  }
  .freebotdm .freebotdm-title {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 45px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (max-width: 1200px) {
  .messanger-banner {
    padding: 50px;
    .left {
      width: 60%;
      h1 {
        font-size: 35px;
      }
      h4 {
        font-size: 20px;
      }
    }
    .right {
      width: 40%;
    }
  }
  .trusted {
    padding: 18px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .img-sec {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 100px;
      padding: 50px;
      margin-top: 0px !important;
    }
  }
  .deliver {
    .deliver-content {
      padding: 50px 50px;
      h1 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
      .deliver-list {
        ul {
          font-size: 20px;
        }
      }
    }
  }
  .home-stepcards {
    padding: 50px;
    .stepCard-title {
      h1 {
        font-size: 30px;
      }
    }
    .step-cards {
      padding: 0px;
      margin-top: 30px;
      .social-card-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
      }
    }
  }
  .grow {
    padding: 50px;
    .usechat-content {
      .left {
      }
      .right {
      }
    }
  }
  .drag .drag-content p {
    font-size: 17px;
  }
  .drag {
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .logos {
      margin-top: 50px;
      margin-bottom: 50px;
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      gap: 100px;
    }
  }
  .acticards {
    padding: 50px;
    p {
      font-size: 17px;
    }
    .customer-cards {
      .card {
        .card-body {
          p {
            font-size: 17px;
          }
        }
      }
    }
  }
  .faq {
    padding: 50px;
    .accor {
      margin-top: 50px;
    }
  }
  .freebotdm {
    .freebotdm-title {
      h2 {
        font-size: 40px;
        color: #000;
      }
      p {
        color: #000;
        font-size: 17px;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .messanger-banner {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .left {
      width: 100%;
      h1 {
        font-size: 26px;
      }
      h4 {
        font-size: 15px;
      }
      button {
        width: 100%;
        margin-bottom: 50px;
      }
    }
    .right {
      width: 100%;
    }
  }
  .trusted {
    padding: 50px 20px;
    span {
      font-size: 25px;
    }
    .img-sec {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 310px;
      gap: 70px;
    }
  }
  .deliver {
    .deliver-content {
      padding: 50px 20px;
      h1 {
        font-size: 30px;
      }
      p {
        font-size: 15px;
      }
      .deliver-list {
        margin-top: 30px;
        ul {
          display: flex;
          flex-direction: column;
          gap: 20px;
          li {
            font-size: 15px;
            height: 42px !important;
          }
        }
      }
    }
  }
  .home-stepcards {
    padding: 20px;
    .stepCard-title {
      h1 {
        max-width: 100% !important;
        font-size: 21px;
      }
    }
    .step-cards {
      .social-card-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }
    }
  }
  .grow {
    padding: 20px;
    .usechat-title {
      h1 {
        font-size: 23px;
      }
    }
    .usechat-content {
      display: flex;
      flex-direction: column;
      gap: 48px;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        margin-left: 0;
      }
    }
    .second {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .drag {
    padding: 50px 20px;
    .drag-content {
      h3 {
        font-size: 21px;
      }
      p{
        font-size: 13px;
        margin-top: 10px;
      }
      .pic{
        padding: 0px !important;
      }
    }
    .logos {
      display: flex;
      flex-direction: column;
      width: 320px;
      align-items: center;
      justify-content: center;
    }
    .free {
      button {
        width: 100%;
      }
    }
  }
  .acticards {
    padding: 50px 20px;
    h3 {
      font-size: 25px;
    }
    p{
      font-size: 13px;
    }
    .customer-cards {
      display: flex;
      flex-direction: column;
    }
  }
  .faq {
    padding: 50px 20px;
  }

  .freebotdm .freebotdm-title {
    padding: 50px 20px 10px 20px !important;
    button {
      width: 100%;
      min-height: 40px !important;
      font-size: 14px;
    }
    h2 {
      font-size: 25px;
      color: #000;
    }
  }
  .footer-content .Footer {
    padding: 50px 20px;
  }
  .combined {
  }
}
