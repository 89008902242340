/* Choose first step Sass */
.flow-messenger .choose {
  border: 2px dashed grey;
  border-radius: 24px;
  padding: 30px 50px;
  width: 400px;
  &:hover {
    border: 2px dashed #0084ff;
    border-color: #0084ff;
  }
}

.flow-messenger .choose h4 {
  color: #30363d;
}

.flow-messenger .choose .steps p {
  color: grey;
  font-size: 20px;
  cursor: pointer;
}

.flow-messenger .choose .steps .img {
  gap: 20px;
}

.flow-messenger .choose .steps .img p {
  color: #30363d;
}

.flow-messenger {
  .choose {
    .steps {
      .img {
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent !important;
          gap: 20px;
          // padding-left: 0px;
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.offcanvas {
  .Offcanvas.Header {
    .Offcanvas.title {
      text-align: center;
    }
  }

  .offcanvas-body {
    padding-bottom: 50px;
    background: ghostwhite;

    .drop {
      .dropdown {
        button {
          background: transparent;
          width: 100%;
          font-size: 16px;
          font-weight: 500;
          color: black;
          border: none;

          & span {
            border-bottom: 1px solid #808094;
            padding-bottom: 2px;
          }

          &::after {
            display: none;
          }
        }
      }
    }

    .for-bor {
      border-top: 1px solid grey;
      color: grey;
      font-size: 15px;
      font-weight: 400;
      text-align: center;
      margin-top: 50px;
      padding-top: 60px;
    }

    .boxes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 100px;
      gap: 30px;

      .box {
        display: flex;
        align-items: center;
        border: 1px dashed #adb4bf;
        padding: 10px;
        border-radius: 12px;
        width: 150px;
        gap: 10px;
        color: #adb4bf;
        cursor: pointer;

        &:hover {
          color: #0084ff;
          border-color: #0084ff;
        }
      }
    }

    button {
      color: #0084ff;
      width: 100%;
      margin-top: 50px;
      padding: 10px;
      border: 2px dashed #adb4bf;
      border-radius: 12px;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      background-color: transparent;

      &:hover {
        border-color: grey;
        background: #f1f3f5;
      }
    }
    .add-text-field {
      .section {
        margin-bottom: 30px;

        input {
          background: #ccdbe7;
          width: 100%;
          border-radius: 12px;
          padding: 10px 20px 30px 20px;
          border: 1px solid transparent;
          color: black;
          border-bottom-left-radius: 0%;
          border-bottom-right-radius: 0%;
          &:hover {
            border-color: #0084ff;
            border-bottom-left-radius: 0%;
            border-bottom-right-radius: 0%;
            border: 2px solid #0084ff;
          }
        }

        .add-btn {
          margin-top: 0;
          border: 1px dashed #adb4bf;
          border-bottom-right-radius: 12px;
          border-bottom-left-radius: 12px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          border-top: 0px;
          background: white;
          color: black;

          &:hover {
            border: 1px dashed #0084ff;
            color: #0084ff;
            border-color: #0084ff;
          }
        }

        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
          padding-top: 10px;
          padding-bottom: 10px;
          cursor: pointer;
        }
        &:hover {
          border: 2px solid #0084ff;
          border-radius: 12px;
          padding: 20px;
        }
      }
    }
    .add-image-field {
      .section {
        margin-top: 30px;
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
          padding-top: 10px;
          cursor: pointer;
          padding-bottom: 10px;
        }
        &:hover {
          border: 2px solid #0084ff;
          border-radius: 12px;
          padding: 20px;
        }
      }

      .add-image-field-inner {
        width: 100%;
        padding: 40px 20px;
        border: 1px dashed #adb4bf;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover {
          border-color: #0084ff;
          color: #0084ff;
        }

        img {
          width: 100%;
        }
      }
    }
    .add-card-field {
      // background: red;
      margin-top: 30px;
      cursor: pointer;

      .section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        gap: 10px;
        border-radius: 12px;
        margin-top: 30px;

        &:hover {
          border: 2px solid #adb4bf;
          color: #0084ff;
          border-color: #0084ff;
        }

        .close-icon {
          display: flex;
          justify-content: flex-end;
          width: 100%;
        }

        .choose-img {
          border: 1px dashed #adb4bf;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 20px;
          width: 100%;

          input {
            width: 80%;
            margin-top: 30px;

            &:hover {
              color: #0084ff;
              border-color: #0084ff;
            }
          }

          &:hover {
            color: #0084ff;
            border-color: #0084ff;
          }
        }

        .title {
          width: 100%;
          padding: 10px 30px 30px 10px;
          border: 1px dashed #adb4bf;
          border-radius: 12px;

          input {
            border: none;
            background: transparent;

            &::placeholder {
              font-weight: 700;
            }
          }

          &:hover {
            color: #0084ff;
            border-color: #0084ff;
          }
        }

        textarea {
          width: 100%;
          padding: 10px 30px 30px 10px;
          border: 1px dashed #adb4bf;
          border-radius: 12px;
          background: transparent;

          &:hover {
            color: #0084ff;
            border-color: #0084ff;
          }

          &::placeholder {
            font-weight: 500;
          }
        }

        .add-btn {
          color: #adb4bf;
        }
      }
    }
    .add-audio-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .audio-inner {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          border: 1px dashed #adb4bf;
          border-radius: 12px;
          padding: 30px 0px;
          gap: 20px;
          margin-top: 30px;
          margin-bottom: 30px;

          input {
            width: 60%;
          }

          p {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }

          &:hover {
            color: #0084ff;
            border: 1px dashed #0084ff;
          }
        }

        &:hover {
          border: 2px solid #0084ff;
          border-radius: 12px;
          padding: 10px;
        }
      }
    }
    .add-video-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }

        .upload-section {
          padding: 40px 60px;
          border: 1px dashed #808094;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 30px;
          margin-top: 30px;
          margin-bottom: 30px;

          input {
            width: 100%;
          }

          &:hover {
            border-color: #0084ff;
          }
        }

        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
      }
    }
    .add-delay-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }

        .upload-section {
          padding: 40px 60px;
          border: 1px dashed #808094;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 30px;
          margin-top: 30px;
          margin-bottom: 30px;

          input {
            width: 100%;
          }

          &:hover {
            border-color: #0084ff;
          }
        }

        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
        .delay-modal {
          .zam {
            background: red;
          }
        }
      }
    }
    .add-messenger-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }
        .upload-section {
          padding: 40px 10px;
          border: 1px dashed #808094;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 30px;
          margin-top: 30px;
          margin-bottom: 30px;
          select {
            width: 100%;
            border: 1px solid #adb4bf;
            border-radius: 4px;
            height: 40px;
            outline: none;
          }
          input {
            width: 100%;
          }
          &:hover {
            border-color: #0084ff;
          }
          .both {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px solid #adb4bf;
            border-radius: 4px;
            gap: 20px;
            padding: 20px 10px;
            input {
              width: 70%;
            }
          }
          h6 {
            display: flex;
          }
          p {
            font-size: 14px;
          }
          .sub {
            width: 100%;
            button {
              margin-top: 0 !important;
            }
          }
        }
        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
      }
    }
    .add-dynamic-field {
      .section {
        margin-top: 30px;
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }
        .upload-section {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 30px;
          margin-bottom: 30px;
          // background: #e8e0ff;

          .uploa {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            color: #b593fb;
            background: #e8e0ff;
            border: 1px solid #b593fb;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            padding-top: 30px;
          }
          .uploa-btn {
            width: 100%;
            button {
              border: 1px dashed #adb4bf;
              margin-top: 0 !important;
              border-top-right-radius: 0px;
              border-top-left-radius: 0px;
              border-top: 0px;
            }
          }
          input {
            width: 100%;
          }

          &:hover {
            border-color: #0084ff;
          }
        }

        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
      }
    }
    .add-messenger-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }
        .upload-section {
          padding: 40px 10px;
          border: 1px dashed #808094;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 30px;
          margin-top: 30px;
          margin-bottom: 30px;
          select {
            width: 100%;
            border: 1px solid #adb4bf;
            border-radius: 4px;
            height: 40px;
            outline: none;
          }
          input {
            width: 100%;
          }
          &:hover {
            border-color: #0084ff;
          }
          .both {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px solid #adb4bf;
            border-radius: 4px;
            gap: 20px;
            padding: 20px 10px;
            input {
              width: 70%;
            }
          }
          h6 {
            display: flex;
          }
          p {
            font-size: 14px;
          }
          .sub {
            width: 100%;
            button {
              margin-top: 0 !important;
            }
          }
        }
        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
      }
    }
    .add-dynamic-field {
      .section {
        .close-icon {
          display: flex;
          justify-content: flex-end;
          padding-bottom: 20px;
          cursor: pointer;
        }
        .upload-section {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin-top: 30px;
          margin-bottom: 30px;
          // background: #e8e0ff;

          .uploa {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            color: #b593fb;
            background: #e8e0ff;
            border: 1px solid #b593fb;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
            padding-top: 30px;
          }
          .uploa-btn {
            width: 100%;
            button {
              border: 1px dashed #adb4bf;
              margin-top: 0 !important;
              border-top-right-radius: 0px;
              border-top-left-radius: 0px;
              border-top: 0px;
            }
          }
          input {
            width: 100%;
          }
          &:hover {
            border-color: #0084ff;
          }
        }
        &:hover {
          padding: 10px 20px;
          color: #0084ff;
          border: 2px solid #0084ff;
          border-radius: 12px;
        }
      }
    }
  }
}
.offcanvas-backdrop.show {
  opacity: 0;
}

.custom-audio-input {
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.custom-audio-input:hover {
  background-color: #2980b9;
}


// random canvas

// mobile Responsive

@media screen and (max-width:600px) {
  .flow-messenger .choose {
    width: 90% !important;
    margin: auto;
    padding: 25px;
    h4{
      font-size: 24px;
    }
    .steps{
      p{
        font-size: 15px ;
      }
    }
  }
  .offcanvas{
    .offcanvas-body{
      button{
        font-size: 12px;
        margin-top: 20px ;
      }
    }
  }
}