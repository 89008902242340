// templateBanner start
.templateBanner {
    background: var(--dull-white);
    width: 100%;
    padding: 108px 235px 108px 398px;

    .banner-content {

        .title {
            text-align: center;

            h6 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            span {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

        .bannerBottom {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 50px;
            padding-top: 90px;

            .left {
                width: 100%;

                img {
                    width: 100%;
                }


            }

            .right {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .right-paragrapgh {
                    p {
                        color: #000;
                        // text-align: center;
                        font-family: "Poppins";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    .template-btn {
                        text-align: center;
                        margin-top: 117px;

                        button {
                            width: 244px;
                            height: 51px;
                            padding: 12px 29px;
                            border-radius: 36px;
                            border: 1px solid #000;
                            background: #000;
                            color: #FFF;
                            text-align: center;
                            font-family: " Coco Gothic";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1710px) {
    .templateBanner {
        background: var(--dull-white);
        width: 100%;
        padding: 108px 150px 108px 150px;


    }
}

@media only screen and (max-width: 1710px) {
    .templateBanner {
        background: var(--dull-white);
        width: 100%;
        padding: 108px 150px 108px 150px;


    }
}

@media only screen and (max-width: 1400px) {
    .templateBanner {
        background: var(--dull-white);
        width: 100%;
        padding: 108px 20px 108px 20px;


    }
}

@media only screen and (max-width: 800px) {
    .templateBanner {
        background: var(--dull-white);
        width: 100%;
        padding: 50px 20px 108px 20px;

        .banner-content {

            .title {
                text-align: center;

                h6 {

                    font-size: 22px;

                }

                span {

                    font-size: 18px;

                }
            }

            .bannerBottom {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                padding-top: 50px;

                .left {
                    width: 100%;

                    img {
                        width: 100%;
                    }


                }

                .right {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .right-paragrapgh {
                        p {

                            font-size: 17px;

                        }

                        .template-btn {
                            text-align: center;
                            margin-top: 40px;

                            button {
                                width: 100%;

                                font-size: 18px;

                            }
                        }
                    }
                }
            }
        }
    }
}

//templateBanner end 
//image card start
.template-imagecard {
    .imagecard {
        background: var(--dull-white);

        .turn {
            padding: 64px 290px;
            background: #f5f5f7;

            .usechat-content {
                .right {
                    margin-left: 13px;
                    width: 50%;

                    h6 {

                        color: #000;
                        font-family: "Poppins";
                        font-size: 100.58px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 130.333px;
                        /* 114.062% */
                    }
                }

                .left {
                    width: 50%;
                }
            }
        }
    }
}

@media only screen and (max-width: 1750px) {
    .template-imagecard {
        .imagecard {
            background: var(--dull-white);

            .turn {
                padding: 64px 200px;
                background: #f5f5f7;

                .usechat-content {
                    .right {
                        margin-left: 13px;
                        width: 50%;

                        h6 {

                            color: #000;
                            font-family: "Poppins";
                            font-size: 78.58px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 96.333px;
                            /* 114.062% */
                        }
                    }

                    .left {
                        width: 50%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .template-imagecard {
        .imagecard {
            background: var(--dull-white);

            .turn {
                padding: 64px 40px;
                background: #f5f5f7;

                .usechat-content {
                    .right {
                        margin-left: 13px;
                        width: 50%;

                        h6 {


                            font-size: 78.58px;

                            line-height: 96.333px;
                            /* 114.062% */
                        }
                    }

                    .left {
                        width: 50%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .template-imagecard {
        .imagecard {
            background: var(--dull-white);

            .turn {
                padding: 64px 40px;
                background: #f5f5f7;

                .usechat-content {
                    .right {
                        // margin-left: 13px;
                        margin: 0px;
                        text-align: center;
                        height: 100%;
                        width: 100%;

                        h6 {}
                    }

                    .left {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .template-imagecard {
        .imagecard {
            background: var(--dull-white);

            .turn {
                padding: 22px;
                background: #f5f5f7;

                .usechat-content {
                    .right {
                        // margin-left: 13px;
                        margin: 0px;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        h6 {
                            font-size: 25.58px;
                            line-height: normal;
                        }
                    }
                    .left {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// image card end 
//customer start
.customer {
    background: var(--dull-white);
    padding: 35px 413px 53px 288px;

    .customerContent {
        text-align: center;

        p {
            color: #483F70;
            text-align: center;
            font-family: "Poppins";
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        span {
            color: #483F70;
            text-align: center;
            font-family: "Poppins";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 2.9px;
        }
    }
}

@media only screen and (max-width: 1650px) {
    .customer {
        background: var(--dull-white);
        padding: 35px 200px 53px 200px;

        .customerContent {
            text-align: center;

            p {
                color: #483F70;
                text-align: center;
                font-family: "Poppins";
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            span {
                color: #483F70;
                text-align: center;
                font-family: "Poppins";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 2.9px;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .customer {
        background: var(--dull-white);
        padding: 35px 40px 53px 40px;


    }
}

@media only screen and (max-width: 600px) {
    .customer {
        background: var(--dull-white);
        padding: 35px 10px 53px 10px;

        .customerContent {
            text-align: center;

            p {

                font-size: 13px;
            }

            span {

                font-size: 8px;

            }
        }
    }
}


// customer end 