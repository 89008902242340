.naver-banner {
  padding: 80px 100px;
  background: #f5f5f7;
  gap: 35px;
  .left {
    width: 65%;
    h1 {
      width: 100%;
    }
    h4 {
      width: 1027px;
    }
  }
  .right {
    width: 35%;
    .banner-img {
      width: 100%;
    }
  }
}
// Safe
.safe {
  background: #f5f5f7;
  p {
    width: 100%;
  }
  .two-div {
    .top {
      padding: 119px 100px;
      p {
        width: 1288px;
      }
      .usechat-content {
        .right {
          margin-left: 13px;
          width: 50%;
        }
        .left {
          width: 50%;
        }
      }
    }
    .bottom {
      padding: 72px 216px;
      background: #000;
      .usechat-content {
        gap: 38px;
      }
      .dm-bot {
        .discover-content {
          gap: 93px;
          //   background-color: red;
        }
      }
    }
  }
}
// Faq
.naver-faq {
  padding: 42px 281px 145px 281px;
  .accor {
    margin-top: 125px;
    .accordion {
      .accordion-item {
        border: none;
        border-bottom: 2px solid black;
      }
    }
  }
}

// ----------------------Media Queries---------------
@media screen and (max-width: 1700px) {
  .naver-banner {
    padding: 100px 150px;
    .left {
      h1 {
        font-size: 50px;
      }
      h4 {
        width: 100%;
      }
    }
  }
  .safe {
    .two-div {
      .bottom {
        padding: 50px 170px;
        .dm-bot {
          .discover-content {
            gap: 80px;
            .content {
              p {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .naver-banner {
    padding: 50px 100px;
    gap: 30px;
    .left {
      h1 {
        font-size: 40px;
      }
      h4 {
        font-size: 27px;
      }
    }
  }
  .safe {
    .two-div {
      .top {
        h2 {
          font-size: 40px;
        }
        p {
          font-size: 27px;
          width: 100%;
        }
      }
      .bottom {
        padding: 50px 130px;
        h2 {
          font-size: 40px;
        }
        .dm-bot {
          .discover-content {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            .content {
              p {
                width: unset;
              }
            }
          }
        }
      }
    }
  }
  .naver-faq {
    padding: 50px 150px;
    .accor {
      margin-top: 50px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .naver-banner {
    padding: 50px;
    gap: 30px;
    .left {
      h1 {
        font-size: 24px;
      }
      h4 {
        font-size: 20px;
      }
    }
  }
  .safe {
    .two-div {
      .top {
        padding: 50px;
        h2 {
          font-size: 30px;
        }
        p {
          font-size: 20px;
        }
      }
      .bottom {
        padding: 50px;
        h2 {
          font-size: 30px;
        }
        .dm-bot {
          .discover-content {
            .content {
              h3 {
                font-size: 30px;
              }
              h4 {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
  .naver-faq {
    padding: 50px;
  }
}
@media screen and (max-width: 600px) {
  .naver-banner {
    padding: 50px 20px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    .left {
      width: 100%;
      h1 {
        font-size: 22px;
        text-align: center;
      }
      h4 {
        font-size: 17px;
      }
      button {
        width: 100%;
      }
    }
    .right {
      width: 100%;
    }
  }
  .safe {
    .two-div {
      .top {
        padding: 0px 20px 50px 20px;
        h2 {
          font-size: 20px;
        }
        p {
          font-size: 17px;
          margin-top: 20px;
        }
        .usechat-content {
          margin-top: 0px !important;
          flex-direction: column-reverse;
          .left {
            width: 100%;
            margin-top: 30px;
          }
          .right {
            width: 100%;
            margin-top: 30px;
            margin-left: 0;
          }
        }
      }
      .bottom {
        padding: 50px 20px 50px 20px;
        h2 {
          font-size: 20px;
        }
        .usechat-content {
          flex-direction: column;
          .left {
            width: 100%;
          }
          .right {
            width: 100%;
          }
        }
        .dm-bot {
          h2 {
            font-size: 25px;
          }
          .discover-content {
            display: grid !important;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            .content {
              h3 {
                font-size: 20px;
              }
              h4 {
                font-size: 17px;
              }
              p {
                font-size: 16px;
              }
            }
          }
          .search-btn {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
  .naver-faq {
    padding: 50px 20px 50px 20px;
    h1 {
      font-size: 40px;
    }
    .accor {
      margin-top: 20px;
    }
  }
}
