.newlogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background: red; */
}

.newlogin .image {
  z-index: -1;
  position: absolute;
  width: 100%;
}

.newlogin .upper {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.newlogin .upper .extra {
  width: 135px;
}

.newlogin .upper .text {
  color: #1a1a1a;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.newlogin .upper .button {
  width: 136px;
  height: 48px;
  border-radius: 4px;
  border: 1.5px solid #212121;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.newlogin .center .buttonSubmit {
  background: #87e5cd;
  width: 365px;
  height: 34px;
  border-radius: 50px;
  color: #212121;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-size: 12.8px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  border: none;
}

.newlogin .upper .button .text {
  color: #212121;
  text-align: right;
  font-family: Poppins;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.newlogin .center {
  width: 572px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 82px 40px -14px rgba(100, 100, 100, 0.08);
  margin-top: 180px;
  /* padding-top: 20px; */
  /* padding-bottom: 160px; */
  justify-content: center;
  height: 500px;
}

.newlogin .center .text {
  color: #1a1a1a;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.newlogin .center .heading {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 367px;
  margin: 0;
  margin-top: 25px;
}

.newlogin .center .inputstyles {
  width: 367px;
  height: 34px;
  border-radius: 50px;
  border: 1px solid #87e5cd;
  background: rgba(135, 229, 205, 0);
  margin-top: 8px;
  padding-left: 10px;
}

.newlogin .center .button {
  width: 365px;
  height: 34px;
  border-radius: 50px;
  background: #87e5cd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 25px;
}

.newlogin .center .button .text {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.newlogin .center .divvv {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 30px;
}

.newlogin .center .divvv .textOne {
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.newlogin .center .divvv .textTwo {
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.newlogin .center .textOR {
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-top: 8px;
}

.newlogin .center .imagesDiv {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 8px;
}

.newlogin .lower {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 100px 70px 50px 70px;
}

.newlogin .lower .text {
  color: #616161;
  text-align: right;
  font-family: Zen Kaku Gothic Antique;
  font-size: 10.29px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
}

.newlogin .lower .extra {
  width: 85px;
}

/* Buttons */
.newlogin .newlogin-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  max-width: 500px;
  font-family: "Poppins";
}
.newlogin .newlogin-button button {
  padding: 10px 0px;
  width: 100%;
  background: #a2ead7;
  border: 1px solid #a2ead7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media screen and (max-width: 650px) {
  .newlogin .upper .extra {
    width: 3px;
  }

  .newlogin .upper .text {
    font-size: 18px;
  }

  .newlogin .upper .button {
    width: 100px;
    height: 38px;
  }

  .newlogin .center {
    width: 90%;
    margin-top: 80px;
  }

  .newlogin .center .heading {
    width: 95%;
    font-size: 14px;
  }

  .newlogin .center .inputstyles {
    width: 95%;
  }

  .newlogin .center .button {
    width: 95%;
  }

  .newlogin .lower .extra {
    width: 5px;
  }
}

.customPointer {
  cursor: pointer;
}
