@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


@font-face {
  font-family: "Coco Gothic";
  src: url("../assets/fonts/CocoGothic_trial.ttf");
}

@font-face {
  font-family: "Rugen";
  src: url("../../public/assets/font/rugenexpanded-dokge.otf");
}

// ----------------------------------------Text-Colors
.text-purple {
  color: #8a6eff;
}

// -----------Color Classes

// ----------------------Font sizes
h1 {
  color: #000;
  font-family: "Poppins";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2 {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h2.one {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h3 {
  color: #fff;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

h3.medium {
  color: #fff;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

h4 {
  color: #fff;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h4.large {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

h4.medium {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

h4.small {
  font-size: 30px;
  font-weight: 500;
  color: #0a0a0a;
}

h5 {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p {
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.one {
  color: #000;
}

p.body-large {
  font-size: 24px;
  color: #000;
}

p.medium {
  font-size: 15px;
  color: #483f70;
}

p.small {
  color: #483f70;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.small-1 {
  color: #483f70;
  font-family: "Poppins";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

p.small-2 {
  color: #483f70;
  font-family: "Poppins";
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-light-purple {
  color: #493f72 !important;
}

p.large {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

p.large1 {
  color: #fff;
  font-size: 32px;
  font-weight: 400;
}