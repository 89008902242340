* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // outline: 1px solid hotpink;


}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
a,
.row,
.col {
  padding: 0;
  margin: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

// ---------------custom margins

.mt-30 {
  margin-top: 30px;
}

// ---------------custom image sizes
.img-100 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input {
  outline: none;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-outer-spin-button {
    display: none;
  }
}

.v-center {
  display: flex;
  align-items: center;
}

.h-center {
  display: flex;
  justify-content: center;
}

// Color classes
.purple {
  color: var(--purple);
}

.light-purple {
  color: var(--light-purple);
}

.dark-purple {
  color: var(--dark-purple);
}

.foot {
  background: #f5f5f7;
}

.dull-white {
  background: var(--dull-white);
}

.pink {
  background: var(--pink);
}

.white {
  background: #fff;
}

.black-color {
  background: #000;
}