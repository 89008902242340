.livechat {
  padding: 0px 0px 100px 0px;
}

.livechat .mainDiv {
  display: flex;
  flex-direction: row;
}

/* Section 1 starts from herre */
.livechat .mainDiv .firstSection {
  width: 300px;
}

.livechat .mainDiv .firstSection .first .heading {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-left: 5px;
}

.livechat .mainDiv .firstSection .first .subHeading {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-left: 15px;
  margin-top: 5px;
  cursor: pointer;
}

.livechat .mainDiv .firstSection .second .headingTwo {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-left: 5px;
  margin-top: 30px;
}

.livechat .mainDiv .firstSection .second .subHeading {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-left: 15px;
  margin-top: 5px;
  cursor: pointer;
}

/* Section 2 starts from herre */
.livechat .mainDiv .secondSection {
  width: 300px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.livechat .mainDiv .secondSection .upper {
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  height: 50px;
  width: 100%;
}

.livechat .mainDiv .secondSection .upper .heading {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-left: 5px;
}

.livechat .mainDiv .secondSection .upper .subHeading {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-left: 5px;
}

.livechat .mainDiv .secondSection .center {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.livechat .mainDiv .secondSection .center .centerDesign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 150px;
}

.livechat .mainDiv .secondSection .center .centerDesign .text {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .secondSection .center .centerDesign .button {
  width: 182px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.livechat .mainDiv .secondSection .center .centerDesign .button .text {
  color: #3f89f9;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .secondSection .center .centerDesignContact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-top: 5px;
  background: #fff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
}

.livechat .mainDiv .secondSection .center .centerDesignContact .inner {
  width: 55%;
}

.livechat .mainDiv .secondSection .center .centerDesignContact .inner .textUserName {
  color: #5a677d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .secondSection .center .centerDesignContact .inner .textsecond {
  color: #5a677d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .secondSection .center .centerDesignContact .textTime {
  color: #5a677d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .secondSection .lower {
  height: 50px;
  width: 100%;
}

/* Section 3  or main banner starts from herre */
.livechat .mainDiv .thirdSection {
  height: 100vh;
  width: 80%;
  display: flex;
  justify-content: center;
}

.livechat .mainDiv .thirdSection .chatDivMain {
  width: 100%;
  height: 85vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.livechat .mainDiv .thirdSection .chatDivMain .header {
  width: 100%;
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #3eb095;
  padding-left: 10px;
  padding-right: 10px;
}

.livechat .mainDiv .thirdSection .chatDivMain .header .innerDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.livechat .mainDiv .thirdSection .chatDivMain .header .innerDiv .text {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .thirdSection .chatDivMain .center {
  width: 100%;
  height: 75%;
  padding: 10px;
  overflow: scroll;

}

.livechat .mainDiv .thirdSection .chatDivMain .center .sender {
  width: 100%;
  margin-top: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

}

.livechat .mainDiv .thirdSection .chatDivMain .center .sender .textSender {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  background: #3399ff;
  border-radius: 15px;
  padding: 10px;
  max-width: 300px;
  width: fit-content;
}

.livechat .mainDiv .thirdSection .chatDivMain .center .sender .timeText {
  color: grey;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-left: 10px;
}

.livechat .mainDiv .thirdSection .chatDivMain .center .reciever {
  width: 100%;
  margin-top: 5px;
}

.livechat .mainDiv .thirdSection .chatDivMain .center .reciever .textReciever {
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  background: #d58f7c;
  border-radius: 15px;
  padding: 10px;
  max-width: 300px;
  width: fit-content;
}

.livechat .mainDiv .thirdSection .chatDivMain .center .reciever .timeText {
  color: grey;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-left: 10px;
}

.livechat .mainDiv .thirdSection .chatDivMain .bottom {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.livechat .mainDiv .thirdSection .chatDivMain .bottom .inputField {
  padding: 12px;
  width: 70%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
}

.livechat .mainDiv .thirdSection .chatDivMain .bottom .button {
  height: 50px;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3eb095;
  border-radius: 5px;
  cursor: pointer;
}

.livechat .mainDiv .thirdSection .chatDivMain .bottom .button .text {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .bannerMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.livechat .mainDiv .bannerMain .text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  width: 70%;
}

.livechat .mainDiv .bannerMain .button {
  width: 182px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 4px;
  background: #ddd;
}

.livechat .mainDiv .bannerMain .button .textOne {
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.livechat .mainDiv .bannerMain .button .textTwo {
  color: #3f89f9;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .livechat .mainDiv {
    flex-direction: column;
  }

  .livechat .mainDiv .firstSection {
    width: 100%;
  }

  .livechat .mainDiv .firstSection .first {
    display: flex;
    flex-direction: row;
  }

  .livechat .mainDiv .firstSection .second {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .livechat .mainDiv .firstSection .first .heading {
    font-size: 13px;
    margin-top: 3px;
    font-weight: 500;
  }

  .livechat .mainDiv .firstSection .first .subHeading {
    font-size: 12px;
  }

  .livechat .mainDiv .firstSection .second .headingTwo {
    font-size: 13px;
    margin-top: 3px;
    font-weight: 500;
  }

  .livechat .mainDiv .firstSection .second .subHeading {
    font-size: 12px;
  }

  .livechat .mainDiv .secondSection {
    width: 100%;
    height: 300px;
  }

  .livechat .mainDiv .secondSection .upper .heading {
    font-size: 13px;
  }

  .livechat .mainDiv .thirdSection {
    height: 400px;
    width: 100%;
  }
}