//general start
.general {

    // background: var(--dull-white);
    padding: 10px 10px 62px 10px;
    width:92%;
    height: 100%;
    // border: 1px solid #949494;
    margin-top: 80px !important;
    margin: auto;
    .generalContent {

        .topSection {
            background: #F3F2F2;
            display: flex;
            align-items: center;
            gap: 20px;
            width: 100%;
            height: 93px;
            margin-block: 10px;
            padding: 25px 50px;

            .lefts {
                width: 20%;
                display: flex;
                // justify-content: center;
                // align-items: center;

                h6 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }



            }

            .middle {
                width: 40%;

                button {
                    border-radius: 4px;
                    border: 1px solid #DDD;
                    width: 100%;
                    height: 32px;
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

            }

            .middleFirst {
                width: 40%;

                button {
                    border-radius: 4px;
                    border: 1px solid #DDD;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 136px;
                    height: 32px;
                    height: 32px;
                    color: #FFF;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    border-radius: 4px;
                    background: #000;
                    padding: 7px 25px 7px 25px;
                }

                .maincheckbox {
                    display: flex;
                    align-items: center;

                    .checkbox {
                        border-radius: 2px;
                        border: 1px solid #949494;
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }

                    .checkbox-label {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .middleSecond {
                width: 40%;

                button {
                    border-radius: 4px;
                    border: 1px solid #DDD;
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    width: 136px;
                    height: 32px;
                    flex-shrink: 0;
                }
            }

            .middleThird {
                width: 40%;

                button {
                    width: 136px;
                    height: 32px;
                    flex-shrink: 0;
                    border-radius: 4px;
                    border: 1px solid #FF1515;
                    color: #F00;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }

            .right {
                width: 40%;
                padding-top: 15px;
                padding-left: 0px;
                padding-right: 0px;


                p {
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

    }
}
@media screen and (max-width:1300px) {
    .general .generalContent .topSection .middle button{
        height: 40px;
    }
}
@media screen and (max-width:600px) {
    .general{
        margin-top: 0 !important;
        padding: 0;
        width: 100%;
        .generalContent{
            .topSection{
                padding: 10px !important;
                width: 95%;
                align-items: center ;
                margin-block: 10px !important;
                margin: auto;
                .lefts{
                    h6{
                        font-size: 10px;
                    }
                }
                .right{
                    padding: 0;
                    p{
                        font-size: 9px;
                        padding: 0;
                        margin-bottom: 0;
                    }
                }
                .middle{
                    button{
                        font-size: 9px ;
                        padding: 5px;
                        height: 50px;
                    }
                }
                .middleFirst{
                    button{
                        font-size: 9px;
                        width: 100% ;
                        height: 42px;
                    }
                    .maincheckbox{
                        .checkbox-label{
                            font-size: 10px !important;
                        }
                    }
                }
                .middleSecond{
                    button{
                        width: 100% !important;
                        font-size: 10px;
                    }
                }
                .middleThird{
                    button{
                        width: 100% !important;
                        font-size: 10px ;
                    }
                }
            }
        }
    }
}

//general end 
//Notification start
.Notification {
    padding: 30px 0px 19px 10px;

    .notification-content {

        .topSection {
            border-radius: 4px;
            background: #F3F2F2;
            width: 100%;
            // height: 140px;
            display: flex;
            padding: 19px 10px 30px 10px;
            margin-bottom: 10px;
            gap: 10px;
            align-items: flex-start;
            .leftSide {
                width: 40%;

                .title {
                    // padding: 19px 0px 97px 10px;

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 0;
                    }
                }
            }

            .middleside {
                width: 50%;

                .middleFirst {
                    // padding: 19px 0px 0px 0px;

                    button {
                        border-radius: 4px;
                        background: #000;
                        // width: 93px;
                        // height: 24px;
                        color: #FFF;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 3px 17px 3px 17px;
                        border: none;
                    }

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    .maincheckbox {

                        .checkbox {
                            border-radius: 2px;
                            border: 0.25px solid #949494;
                            width: 12px;
                            height: 12px;
                            margin-right: 10px;
                        }

                        .checkbox-label {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;

                        }
                    }
                }
            }

            .rightSide {
                width: 50%;
                // padding: 19px 0px 0px 0px;

                p {
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                span {
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }

        .topSectionsecond {
            border-radius: 4px;
            background: #F3F2F2;
            width: 100%;
            height: 110px;
            display: flex;
            gap: 10px;
            padding: 1px 10px 40px 10px;
            margin-bottom: 10px;
            // gap: 10px;

            .leftSide {
                width: 40%;

                .title {
                    padding: 19px 0px 97px 10px;

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }

            .middleside {
                width: 50%;

                .middleFirst {
                    padding: 19px 0px 0px 0px;

                    button {
                        border-radius: 4px;
                        background: #000;
                        // width: 93px;
                        // height: 24px;
                        color: #FFF;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding: 3px 17px 3px 17px;
                        border: none;
                    }

                    input {
                        border-radius: 4px;
                        border: 1px solid #DDD;
                        width: 211px;
                        padding: 4px 10px;
                        // height: 24px;
                        flex-shrink: 0;
                        margin-right: 10px;

                        &::placeholder {
                            color: #ACABAB;
                            font-family: "Poppins";
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            padding-left: 5px;
                            // padding-bottom: 5px;
                        }
                    }

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }

                    .maincheckbox {
                        display: flex;
                        justify-content: flex-start;
                        margin-top: 5px;

                        .checkbox {
                            border-radius: 2px;
                            border: 0.25px solid #949494;
                            width: 12px;
                            height: 12px;
                            margin-right: 10px;
                        }

                        .checkbox-label {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;

                        }
                    }
                }
            }

            .rightSide {
                width: 50%;
                padding: 19px 0px 0px 0px;

                p {
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                span {
                    color: #949494;
                    font-family: "Poppins";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }
}
@media screen and (max-width:1500px) {
    .Notification .notification-content .last{
        height: 165px !important;
    }
}

@media screen and (max-width:600px) {
    .Notification{
        padding: 0px 0px !important;
        .notification-content{
            .topSection{
                justify-content: center;
                align-items: flex-start;
                row-gap: 10px;
                flex-direction: column;
                padding-bottom: 12px;
                background: transparent;
                .leftSide{
                    width: 100%;
                    .title{
                        padding: 0!important;
                        p{
                            font-size: 10px;
                            color: #000;
                        }
                    }
                }
                .rightSide{
                    width: 100%;
                    padding: 0 !important;
                    p{
                        font-size: 9px;
                    }
                }
                .middleside{
                    width: 100%;
                    .middleFirst{
                        span{
                            padding-bottom: 10px;
                        }
                    }
                }
            }
            .middleside{
                .middleFirst{
                    padding: 0 !important;
                    .maincheckbox{
                        display: flex !important;
                        margin-bottom: 7px;
                        margin-top: 7px;
                        .checkbox-label{
                            font-size: 10px !important;
                        }
                    }

                }
            }
            .topSectionsecond{
                height: 100% !important;
                margin-bottom: 0;
                padding-bottom: 10px;
                background: transparent;
                .leftSide{
                    width: 100%;
                    .title{
                        padding: 0  ;

                        p{
                            font-size: 10px;
                            color: #000;
                            margin-bottom: 0;
                        }
                    }
                }
                .rightSide{
                    padding: 0;
                    p{
                        font-size: 9px;
                        margin-bottom: 0;
                    }
                }
                .middleside{
                    width: 100%;
                    .middleFirst{
                        input{
                            width: 100%;
                        }
                        button{
                            width: 90%;
                            font-size: 10px;

                        }
                        span{
                            font-size: 10px;
                            margin-bottom: 5px !important;
                        }
                        .maincheckbox{
                            .checkbox-label{
                                font-size: 9px !important;
                            }
                            .checkbox{
                                margin-right: 8px !important;
                            }
                        }
                    }
                    .middleFirst{
                        button{
                            width: 100%;
                            padding: 5px 18px;
                        }
                        input{
                            margin-bottom: 8px;
                            margin-right: 0 !important;
                        }
                        .maincheckbox{
                            margin-top: 10px;
                        }
                    }
                }
            }
        .topSectionsecond{
            flex-direction: column;
            .rightSide{
                width: 100%;
            }
        }
        }
    }
    
}

//Notification end
// teammember start
.teamMember {
    padding: 38px 10px 38px 10px;
    background: var(--white);

    .teamMember-content {
        .main-profile {
            .main-primary {
                .tabs-container {
                    .tabs {
                        // overflow-x: scroll;
                        gap: 10px;
                        flex-wrap: nowrap;

                        a {
                            font-family: "Poppins";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 7px;
                            white-space: nowrap;
                            color: #949494;
                            cursor: pointer;

                            &.active {
                                color: #949494;
                                text-decoration-line: underline;
                            }
                        }
                    }
                }
            }
        }
    }
}

// First tab team 
.firstTab {
    padding: 6px 0px 76px 0px;
    border-radius: 4px;
    background: #F3F2F2;

    .tabContent {
        padding-top: 25px;

        h6 {
            color: #000;
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-left: 33px;
        }

        .hr {
            color: #A4A4A4;

        }

        .paracontent {
            padding-left: 33px;

            span {
                color: #949494;
                font-family: "Poppins";
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            p {
                color: #949494;
                font-family: "Poppins";
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

        .nameSection {

            display: flex;
            justify-content: space-between;

            .leftName {
                h6 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

            .rightName {
                display: flex;
                padding-right: 121px;

                .right-a {
                    h6 {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }


        }

        .nametext {
            span {
                color: #000;
                font-family: "Poppins";
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding-right: 10px;
                padding-left: 50px;
            }
        }
    }
}
@media screen and (max-width:600px) {
    .firstTab .tabContent h6{
        padding-left: 8px;
    }
    .firstTab .tabContent .paracontent {
        padding: 8px;
    }
    .firstTab .tabContent .nameSection .rightName {
        padding-right: 15px;
    }
}
//secondTab start
.secondTab {
    .secondTab-Content {
        padding: 20px 10px 20px 10px;

        .cardSection {
            width: 100%;
            height: 528px;
            border-radius: 8px;
            border: 1px solid #B5B5B5;
            background: #FFF;
            box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.10);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .cardCpntent {
                text-align: center;

                h6 {
                    color: #000;
                    // text-align: center;
                    font-family: "Poppins";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    // text-align: center;
                    font-family: "Poppins";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }

                button {
                    width: 114px;
                    height: 41px;
                    border-radius: 8px;
                    background: #000;
                    color: #FFF;
                    font-family: "Poppins";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}
@media screen  and (max-width:600px) {
    .secondTab .secondTab-Content {
        padding: 15px 0;
    }
    .secondTab .secondTab-Content .cardSection {
        height: 400px;
    }
    .secondTab .secondTab-Content .cardSection .cardCpntent{
        padding: 0 10px;
    }
}
//Modal Section
.modal-header {
    display: flex;
    justify-content: center;

    .modal-title {
        color: #000;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

}

.modalBody {

    .topSection {
        padding-bottom: 12px;

        .gName {
            color: #000;
            font-family: "Poppins";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        input {
            border-radius: 4px;

            width: 315px;
            // height: 24px;
            border: 1px solid #DDD;

            &::placeholder {
                color: #B9B8B8;
                font-family: "Poppins";
                font-size: 10px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 4px 10px 5px 10px;
            }


        }

        .radioButton {
            width: 100%;
            // height: 57px;
            border-radius: 4px;
            border: 1px solid #51A1FF;
            background: rgba(81, 161, 255, 0.05);

            .maincheckbox {
                display: flex;
                align-items: center;
                padding: 10px 10px 10px 10px;
                // justify-content: center;

                .checkbox {
                    width: 19px;
                    height: 19px;
                    flex-shrink: 0;
                    background: #E4E4E4;

                }

                .checkbox-label {
                    padding-top: 10px;
                    padding-left: 10px;
                    color: #000;
                    font-family: "Poppins";
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }

    .bottomsection {


        .sectionContentf {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            width: 100%;
            // border: 1px solid #DDD;

            border-radius: 4px 4px 0px 0px;

            // border: 1px solid #DDD;
            border-top: 1px solid #DDD;
            border-left: 1px solid #DDD;
            border-right: 1px solid #DDD;
            padding: 12px 30px 12px 30px;

            .bottomLeft {

                h6 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .sectionContent {
            display: flex;
            justify-content: space-between;
            gap: 30px;
            width: 100%;
            border: 1px solid #DDD;

            border-radius: 0px 0px 4px 4px;

            border: 1px solid #DDD;
            // border-top: 1px solid #DDD;
            // border-left: 1px solid #DDD;
            // border-right: 1px solid #DDD;
            padding: 12px 19px 12px 30px;

            .bottomLeft {

                h6 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .radioButton {

                    .maincheckbox {
                        display: flex;
                        align-items: center;

                        .checkbox {
                            margin-right: 5px;
                        }

                        .checkbox-label2 {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }
                }
            }
        }

        // hr {
        //     border: 1px solid #DDDD;
        // }
    }
}

.modalFooter {
    display: flex;
    justify-content: space-between;

    .cancelButton {
        // width: 66px;
        // height: 24px;
        padding: 3px 12px 3px 12px;
        border-radius: 4px;
        border: 1px solid #DDD;
        color: #000;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: #DDD;
    }

    .saveButton {
        // width: 69px;
        // height: 24px;
        border-radius: 10px;
        background: #420061;
        padding: 10px 20px 10px 20px;
        color: #FFF;
        font-family: "Poppins";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}



// teammember end 