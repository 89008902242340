.modal-content {
  width: 120%;
}
.body {
  padding: 0px 20px;
  input {
    width: 100%;
    border: 1px solid #0084ff;
    border-radius: 12px;
    padding: 5px 10px;
  }
}
.multiple-buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  place-items: center;
  .messenger {
    margin-top: 20px;
    border: 1px dashed #adb4bf;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    span {
      width: 100%;
      display: flex;
      align-items: center;
      //   justify-content: center;
      font-size: 20px;
      gap: 10px;
    }
  }
}
.modal-footer {
  justify-content: space-between;

  .btn-primary {
    background-color: #0084ff;
    border: 1px solid #0084ff;
    border-radius: 4px;
  }
  .btn-secondary {
    border: none;
    gap: 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ba3329;
    &:hover {
      background: transparent;
      color: black;
    }
  }
}
@media screen and (max-width:600px) {
  .multiple-buttons{
    gap: 0;
    padding: 10px 0;
  }
  .multiple-buttons .messenger{
    width: 90% !important;
  }
  .multiple-buttons .messenger span{
    font-size: 10px;
  }
  .modal-body{
    padding: 0 !important;
  }
  .modal-footer{
    flex-direction: row !important;
    padding-left: 0 !important;
  }
}
