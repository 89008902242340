.outside  {
    width: 97%;
    margin: auto;
    margin-top: 80px;
    height: 429px;
    border-radius: 6.502px;
    border: 0.813px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 13.00467px 3.25117px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
}
.outside .cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.outside .cont h2{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.outside .cont span a{
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
.outside .cont p{
    width: 470px;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.outside .cont .btn {
    width: 130px;
    height: 41px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #000;
}
.outside .cont button{
    color: #FFF;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: transparent;
    border: none;
}

@media screen and (max-width:600px) {
    .outside .cont h2 {
        font-size: 22px;
    }
    .outside .cont p {
        width: 100%;
        font-size: 12px;
        padding: 4px 8px;
    }
    .outside {
        width: 95% !important;
        height: 350px;
        margin-top: 30px;
       
    }
    
}