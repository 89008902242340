.ctx-banner {
  .home-banner {
    padding: 100px 223px;
    background: #fff;
    display: flex;
    gap: 100px;

    .left {
      width: 70%;

      h4 {
        // width: 974px;
      }

      h1 {
        color: #000;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      h4 {
        color: #000;
        font-family: "Poppins";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }
}


@media screen and (max-width: 1650px) {
  .ctx-banner {
    .home-banner {
      padding: 100px 150px;
      background: #f5f5f7;

      .left {
        width: 70%;
      }

      .right {
        width: 30%;

        .banner-img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .ctx-banner {
    .home-banner {
      padding: 100px 100px;
      background: #f5f5f7;

      .left {
        width: 70%;

        h1 {
          font-size: 50px;
        }

        h3 {
          font-size: 30px;
        }
      }

      .right {
        width: 30%;

        .banner-img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .ctx-banner {
    .home-banner {
      padding: 100px 40px;
      background: #f5f5f7;

      .left {
        width: 70%;

        h1 {
          font-size: 50px;
        }

        h4 {
          font-size: 27px;
        }
      }

      .right {
        width: 30%;

        .banner-img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .ctx-banner {
    .home-banner {
      padding: 100px 20px;
      background: #f5f5f7;

      .left {
        width: 70%;

        h1 {
          font-size: 40px;
        }

        h4 {
          font-size: 22px;
        }
      }

      .right {
        width: 30%;

        .banner-img {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .ctx-banner {
    .home-banner {
      padding: 100px 20px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .left {
        // width: 70%;
        width: 100%;
        text-align: center;

        h1 {
          font-size: 30px;
        }

        h4 {
          font-size: 19px;
        }

        // button {
        //   font-size: 18px;
        // }
      }

      .right {
        // width: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .banner-img {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .ctx-banner {
    .home-banner {
      padding: 50px 20px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .left {
        // width: 70%;
        width: 100%;
        text-align: center;

        h1 {
          font-size: 20px;
        }

        h4 {
          font-size: 15px;
          font-weight: 400;
        }

        // button {
        //   font-size: 18px;
        // }
      }

      .right {
        // width: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .banner-img {
          width: 50%;
        }
      }
    }
  }
}


// banner end

//storecustomer start

.storecustomer {
  padding: 35px 331px 100px 342px;

  .store-content {
    .top-section {
      // padding: 0px 410px 125px 410px;

      h4 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .storeBottom {
      display: flex;
      width: 100%;
      gap: 115px;
      margin-top: 125px;

      .left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;


      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          color: #000;
          font-family: "Poppins";
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        p {
          color: #000;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .storeBottomfirst {
      display: flex;
      width: 100%;
      gap: 115px;
      margin-top: 125px;

      .left {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;


      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h4 {
          color: #000;
          font-family: "Poppins";
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        p {
          color: #000;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .storecustomer {
    padding: 35px 200px 100px 200px;
  }
}

@media only screen and (max-width: 1450px) {
  .storecustomer {
    padding: 35px 100px 100px 100px;
  }
}

@media only screen and (max-width: 1300px) {
  .storecustomer {
    padding: 35px 60px 70px 60px;
  }
}

@media only screen and (max-width: 1050px) {
  .storecustomer {
    padding: 35px 20px 100px 20px;

    .store-content {
      .top-section {
        // padding: 0px 410px 125px 410px;

        h4 {

          font-size: 40px;

        }

        p {

          font-size: 20px;

        }
      }

      .storeBottom {
        display: flex;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .right {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {

            font-size: 30px;

          }

          p {

            font-size: 20px;

          }
        }
      }

      .storeBottomfirst {
        display: flex;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .right {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {

            font-size: 30px;

          }

          p {

            font-size: 20px;

          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .storecustomer {
    padding: 35px 20px 100px 20px;

    .store-content {
      .top-section {
        // padding: 0px 410px 125px 410px;

        h4 {

          font-size: 40px;

        }

        p {

          font-size: 20px;

        }
      }

      .storeBottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {

            font-size: 30px;

          }

          p {

            font-size: 20px;

          }
        }
      }

      .storeBottomfirst {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 1;

          h4 {

            font-size: 30px;

          }

          p {

            font-size: 20px;

          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .storecustomer {
    padding: 0px 20px 50px 20px;

    .store-content {
      .top-section {


        h4 {

          font-size: 20px;

        }

        p {
          font-size: 15px;
        }
      }

      .storeBottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h4 {

            font-size: 20px;
            font-weight: 700;

          }

          p {

            font-size: 16px;

          }
        }
      }

      .storeBottomfirst {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 70px;

        .left {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 1;

          h4 {
            font-weight: 700;
            font-size: 20px;
          }

          p {

            font-size: 16px;

          }
        }
      }

    }
  }
}

//storecustomer end
//most dm start
.mostdm {
  .content {
    display: flex;
    gap: 100px;

    .left {
      width: 50%;

      h4 {
        color: #fff;
        font-family: "Poppins";
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #fff;
        font-family: "Poppins";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      button {
        padding: 12px 29px;
        border-radius: 36px;
        border: 1px solid #fff;
        background: #000;
        color: #fff;
        text-align: center;
        font-family: "Coco Gothic";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1000px) {
  .mostdm {
    .content {
      display: flex;
      gap: 10px !important;

      .left {
        width: 50%;

        h4 {

          font-size: 25px !important;

        }

        p {
          color: #fff;
          font-family: "Poppins";
          font-size: 25px !important;

        }

        button {
          padding: 12px 29px;
          border-radius: 36px;
          border: 1px solid #fff;
          background: #000;
          color: #fff;
          text-align: center;
          font-family: "Coco Gothic";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .mostdm {
    .content {
      display: flex;
      gap: 10px !important;
      flex-direction: column;

      .left {
        width: 100% !important;
        text-align: center;
        padding-top: 30px;

        h4 {

          font-size: 20px !important;

        }

        p {
          color: #fff;
          font-family: "Poppins";
          font-size: 17px !important;

        }

        button {
          padding: 12px 29px;
          border-radius: 36px;
          border: 1px solid #fff;
          background: #000;
          color: #fff;
          text-align: center;
          font-family: "Coco Gothic";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .right {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;

        img {
          width: 100%;
        }
      }
    }
  }
}

//most dm end
// drag start
.drag-section {
  background: var(--black);

  .drag {
    padding: 50px 247px;

    .drag-content {
      h3 {
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }

      p {
        max-width: 1246px;
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      h5 {
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-top: 28px;
        padding: 0px 90px 0px 90px;
      }

      .pics {
        border-radius: 9px;
        background: white;
        // background: #e0b9fe;
        // padding: 101px 57px;
        width: 100%;

        video {
          width: 100%;
        }
      }
    }

    .logos {
      padding: 46px 280px;
      gap: 62px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .drag-section {
    background: var(--black);

    .drag {
      padding: 50px 80px;


    }
  }
}

@media screen and (max-width: 1000px) {
  .drag-section {
    background: var(--black);

    .drag {
      padding: 50px 80px;

      .drag-content {
        h3 {


          font-size: 40px;

        }

        p {
          max-width: 1246px;

          font-size: 25px;

        }

        h5 {

          font-size: 25px;

        }

        .pics {
          border-radius: 9px;
          background: white;
          // background: #e0b9fe;
          // padding: 101px 57px;
          width: 100%;

          video {
            width: 100%;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 850px) {
  .drag-section {
    background: var(--black);

    .drag {
      padding: 50px 10px;

      .drag-content {
        h3 {


          font-size: 35px;

        }

        p {
          max-width: 1246px;

          font-size: 22px;

        }

        h5 {

          font-size: 20px;
          padding: 0px;

        }


      }

    }
  }
}

@media screen and (max-width: 550px) {
  .drag-section {


    .drag {


      .drag-content {
        h3 {


          font-size: 25px;

        }

        p {
          max-width: 1246px;

          font-size: 16px;

        }

        h5 {

          font-size: 18px;
          padding: 0px;

        }


      }

    }
  }
}

//reate freebot dm
.freebot-second {
  // background: var(-- --dull-white) !important;

  .freebotdm {
    background: var(--dull-white);

    .freebotdm-title {
      h2 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        font-family: "Poppins";
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      // .freestartBtn {
      //     padding: 12px 29px;
      //     border-radius: 36px;
      //     border: 1px solid #000;
      //     background: #000;
      // }
    }
  }
}

@media screen and (max-width: 1000px) {}

@media screen and (max-width: 1750px) {
  .freebot-second {
    // background: var(-- --dull-white) !important;

    .freebotdm {
      background: var(--dull-white);


      .freebotdm-title {
        h2 {

          font-size: 40px;

        }

        p {

          font-size: 20px;

        }
      }
    }
  }

}

@media screen and (max-width: 900px) {
  .freebot-second {
    // background: var(-- --dull-white) !important;

    .freebotdm {
      background: var(--dull-white);


      .freebotdm-title {
        h2 {

          font-size: 22px;

        }

        p {

          font-size: 16px;

        }
      }
    }
  }

}


@media screen and (max-width: 1000px) {
  .mostdm {
    .content {
      display: flex;
      gap: 100px;

      .left {
        width: 50%;

        h4 {
          color: #fff;
          font-family: "Poppins";
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: #fff;
          font-family: "Poppins";
          font-size: 32px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        button {
          padding: 12px 29px;
          border-radius: 36px;
          border: 1px solid #fff;
          background: #000;
          color: #fff;
          text-align: center;
          font-family: "Coco Gothic";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .right {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

}