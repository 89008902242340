//banner start
.program-banner {
  background: var(--dull-white);
  width: 100%;

  padding: 0px 216px 0px 216px;

  .banner-content {
    text-align: center;
    padding: 123px 0px 73px 0px;

    h6 {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 5px;
    }

    p {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 73px;
    }

    button {
      padding: 12px 29px;
      border-radius: 36px;
      border: 1px solid #000;
      background: #000;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 20px;
    }

    span {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1650px) {
  .program-banner {
    background: var(--dull-white);
    width: 100%;

    padding: 0px 50px 0px 50px;

    .banner-content {
      text-align: center;
      padding: 123px 0px 73px 0px;

      h6 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 5px;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 32px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-bottom: 73px;
      }

      button {
        padding: 12px 29px;
        border-radius: 36px;
        border: 1px solid #000;
        background: #000;
        color: #fff;
        text-align: center;
        font-family: "Coco Gothic";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 20px;
      }

      span {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
  }

}



@media screen and (max-width: 600px) {
  .program-banner {
    background: var(--dull-white);
    width: 100%;

    padding: 0px 10px 0px 10px;

    .banner-content {
      text-align: center;
      padding: 70px 0px 73px 0px;

      h6 {

        font-size: 22px;

      }

      p {

        font-size: 12px;

      }

      button {

        font-size: 14px;

      }

      span {

        font-size: 12px;

      }
    }
  }

}

//banner end

// testimonial start
.testimonial {
  width: 100%;
  background: var(--black);
  padding-left: 417px;
  padding-right: 417px;
  padding-bottom: 30px;

  .testimonial-content {
    .title {
      padding-top: 48px;
      padding-bottom: 38px;

      h6 {
        color: #fff;
        text-align: center;
        font-family: "Poppins";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .tools-section {
      display: flex;
      width: 100%;
      gap: 20px;

      .left {
        width: 50%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
          color: #fff;
          font-family: "Poppins";
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        p {
          color: #fff;
          font-family: "Poppins";
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 1650px) {
  .testimonial {

    padding-left: 200px;
    padding-right: 200px;


  }
}

@media screen and (max-width:1300px) {
  .testimonial{
    .testimonial-content{
      .tools-section{
        .right{
          p{
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1150px) {
  .testimonial {

    padding-left: 100px;
    padding-right: 100px;


  }
}

@media only screen and (max-width: 1000px) {
  .testimonial {
    width: 100%;
    background: var(--black);
    padding-left: 50px;
    padding-right: 50px;

    .testimonial-content {
      .title {
        padding-top: 48px;
        padding-bottom: 38px;

        h6 {

          font-size: 30px;

        }
      }

      .tools-section {
        display: flex;
        width: 100%;
        gap: 20px;

        .left {
          width: 50%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .right {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          span {

            font-size: 25px;

          }

          p {

            font-size: 19px;

          }
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .testimonial {
    width: 100%;
    background: var(--black);
    padding: 20px;

    .testimonial-content {
      .title {
        padding-top: 48px;
        padding-bottom: 38px;

        h6 {

          font-size: 20px;

        }
      }

      .tools-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;

        .left {
          width: 100%;


          img {
            width: 100%;
            height: 100%;
          }
        }

        .right {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          order: 1;
          text-align: center;

          span {

            font-size: 25px;

          }

          p {

            font-size: 13px;

          }
        }
      }
    }
  }
}

// testimonial end
//program-card start
.program-card {
  background: var(--dull-white);

  .programContent {
    width: 100%;
    padding: 0px 395px 0px 395px;

    .stepCard-title {
      padding-top: 34px;

      h2 {
        color: #000;

        font-family: "Poppins";
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
      }
    }

    .step-cards {
      padding: 34px 200px;

      .social-card-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 25px;

        .contents {
          width: 355.494px;
          // height: 363.394px;
          border-radius: 6.32px;
          background: #fff;
          box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

          .card-content {
            .card-title {
              text-align: center;
              margin-top: 11px;

              span {
                color: #000;
                font-family: "Poppins";
                font-size: 25.168px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }

            .card-img {
              width: 100%;
              height: 100%;
              margin-top: 31px;
              padding-left: 100px;
              padding-right: 100px;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 151.158px;
                height: 124.468px;
                object-fit: cover;
              }
            }

            .card-paragraph {
              padding: 29px;

              p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 18.876px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .card-btn {
      display: flex;
      justify-content: center;

      button {
        padding: 12px 29px;
        border-radius: 36px;
        border: 1px solid #000;
        background: #000;
        color: #FFF;
        text-align: center;
        font-family: " Coco Gothic";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media only screen and (max-width: 1700px) {
  .program-card {


    .programContent {
      width: 100%;
      padding: 0px 10px 0px 10px;




      .step-cards {
        padding: 20px 10px;

        .social-card-content {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 10px;


          .contents {
            width: 355.494px;
            height: 363.394px;
            border-radius: 6.32px;
            background: #fff;
            box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

            .card-content {
              .card-title {
                text-align: center;
                margin-top: 11px;

                span {

                  font-size: 20px;

                }
              }

              .card-img {
                width: 100%;
                height: 100%;
                margin-top: 31px;
                padding-left: 100px;
                padding-right: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 151.158px;
                  height: 124.468px;
                  object-fit: cover;
                }
              }

              .card-paragraph {
                padding: 29px;

                p {
                  color: #000;
                  text-align: center;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }





    }
  }
}

@media only screen and (max-width: 1200px) {
  .program-card {
    background: var(--dull-white);

    .programContent {
      width: 100%;
      padding: 0px 10px 0px 10px;

      .stepCard-title {
        padding-top: 34px;

        h2 {
          font-size: 30px;
        }
      }

      .step-cards {
        padding: 20px 10px;

        .social-card-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 10px;


          .contents {
            width: 355.494px;
            height: 363.394px;
            border-radius: 6.32px;
            background: #fff;
            box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

            .card-content {
              .card-title {
                text-align: center;
                margin-top: 11px;

                span {

                  font-size: 20px;

                }
              }

              .card-img {
                width: 100%;
                height: 100%;
                margin-top: 31px;
                padding-left: 100px;
                padding-right: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 151.158px;
                  height: 124.468px;
                  object-fit: cover;
                }
              }

              .card-paragraph {
                padding: 29px;

                p {
                  color: #000;
                  text-align: center;
                  font-family: "Poppins";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }

      .card-btn {
        display: flex;
        justify-content: center;

        button {
          padding: 12px 29px;
          border-radius: 36px;
          border: 1px solid #000;
          background: #000;
          color: #FFF;
          text-align: center;
          font-family: " Coco Gothic";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .program-card {
    background: var(--dull-white);

    .programContent {
      width: 100%;
      padding: 0px 10px 0px 10px;

      .stepCard-title {
        padding-top: 34px;

        h2 {
          font-size: 25px;
        }
      }

      .step-cards {
        padding-left: 30px;
        padding-right: 30px;



        .social-card-content {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 10px;


          .contents {
            // width: 100%;
            // height: 100%;
            // width: 355.494px;
            // height: 363.394px;
            border-radius: 6.32px;
            background: #fff;
            box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

            .card-content {
              .card-title {
                text-align: center;
                margin-top: 11px;

                span {

                  font-size: 15px;

                }
              }

              .card-img {
                width: 100%;
                height: 100%;
                margin-top: 31px;
                padding-left: 100px;
                padding-right: 100px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 151.158px;
                  height: 124.468px;
                  object-fit: cover;
                }
              }

              .card-paragraph {
                padding: 50px;

                p {
                  color: #000;
                  text-align: center;
                  font-family: "Poppins";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
        }
      }

      .card-btn {
        display: flex;
        justify-content: center;

        button {

          font-size: 15px;

        }
      }
    }
  }
}

//program-card end

//program-faq start
.program-faq {
  background: var(--dull-white);

  .faq {
    h1 {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    p {
      color: #000;
      text-align: center;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .accor {
      .accordion {
        .accordion-item {
          background: transparent;
          border-color: black;

          .accordion-header {
            .accordion-button {
              background: transparent;
              // color: #fff;

              &::after {
                display: none;
              }
            }
          }

          .accordion-collapse {
            .accordion-body {
              color: #000;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .program-faq {
    background: var(--dull-white);

    .faq {
      h1 {

        font-size: 30px;

      }

      p {

        font-size: 18px;

      }


    }
  }
}

@media only screen and (max-width: 500px) {
  .program-faq {
    background: var(--dull-white);

    .faq {
      h1 {

        font-size: 20px;

      }

      p {

        font-size: 13px;

      }


    }
  }
}

//program-faq end