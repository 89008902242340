.customers {
  width: 97%;
  margin: auto;
  margin-top: 79px;
  height: 429px;
  border-radius: 6.502px;
  border: 0.813px solid #b5b5b5;
  background: #fff;
  box-shadow: 0px 0px 13.00467px 3.25117px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.customers .cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.customers .cont h2 {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.customers .cont span a {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}
.customers .cont p {
  width: 470px;
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.customers .cont .connectbtn button {
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  width: 130px;
  height: 41px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #000;
}

@media screen and (max-width: 600px) {
  .customers .cont p {
    width: 100%;
    font-size: 12px;
    padding: 4px 8px;
  }
  .customers {
    width: 95% !important%;
    margin-top: 30px;
    height: 350px;
    padding: 13px;
  }
  .customers .cont .connectbtn button {
    font-size: 13px;
    width: 100px;
    height: 30px;
  }
  .customers .cont h2 {
    font-size: 22px;
  }
}
