//resturant-banner
.resturant-banner {
    padding: 200px 144px 48px 144px;
    background: var(--dull-white);

    .Banner {


        .resturant-banners {
            // gap: 50px;
            display: flex;
            gap: 100px;

            .left {
                width: 60%;

                h1 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                h4 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 36px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    padding-right: 98px;
                }
            }

            .right {
                width: 40%;
                display: flex;


                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .resturant-banner {
        padding: 100px 20px 48px 20px;

        .Banner {


            .resturant-banners {
                // gap: 50px;
                display: flex;
                gap: 100px;

                .left {
                    width: 60%;

                    h1 {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }

                    h4 {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 36px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        padding-right: 98px;
                    }
                }

                .right {
                    width: 40%;
                    display: flex;


                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .resturant-banner {
        padding: 100px 20px 48px 20px;

        .Banner {


            .resturant-banners {
                // gap: 50px;
                display: flex;
                gap: 50px;

                .left {
                    width: 60%;

                    h1 {

                        font-size: 40px;

                    }

                    h4 {

                        font-size: 30px;
                        padding-right: 0px;
                    }
                }

                .right {
                    width: 40%;
                    display: flex;


                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .resturant-banner {
        padding: 50px 20px 48px 20px;

        .Banner {


            .resturant-banners {
                // gap: 50px;
                display: flex;
                gap: 20px;

                .left {
                    width: 60%;

                    h1 {

                        font-size: 35px;

                    }

                    h4 {

                        font-size: 22px;
                        padding-right: 0px;
                    }
                }

                .right {
                    width: 40%;
                    display: flex;


                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .resturant-banner {
        padding: 50px 20px 48px 20px;

        .Banner {


            .resturant-banners {
                // gap: 50px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .left {
                    width: 100%;
                    order: 2;

                    h1 {}

                    h4 {


                        padding-right: 0px;
                    }
                }

                .right {
                    width: 100%;
                    display: flex;


                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .resturant-banner {
        padding: 50px 20px 48px 20px;

        .Banner {


            .resturant-banners {
                // gap: 50px;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .left {
                    width: 100%;
                    order: 2;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    h1 {
                        font-size: 20px;
                    }

                    h4 {
                        font-size: 15px;

                        padding-right: 0px;
                    }
                }

                .right {
                    width: 100%;
                    display: flex;


                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}



//banner end 

//newlead start
.newlead {
    width: 100%;
    height: 981px;
    border-radius: 8px;
    background: #000;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.10);
    padding: 32px 190px 0px 146px;

    .newlead-title {

        h6 {
            color: var(--white);
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

    }

    .newleadContent {
        display: flex;
        width: 100%;
        gap: 10px;

        .leftContent {
            width: 50%;

            .topParagraph {
                background: #555;
                padding: 15px 36px 6px 20px;
                // padding-left:  36px;
                // padding-right: 20px;
                // padding-bottom: 6px;

                h6 {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

}

////newlead end 
///  profit component start
.profit {
    background: var(--dull-white);

    .profit-content {

        .title {
            padding: 58px 514px 58px 514px;

            h4 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .profitCards {
            padding: 0px 221px 0px 221px;
            display: grid;
            gap: 40px;
            grid-template-columns: repeat(2, 1fr);

            .firstCard {
                width: 100%;
                height: 250px;
                border-radius: 9px;
                background: #FFF;

                .cardHeader {
                    display: flex;
                    gap: 23px;
                    padding: 38px 36px 16px;

                    .headerText {

                        h6 {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                .cardContent {
                    padding: 17px 36px 49px 36px;

                    p {
                        color: #483F70;
                        font-family: "Poppins";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

        .profit-btn {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 53px;
            padding-bottom: 66px;


            button {
                padding: 12px 29px;
                border-radius: 36px;
                border: 1px solid #8A6EFF;
                background: #8A6EFF;
                color: #FFF;
                text-align: center;
                font-family: "Coco Gothic";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media screen and (max-width: 1700px) {
    .profit {
        background: var(--dull-white);

        .profit-content {

            .title {
                padding: 58px 20px 58px 20px;

                h4 {
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .profitCards {
                padding: 0px 10px 0px 10px;
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(2, 1fr);


            }

        }
    }
}

@media screen and (max-width: 1000px) {
    .profit {
        background: var(--dull-white);

        .profit-content {

            .title {
                padding: 58px 20px 58px 20px;

                h4 {
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .profitCards {
                padding: 0px 10px 0px 10px;
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(1, 1fr);


            }

        }
    }
}

@media screen and (max-width: 650px) {
    .profit {
        background: var(--dull-white);

        .profit-content {

            .title {
                padding: 58px 20px 58px 20px;

                h4 {

                    font-size: 25px;

                }
            }

            .profitCards {
                padding: 0px 10px 0px 10px;
                display: grid;
                gap: 10px;
                grid-template-columns: repeat(1, 1fr);

                .firstCard {
                    width: 100%;
                    height: 250px;
                    border-radius: 9px;
                    background: #FFF;

                    .cardHeader {
                        display: flex;
                        gap: 23px;
                        padding: 38px 36px 16px;

                        .headerText {

                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    .cardContent {
                        padding: 17px 10px 49px 10px;

                        p {
                            color: #483F70;
                            font-family: "Poppins";
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }

            }

        }
    }
}

/// profit component end 
///  expert strategies start
.strategies {
    background: var(--black);
    padding: 77px 122px 81px 122px;

    .strategies-content {

        h5 {
            color: #FFF;
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        p {
            text-align: center;
            color: #FFF;
            font-family: "Poppins";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 69px;
            margin-top: 10px;
        }

        .expertizeContent {
            // padding-left: 280px;
            // padding-right: 310px;
            display: flex;
            justify-content: center;
            padding-top: 30px;

            .content {
                padding-left: 30px;
                padding-top: 11px;
                padding-bottom: 11px;
                padding-right: 19px;
                border-radius: 8px;
                background: rgba(132, 132, 132, 0.29);



                .first-section {



                    li {
                        color: #FFF;
                        font-family: "Poppins";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;

                    }
                }
            }


        }

        .strategy-button {
            display: flex;
            justify-content: center;
            padding-top: 145px;

            button {
                padding: 12px 29px;
                border-radius: 36px;
                border: 1px solid #FFF;
                background: #000;
                color: #FFF;
                text-align: center;
                font-family: "Coco Gothic";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    .strategies {
        background: var(--black);
        padding: 77px 20px 81px 20px;
    }
}

@media screen and (max-width: 1250px) {
    .strategies {
        background: var(--black);
        padding: 77px 20px 81px 20px;

        .strategies-content {

            h5 {

                font-size: 30px;

            }

            p {

                font-size: 15px;

            }

            .expertizeContent {
                // padding-left: 280px;
                // padding-right: 310px;
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .content {
                    padding-left: 30px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-right: 19px;
                    border-radius: 8px;
                    background: rgba(132, 132, 132, 0.29);



                    .first-section {



                        li {
                            color: #FFF;
                            font-family: "Poppins";
                            font-size: 17px !important;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                        }
                    }
                }


            }

            .strategy-button {
                display: flex;
                justify-content: center;
                padding-top: 145px;

                button {

                    font-size: 16px;

                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .strategies {
        background: var(--black);
        padding: 77px 20px 81px 20px;

        .strategies-content {

            h5 {

                font-size: 20px;

            }

            p {

                font-size: 11px;

            }

            .expertizeContent {
                // padding-left: 280px;
                // padding-right: 310px;
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .content {
                    padding-left: 30px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-right: 19px;
                    border-radius: 8px;
                    background: rgba(132, 132, 132, 0.29);



                    .first-section {



                        li {
                            color: #FFF;
                            font-family: "Poppins";
                            font-size: 13px !important;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                        }
                    }
                }


            }

            .strategy-button {
                display: flex;
                justify-content: center;
                padding-top: 145px;

                button {

                    font-size: 16px;

                }
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .strategies {
        background: var(--black);
        padding: 77px 20px 81px 20px;

        .strategies-content {

            h5 {

                font-size: 14px;

            }

            p {

                font-size: 9px;

            }

            .expertizeContent {
                // padding-left: 280px;
                // padding-right: 310px;
                display: flex;
                justify-content: center;
                padding-top: 30px;

                .content {
                    padding-left: 30px;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-right: 19px;
                    border-radius: 8px;
                    background: rgba(132, 132, 132, 0.29);



                    .first-section {



                        li {
                            color: #FFF;
                            font-family: "Poppins";
                            font-size: 10px !important;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;

                        }
                    }
                }


            }

            .strategy-button {
                display: flex;
                justify-content: center;
                padding-top: 145px;

                button {

                    font-size: 16px;

                }
            }
        }
    }
}

///  expert strategies end
/// game number start
.gamenumber {
    background: #F5F5F7;
    padding-left: 298px;
    padding-right: 298px;


    .gamenunber-content {
        .title {
            padding-top: 76px;
            padding-bottom: 12px;

            h6 {
                color: #000;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .title2 {
            padding-top: 0px;
            padding-bottom: 12px;

            h6 {
                color: #000;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        .numberContent {
            display: flex;

            padding-top: 30px;
            padding-bottom: 76px;
            gap: 40px;
            width: 100%;

            .left {
                width: 60%;

                span {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                ul {
                    margin-left: 50px;
                    margin-top: 50px;

                    li {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 36px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }
                }
            }

            .right {
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    // width: 100%;
                    // height: 100%;
                }
            }
        }

        .progres-cards {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 23px;
            width: 100%;

            .firstCard {
                width: 100%;
                height: 181px;
                border-radius: 8px;
                background: #FFF;
                box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.10);
                display: flex;
                flex-direction: column;
                justify-content: center;
                // padding-left: 58px;
                align-items: center;

                span {
                    color: #493F72;
                    font-family: "Poppins";
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                p {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .gamenumber {
        background: #F5F5F7;
        padding-left: 100px;
        padding-right: 100px;



    }
}

@media screen and (max-width: 1350px) {
    .gamenumber {
        background: #F5F5F7;
        padding-left: 20px;
        padding-right: 20px;

        .gamenunber-content {
            .title {
                padding-top: 76px;
                padding-bottom: 12px;

                h6 {

                    font-size: 35px;

                }
            }

            .title2 {
                padding-top: 0px;
                padding-bottom: 12px;

                h6 {

                    font-size: 35px;

                }
            }

            .numberContent {
                display: flex;

                padding-top: 30px;
                padding-bottom: 76px;
                gap: 10px;
                width: 100%;

                .left {
                    width: 60%;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 17px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    ul {
                        margin-left: 50px;
                        margin-top: 50px;

                        li {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }
                }

                .right {
                    width: 40%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        // width: 100%;
                        // height: 100%;
                    }
                }
            }

            .progres-cards {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 23px;

                .firstCard {
                    width: 100%;
                    height: 181px;
                    border-radius: 8px;
                    background: #FFF;
                    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.10);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    // align-items: center;

                    span {
                        color: #493F72;
                        font-family: "Poppins";
                        font-size: 64px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }


    }
}

@media screen and (max-width: 850px) {
    .gamenumber {
        background: #F5F5F7;
        padding-left: 20px;
        padding-right: 20px;

        .gamenunber-content {
            .title {
                padding-top: 76px;
                padding-bottom: 12px;

                h6 {

                    font-size: 25px;

                }
            }

            .title2 {
                padding-top: 0px;
                padding-bottom: 12px;

                h6 {

                    font-size: 25px;

                }
            }



            .progres-cards {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 23px;

                .firstCard {
                    width: 100%;
                    height: 181px;
                    border-radius: 8px;
                    background: #FFF;
                    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.10);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    // align-items: center;

                    span {
                        color: #493F72;
                        font-family: "Poppins";
                        font-size: 64px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }


    }
}

@media screen and (max-width: 650px) {
    .gamenumber {
        background: #F5F5F7;
        padding-left: 20px;
        padding-right: 20px;

        .gamenunber-content {
            .title {
                padding-top: 76px;
                padding-bottom: 12px;
                text-align: center;

                h6 {

                    font-size: 14px;

                }
            }

            .title2 {
                padding-top: 0px;
                padding-bottom: 12px;
                text-align: center;

                h6 {

                    font-size: 14px;

                }
            }

            .numberContent {
                display: flex;
                flex-direction: column;
                padding-top: 30px;
                padding-bottom: 76px;
                gap: 10px;
                width: 100%;

                .left {
                    width: 100%;


                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-align: center;
                    }

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-align: center;
                    }

                    ul {
                        // margin-left: 50px;
                        // margin-top: 50px;

                        margin-left: 20px;

                        li {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 36px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                        }
                    }
                }

                .right {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        // width: 100%;
                        // height: 100%;
                    }
                }
            }

            .progres-cards {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 23px;

                .firstCard {
                    width: 100%;
                    height: 164px;
                    border-radius: 8px;
                    background: #FFF;
                    box-shadow: 0px 4px 23px 0px rgba(0, 0, 0, 0.10);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    // align-items: center;

                    span {
                        color: #493F72;
                        font-family: "Poppins";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    p {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }


    }
}

/// game end 
/// missing content start
.missing {
    padding-left: 350px;
    padding-right: 281px;
    padding-top: 155px;
    padding-bottom: 50px;
    background: #F5F5F7;

    .missing-content {

        h5 {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

        }

        p {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .demo-btn {
            display: flex;
            justify-content: center;

            button {
                padding: 12px 29px;
                border-radius: 36px;
                border: 1px solid #000;
                background: #000;
                color: #FFF;
                text-align: center;
                font-family: "Coco Gothic";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .missing {
        padding-left: 200px;
        padding-right: 200px;



    }
}

@media screen and (max-width: 1350px) {
    .missing {
        padding-left: 100px;
        padding-right: 100px;



    }
}

@media screen and (max-width: 1100px) {
    .missing {
        padding-left: 50px;
        padding-right: 50px;


        .missing-content {

            h5 {

                font-size: 25px;


            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 25px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .demo-btn {
                display: flex;
                justify-content: center;

                button {
                    padding: 12px 29px;
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Coco Gothic";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .missing {
        padding-left: 20px;
        padding-right: 20px;


        .missing-content {

            h5 {

                font-size: 17px;


            }

            p {

                font-size: 13px;

            }

            .demo-btn {
                display: flex;
                justify-content: center;

                button {

                    font-size: 15px;

                }
            }
        }
    }
}

/// end 
/// resturant-last start
.second-faq {
    background: var(--black);

    .faq {
        .accor {
            .accordion {
                .accordion-item {
                    background: transparent;
                    border-color: #fff;

                    .accordion-header {
                        .accordion-button {
                            background: transparent;
                            color: #fff;

                            &::after {
                                display: none;
                            }
                        }
                    }

                    .accordion-collapse {
                        .accordion-body {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

}

/// resturant-last end
/// freebots start
.freebots {
    background: var(--black);
    padding-bottom: 30px;

    .freebotdm {
        .freebotdm-title {

            h2 {
                color: #FFF;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            p {
                color: #FFF;
                text-align: center;
                font-family: "Poppins";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .freebots {


        .freebotdm {
            .freebotdm-title {
                padding-left: 200px;
                padding-right: 200px;


            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .freebots {
        background: var(--black);
        padding-bottom: 30px;

        .freebotdm {
            .freebotdm-title {
                padding-left: 100px;
                padding-right: 100px;

                h2 {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                p {
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .freebots {
        background: var(--black);
        padding-bottom: 30px;

        .freebotdm {
            .freebotdm-title {
                padding-left: 20px;
                padding-right: 20px;

                h2 {

                    font-size: 30px;

                }

                p {

                    font-size: 15px;

                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .freebots {
        background: var(--black);
        padding-bottom: 30px;

        .freebotdm {
            .freebotdm-title {
                padding-left: 20px;
                padding-right: 20px;

                h2 {

                    font-size: 20px;

                }

                p {

                    font-size: 13px;

                }
            }
        }
    }
}

/// end