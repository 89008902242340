//banner start
.banner-creater {
  background: var(--dull-white);
  padding: 20px 0px 14px 172px;

  .banner-content {
    .topsection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 70px;

      .left {
        width: 100%;

        h6 {
          color: #000;
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }

        p {
          color: #000;
          font-family: "Poppins";
          font-size: 32px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }

        button {
          padding: 12px 29px;
          border-radius: 36px;
          background: #000;
          color: #fff;
          text-align: center;
          font-family: "Coco Gothic";
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border: none;
        }
      }

      .right {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .banner-creater {
    background: var(--dull-white);
    padding: 20px 0px 14px 20px;

    .banner-content {
      .topsection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 70px;

        .left {
          width: 100%;

          h6 {

            font-size: 40px;

          }

          p {

            font-size: 25px;

          }

          button {

            font-size: 15px;

          }
        }

        .right {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .banner-creater {
    background: var(--dull-white);
    padding: 20px 0px 14px 20px;

    .banner-content {
      .topsection {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        gap: 20px;

        .left {
          width: 100%;

          h6 {

            font-size: 30px;

          }

          p {

            font-size: 17px;

          }

          button {

            font-size: 14px;

          }
        }

        .right {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .banner-creater {
    background: var(--dull-white);
    padding: 0px 10px 14px 10px;

    .banner-content {
      .topsection {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;

        .left {
          width: 100%;
          order: 2;
          text-align: center;


          h6 {

            font-size: 20px;

          }

          p {

            font-size: 16px;

          }

          button {

            font-size: 14px;

          }
        }

        .right {
          width: 100%;
        }
      }
    }
  }
}

//banner end
// happay customer say  start

.tiktok-enterpreneur {
  background: #f5f5f7;
  padding-left: 424px;
  padding-right: 196px;
  padding-top: 108px;
  padding-bottom: 108px;

  .enterpreneus-content {
    display: flex;

    .right {
      width: 100%;
      display: flex;

      .background-divs {
        width: 100%;
        height: 657px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px dashed #000;
        display: flex;

        .enterpreneur-img {
          position: absolute;
          left: 189px;
          padding-block: 44px;
          border-radius: 8px;
        }

        .right-content {
          padding-top: 102px;
          padding-bottom: 81px;
          padding-right: 61px;
          padding-left: 333px;

          p {
            color: #000;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .subheading {
            h3 {
              color: #000;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .back-btn {
    margin-top: 91px;
  }
}

@media screen and (max-width: 1650px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 424px;
    padding-right: 196px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-divs {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px dashed #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 189px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 1450px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 400px;
    padding-right: 172px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-divs {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px dashed #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 159px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 200px;
    padding-right: 86px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-divs {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px dashed #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 40px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 10px;
            padding-left: 400px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .back-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-divs {
          width: 100%;
          height: unset;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px dashed #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .enterpreneur-img {
            position: unset;
            // left: 350px;
            // padding-block: 180px;
            text-align: center;

            border-radius: 8px;
            width: 50%;
          }

          .right-content {
            padding: 30px;
            // width: 906px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 17px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }

    .back-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .tiktok-enterpreneur {
    padding: 20px;

    .back-btn {
      margin-top: 50px;
    }

    
    .enterpreneus-content{
      .right{
        .background-divs{
          .right-content{
            padding: 20px;

            p{
              font-size: 13px !important;
            }
            .subheading{
              h3{
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

.happaycustomer {
  background: var(--dull-white);

  .customer-content {
    padding: 48px 162px 42px 217px;

    .title {
      h6 {
        color: #493f72;
        text-align: center;
        font-family: "Rugen";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .happaycustomer {
    background: var(--dull-white);

    .customer-content {
      padding: 48px 20px 42px 20px;

      .title {
        h6 {

          font-size: 40px;

        }
      }
    }
  }

}

@media screen and (max-width: 1200px) {
  .happaycustomer {
    background: var(--dull-white);

    .customer-content {
      padding: 48px 20px 42px 20px;

      .title {
        h6 {

          font-size: 30px;

        }
      }
    }
  }

}

@media screen and (max-width: 850px) {
  .happaycustomer {
    background: var(--dull-white);

    .customer-content {
      padding: 48px 10px 42px 10px;
      .title {
        h6 {
          font-size: 15px;
        }
      }
    }
  }

}

// @media screen and (max-width: 600px) {
//   .happaycustomer {
//     background: var(--dull-white);

//     .customer-content {
//       padding: 30px 10px 30px 10px;

//       .title {
//         h6 {

//           font-size: 12px;

//         }
//       }
//     }

//     .tiktok-enterpreneur {
//       background: #f5f5f7;
//       padding-left: 250px;
//       padding-right: 100px;
//       padding-top: 108px;
//       padding-bottom: 108px;

//       .enterpreneus-content {
//         display: flex;


//         .right {
//           width: 100%;
//           display: flex;

//           .background-div {
//             width: 100%;
//             height: 657px;
//             flex-shrink: 0;
//             border-radius: 8px;
//             border: 1px dashed #000;
//             display: flex;


//             .enterpreneur-img {
//               position: absolute;
//               left: 50px;
//               padding-block: 70px;
//               border-radius: 8px;
//               width: 30%;
//             }

//             .right-contents {

//               // padding-top: 102px;
//               // padding-bottom: 81px;
//               // padding-right: 61px;
//               padding: 20px !important;


//               p {
//                 color: #000;
//                 font-family: "Poppins";
//                 font-size: 32px;
//                 font-style: normal;
//                 font-weight: 500;
//                 line-height: normal;

//               }

//               .subheading {
//                 h3 {
//                   color: #000;
//                   font-family: "Poppins";
//                   font-size: 24px;
//                   font-style: normal;
//                   font-weight: 400;
//                   line-height: normal;
//                 }
//               }
//             }
//           }
//         }
//       }

//       .back-btn {
//         margin-top: 91px;
//       }
//     }
//   }
// }

// happay customer  end
// socialmedia start
.socialmedia {
  background: var(--dull-white);

  .smedia-content {
    padding: 0px 350px 0px 350px;

    .title {
      h6 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 58px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .socialmedia {
    background: var(--dull-white);

    .smedia-content {
      padding: 0px 100px 0px 100px;


    }
  }
}

@media screen and (max-width: 1000px) {
  .socialmedia {
    background: var(--dull-white);

    .smedia-content {
      padding: 0px 10px 0px 10px;

      .title {
        padding-top: 50px;

        h6 {

          font-size: 18px;

        }
      }
    }
  }
}

//socialmedia end

//got creater start
.gotback-creater {
  .guide-gotback {
    background: var(--black);
  }
}

// got creater end
// courses start
.courses-creater {
  .courses-guide {
    .courses {
      padding: 70px 300px 72px 300px;
      background: var(--black);

      .title {
        padding-top: 30px;
        background: white;

        h6 {
          color: #493f72;
          text-align: center;
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
      }

      .courses-contents {
        width: 100%;
        // border-radius: 9px;
        background: #fff;
        // height: 560px;
        display: flex;
        padding: 81px 71px 81px 71px;

        .left {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 50px;
          // align-items: center;

          h6 {
            color: #493f72;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }

          p {
            color: #483f70;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }

        .right {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          video {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .courses-creater {
    .courses-guide {
      .courses {
        padding: 70px 100px 72px 100px;
        background: var(--black);

        .title {
          padding-top: 30px;
          background: white;

          // h6 {}
        }

        .courses-content {
          width: 100%;
          // border-radius: 9px;
          background: #fff;
          // height: 560px;
          display: flex;
          padding: 81px 20px 81px 20px;

          .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            // align-items: center;

            // h6 {}

            // p {}
          }

          .right {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .courses-creater {
    .courses-guide {
      .courses {
        padding: 70px 20px 72px 20px;
        background: var(--black);

        .title {
          padding-top: 30px;
          background: white;

          h6 {

            font-size: 40px;

          }
        }

        .courses-content {
          width: 100%;
          // border-radius: 9px;
          background: #fff;
          // height: 560px;
          display: flex;
          padding: 81px 20px 81px 20px;

          .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;
            // align-items: center;

            h6 {

              font-size: 40px;

            }

            p {

              font-size: 25px;

            }
          }

          .right {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .courses-creater {
    .courses-guide {
      .courses {
        padding: 20px 20px 20px 20px;
        background: var(--black);

        .title {
          padding-top: 30px;
          background: white;

          h6 {

            font-size: 17px;

          }
        }

        .courses-content {
          width: 100%;
          // border-radius: 9px;
          background: #fff;
          // height: 560px;
          display: flex;
          flex-direction: column;
          padding: 40px 0px 40px 0px;

          .left {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            order: 2;
            padding-left: 10px;
            align-items: center;
            padding-top: 20px;
            // padding: 20px;

            h6 {

              font-size: 21px;

            }

            p {

              font-size: 16px;

            }
          }

          .right {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

// courses end
//freebotz start
.freebotz {
  background-color: var(--dull-white);
}

//freebotz end
//creater-faq start
// .creater-faq {
//     background: var(--dull-white);
// }

//creater-faq end