.toptext .modhead {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}
.toptext .modhead .fbimg img{
    width: 35px;
    height: 35px;
}
.toptext .modhead .reimg img{
    width: 25px;
    height: 25px;
}
.toptext .modhead .siteimg img{
    width: 40px;
    height: 40px;
}
.refresh {
    display: flex;
    flex-direction: column;
    padding: 22px 5px;
    
}
.refresh h2 {
    color: #000;
    font-size: 17px;
    font-family: "poppins";
    text-align: left;
    font-weight: 500;
}
.refresh p {
    color: #000;
    font-size: 15px;
    font-family: "poppins";
    padding-top: 7px;
}
.refresh a {
    color: blue;
    font-size: 15px;
    font-weight: 500;
}
.refresh a:hover {
    text-decoration: underline;
}
.butonsec{
    display: flex;
    padding: 10px ;
}
.butonsec .leftbtn {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.butonsec .leftbtn button {
    width: 95%;
    height: 36px;
    background: #1B74E4;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 15px;
    font-family: "poppins";
}
.butonsec .rightbtn {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.butonsec .rightbtn button {
    width: 95%;
    height: 36px;
    border-radius: 5px;
    border: none;
    color: #000;
    background: #E4E6EB;
    font-size: 15px;
    font-family: "poppins";
}
.butonsec .rightbtn button:hover {
    background: lightgray;
}
.botomsec {
    padding: 2px 16px;
}
.botomsec p {
    color: #65676B;
    font-weight: 400;
    font-size: 13px;
    font-family: "poppins";
    line-height: normal;

}
.botomsec a:hover {
    text-decoration: underline;
}