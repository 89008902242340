.main .tophead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 26px 368px 70px 403px;
}
.main .tophead img {
  width: 100%;
}
.main .tophead h2 {
  width: 716px;
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.main .tophead > p {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 16.12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.main .tophead .writer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
  padding-right: 40px;
}
.main .tophead .writer p {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main .intro {
  width: 100%;
  padding: 10px 237px;
}
.main .intro h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}
.main .intro p {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main .intro ul > li {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main .intro ol > li {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main .intro .publish {
  max-width: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  padding-top: 55px;
  padding-bottom: 35px;
}
.main .intro .publish p {
  color: #000;
  font-family: "Poppins";
  font-size: 16.12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 3.546px;
}

@media screen and (max-width: 1700px) {
  .main .intro {
    width: 1446px;
    margin: auto;
    width: 100%;
    padding: 10px 240px;
  }

  .main .intro p {
    color: #000;
    font-family: "Poppins";
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .main .intro ul > li {
    color: #000;
    font-family: "Poppins";
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .main .intro ol > li {
    color: #000;
    font-family: "Poppins";
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
@media screen and (max-width: 1500px) {
  .main .tophead {
    padding: 26px 320px 70px 320px;
  }

  .main .tophead h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
  }
  .main .intro p {
    font-size: 16px;
  }
  .main .intro h2 {
    font-size: 26px;
  }
  .main .intro ul > li {
    font-size: 16px;
  }
  .main .intro ol > li {
    font-size: 16px;
  }
  .main .intro .publish p {
    font-size: 13.12px;
  }
}
@media screen and (max-width: 1300px) {
  .main .tophead h2 {
    font-size: 30px;
  }

  .main .tophead {
    padding: 26px 250px 70px 250px;
  }
  .main .intro p {
    font-size: 20px;
  }
  .main .intro h2 {
    font-size: 22px;
  }
  .main .intro ul > li {
    font-size: 13px;
  }
  .main .intro ol > li {
    font-size: 13px;
  }
  .main .intro .publish p {
    font-size: 16.12px;
  }
  .main .intro {
    width: 100%;
    padding: 10px 150px;
  }
}
@media screen and (max-width: 600px) {
  .main .tophead {
    padding: 23px;
  }
  .main .tophead h2 {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
  }
  .main .tophead .writer {
    padding: 0px;
  }
  .main .tophead .writer p {
    font-size: 10px;
    font-weight: 300;
  }
  .main .tophead > p {
    font-size: 10.12px;
    font-weight: 300;
  }
  .main .intro {
    width: 100%;
    padding: 10px 23px;
  }
  .main .intro p {
    font-size: 13px;
    font-weight: 300;
    padding: 0 !important;
  }
  .main .intro h2 {
    font-size: 17px;
    font-weight: 500;
    padding: 0 !important;
  }
  .main .intro ul > li {
    font-size: 10px;
    font-weight: 300;
  }
  .main .intro ul {
    padding: 0px 0px !important;
    /* padding-right: 23px !important; */
  }
  .main .intro ol {
    padding: 0px 15px !important;
    /* padding-right: 23px !important; */
  }
  .main .intro ol > li {
    font-size: 10px;
    font-weight: 300;
    padding: 0 !important;
  }
  .main .intro .publish p {
    font-size: 9px;
    font-weight: 300;
  }
  .main .intro .publish {
    padding-top: 5px;
    padding-bottom: 8px;
  }
}
