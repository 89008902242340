.agencyBanner {
    width: 100%;
    background: #F5F5F7;
    ;

    .banner-content {
        padding-left: 320px;
        padding-right: 320px;
        padding-bottom: 77px;
        padding-top: 89px;

        h2 {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }

        p {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .feature-content {

            .title {
                padding-top: 69px;
                // padding-bottom: 14px;

                span {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .feature-content {
                    display: flex;
                    gap: 35px;
                    width: 100%;
                    padding-top: 14px;

                    .leftFeature {
                        width: 50%;
                        border-radius: 6.71px;
                        background: #FFF;
                        height: 390px;
                        box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);

                        .left-title {
                            display: flex;
                            // justify-content: center;
                            // align-items: center;
                            gap: 25px;
                            padding-top: 73px;
                            padding-left: 83px;

                            .right-content {


                                h6 {
                                    color: #000;
                                    font-family: "Poppins";
                                    font-size: 35.542px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 42.651px;
                                    /* 120% */
                                }

                                span {
                                    color: #000;
                                    font-family: "Poppins";
                                    font-size: 20.733px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 31.099px;
                                    /* 150% */
                                    letter-spacing: 0.207px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        .left-paragraph {

                            width: 100%;
                            padding-left: 83px;
                            padding-right: 83px;
                            padding-top: 34px;

                            p {
                                color: #7E817E;
                                font-family: "Poppins";
                                font-size: 22.214px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 33.321px;
                                /* 150% */
                            }
                        }
                    }

                }
            }
        }

        .getStartdbtn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 58px;

            button {
                border-radius: 36px;
                border: 1px solid #000;
                background: #000;
                color: #FFF;
                text-align: center;
                font-family: "Poppins";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding: 12px 29px;
                width: 244px;
                height: 51px;
                border: none;
            }
        }
    }
}

@media screen and (max-width: 1800px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;
        ;

        .banner-content {
            padding-left: 250px;
            padding-right: 250px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 69px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        gap: 35px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);

                            .left-title {
                                display: flex;
                                // justify-content: center;
                                // align-items: center;
                                gap: 25px;
                                padding-top: 73px;
                                padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 35.542px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 20.733px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 83px;
                                padding-right: 83px;
                                padding-top: 34px;

                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 22.214px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 33.321px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;
        ;

        .banner-content {
            padding-left: 150px;
            padding-right: 150px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 27px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 69px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        gap: 35px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);

                            .left-title {
                                display: flex;
                                // justify-content: center;
                                // align-items: center;
                                gap: 25px;
                                padding-top: 73px;
                                padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 17px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 83px;
                                padding-right: 83px;
                                padding-top: 34px;

                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 19px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 33.321px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;
        ;

        .banner-content {
            padding-left: 100px;
            padding-right: 100px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 27px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 69px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        gap: 35px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);

                            .left-title {
                                display: flex;
                                // justify-content: center;
                                align-items: center;
                                gap: 25px;
                                padding-top: 73px;
                                // padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 17px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 83px;
                                padding-right: 50px;
                                padding-top: 34px;

                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 19px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 33.321px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;
        ;

        .banner-content {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 27px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 69px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        gap: 15px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);

                            .left-title {
                                display: flex;
                                // justify-content: center;
                                // align-items: center;
                                gap: 25px;
                                padding: 30px;
                                // padding-top: 73px;
                                // padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 17px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 30px;
                                padding-right: 50px;
                                padding-top: 34px;

                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 19px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 33.321px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;


        .banner-content {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 35px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 22px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 69px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 35px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            // width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);
                            width: 100%;

                            .left-title {
                                display: flex;
                                // justify-content: center;
                                // align-items: center;
                                gap: 25px;
                                padding: 30px;
                                // padding-top: 73px;
                                // padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 17px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 30px;
                                padding-right: 50px;
                                padding-top: 34px;

                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 19px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 33.321px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .agencyBanner {
        width: 100%;
        background: #F5F5F7;


        .banner-content {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 77px;
            padding-top: 89px;

            h2 {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }

            .feature-content {

                .title {
                    padding-top: 39px;
                    // padding-bottom: 14px;

                    span {
                        color: #000;
                        font-family: "Poppins";
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .feature-content {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                        width: 100%;
                        padding-top: 14px;

                        .leftFeature {
                            // width: 50%;
                            border-radius: 6.71px;
                            background: #FFF;
                            height: 390px;
                            box-shadow: 0px 6.70968px 20.12903px 0px rgba(0, 0, 0, 0.15);
                            width: 100%;

                            .left-title {
                                display: flex;
                                flex-direction: column;
                                // justify-content: center;
                                // align-items: center;
                                gap: 25px;
                                padding: 20px;
                                // padding-top: 73px;
                                // padding-left: 83px;

                                .right-content {


                                    h6 {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 25px;
                                        font-style: normal;
                                        font-weight: 600;
                                        line-height: 42.651px;
                                        /* 120% */
                                    }

                                    span {
                                        color: #000;
                                        font-family: "Poppins";
                                        font-size: 15px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 31.099px;
                                        /* 150% */
                                        letter-spacing: 0.207px;
                                        text-transform: uppercase;
                                    }
                                }
                            }

                            .left-paragraph {

                                width: 100%;
                                padding-left: 10px;
                                padding-right: 10px;
                                padding-top: 10px;


                                p {
                                    color: #7E817E;
                                    font-family: "Poppins";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 23px;
                                    /* 150% */
                                }
                            }
                        }

                    }
                }
            }

            .getStartdbtn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 58px;

                button {
                    border-radius: 36px;
                    border: 1px solid #000;
                    background: #000;
                    color: #FFF;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 12px 29px;
                    width: 244px;
                    height: 51px;
                    border: none;
                }
            }
        }
    }
}

// agency banner end 

// agency banner start 
.agencyPartner {
    width: 100%;
    background: #F5F5F7;

    .agencybanner-content {
        padding-left: 273px;
        padding-right: 273px;

        .title {

            h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .agency-cards {
            width: 100%;
            // display: flex;
            // justify-content: center;
            gap: 17px;
            // flex-wrap: wrap;
            padding-top: 14px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .firstCard {
                width: 446.284px;
                // height: 261.459px;

                background: #FFF;

                .card-title {
                    display: flex;
                    gap: 15px;
                    padding-top: 49px;
                    padding-left: 70px;

                    .left-content {
                        h6 {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 23.802px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 28.562px;
                            /* 120% */
                        }

                        span {
                            color: #000;
                            font-family: "Poppins";
                            font-size: 13.884px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20.827px;
                            /* 150% */
                            letter-spacing: 0.139px;
                            text-transform: uppercase;
                        }
                    }

                }

                .card-paragrapgh {
                    padding-left: 70px;
                    padding-top: 23px;
                    padding-bottom: 48px;
                    padding-right: 69px;

                    p {
                        color: #7E817E;
                        font-family: "Poppins";
                        font-size: 14.876px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22.314px;
                        /* 150% */
                    }
                }

            }
        }

        .showMore-btn {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 68px;
            padding-bottom: 101px;

            button {
                width: 169px;
                height: 46.222px;
                flex-shrink: 0;
                border-radius: 6.5px;
                border: 0.722px solid #000;
                color: #000;
                text-align: center;
                font-family: "Poppins";
                font-size: 17.333px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                background: #F5F5F7;
            }
        }
    }
}

@media screen and (max-width: 1750px) {
    .agencyPartner {
        width: 100%;
        background: #F5F5F7;

        .agencybanner-content {
            padding-left: 190px;
            padding-right: 273px;

            .title {

                h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .agency-cards {
                width: 100%;
                // display: flex;
                // justify-content: center;
                gap: 17px;
                // flex-wrap: wrap;
                padding-top: 14px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .firstCard {
                    width: 446.284px;
                    // height: 261.459px;

                    background: #FFF;

                    .card-title {
                        display: flex;
                        gap: 15px;
                        padding-top: 49px;
                        padding-left: 70px;

                        .left-content {
                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 23.802px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.562px;
                                /* 120% */
                            }

                            span {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 13.884px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.827px;
                                /* 150% */
                                letter-spacing: 0.139px;
                                text-transform: uppercase;
                            }
                        }

                    }

                    .card-paragrapgh {
                        padding-left: 70px;
                        padding-top: 23px;
                        padding-bottom: 48px;
                        padding-right: 69px;

                        p {
                            color: #7E817E;
                            font-family: "Poppins";
                            font-size: 14.876px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.314px;
                            /* 150% */
                        }
                    }

                }
            }

            .showMore-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 68px;
                padding-bottom: 101px;

                button {
                    width: 169px;
                    height: 46.222px;
                    flex-shrink: 0;
                    border-radius: 6.5px;
                    border: 0.722px solid #000;
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 17.333px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: #F5F5F7;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .agencyPartner {
        width: 100%;
        background: #F5F5F7;

        .agencybanner-content {
            padding-left: 30px;
            padding-right: 20px;

            .title {

                h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .agency-cards {
                width: 100%;
                // display: flex;
                // justify-content: center;
                gap: 10px;
                // flex-wrap: wrap;
                padding-top: 14px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .firstCard {
                    width: 100%;
                    // width: 446.284px;
                    // height: 261.459px;

                    background: #FFF;

                    .card-title {
                        display: flex;
                        gap: 15px;
                        padding-top: 49px;
                        padding-left: 50px;

                        .left-content {
                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 23.802px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.562px;
                                /* 120% */
                            }

                            span {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 13.884px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.827px;
                                /* 150% */
                                letter-spacing: 0.139px;
                                text-transform: uppercase;
                            }
                        }

                    }

                    .card-paragrapgh {
                        padding-left: 50px;
                        padding-top: 23px;
                        padding-bottom: 48px;
                        padding-right: 69px;

                        p {
                            color: #7E817E;
                            font-family: "Poppins";
                            font-size: 14.876px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.314px;
                            /* 150% */
                        }
                    }

                }
            }

            .showMore-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 68px;
                padding-bottom: 101px;

                button {
                    width: 169px;
                    height: 46.222px;
                    flex-shrink: 0;
                    border-radius: 6.5px;
                    border: 0.722px solid #000;
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 17.333px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: #F5F5F7;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .agencyPartner {
        width: 100%;
        background: #F5F5F7;

        .agencybanner-content {
            padding-left: 30px;
            padding-right: 20px;

            .title {

                h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .agency-cards {
                width: 100%;
                // display: flex;
                // justify-content: center;
                gap: 10px;
                // flex-wrap: wrap;
                padding-top: 14px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);

                .firstCard {
                    width: 100%;
                    // width: 446.284px;
                    // height: 261.459px;

                    background: #FFF;

                    .card-title {
                        display: flex;
                        gap: 15px;
                        padding-top: 49px;
                        padding-left: 20px;

                        .left-content {
                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 23.802px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.562px;
                                /* 120% */
                            }

                            span {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 13.884px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.827px;
                                /* 150% */
                                letter-spacing: 0.139px;
                                text-transform: uppercase;
                            }
                        }

                    }

                    .card-paragrapgh {
                        padding-left: 20px;
                        padding-top: 23px;
                        padding-bottom: 48px;
                        padding-right: 69px;

                        p {
                            color: #7E817E;
                            font-family: "Poppins";
                            font-size: 14.876px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.314px;
                            /* 150% */
                        }
                    }

                }
            }

            .showMore-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 68px;
                padding-bottom: 101px;

                button {
                    width: 169px;
                    height: 46.222px;
                    flex-shrink: 0;
                    border-radius: 6.5px;
                    border: 0.722px solid #000;
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 17.333px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: #F5F5F7;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .agencyPartner {
        width: 100%;
        background: #F5F5F7;

        .agencybanner-content {
            padding-left: 30px;
            padding-right: 20px;

            .title {

                h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .agency-cards {
                width: 100%;
                // display: flex;
                // justify-content: center;
                gap: 10px;
                // flex-wrap: wrap;
                padding-top: 14px;
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .firstCard {
                    width: 100%;
                    // width: 446.284px;
                    // height: 261.459px;

                    background: #FFF;

                    .card-title {
                        display: flex;
                        gap: 15px;
                        padding-top: 49px;
                        padding-left: 20px;

                        .left-content {
                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 23.802px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.562px;
                                /* 120% */
                            }

                            span {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 13.884px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.827px;
                                /* 150% */
                                letter-spacing: 0.139px;
                                text-transform: uppercase;
                            }
                        }

                    }

                    .card-paragrapgh {
                        padding-left: 20px;
                        padding-top: 23px;
                        padding-bottom: 48px;
                        padding-right: 10px;

                        p {
                            color: #7E817E;
                            font-family: "Poppins";
                            font-size: 14.876px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.314px;
                            /* 150% */
                        }
                    }

                }
            }

            .showMore-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 68px;
                padding-bottom: 101px;

                button {
                    width: 169px;
                    height: 46.222px;
                    flex-shrink: 0;
                    border-radius: 6.5px;
                    border: 0.722px solid #000;
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 17.333px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: #F5F5F7;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .agencyPartner {
        width: 100%;
        background: #F5F5F7;

        .agencybanner-content {
            padding-left: 30px;
            padding-right: 20px;
            padding: 10px;
            .title {

                h3 {
                    color: #000;
                    font-family: "Poppins";
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding: 0 20px;
                }
            }
            .agency-cards {
                width: 100%;
                // display: flex;
                // justify-content: center;
                gap: 10px;
                // flex-wrap: wrap;
                padding-top: 14px;
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                .firstCard {
                    width: 100%;
                    // width: 446.284px;
                    // height: 261.459px;

                    background: #FFF;

                    .card-title {
                        display: flex;
                        gap: 15px;
                        padding-top: 49px;
                        padding-left: 20px;

                        .left-content {
                            h6 {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 23.802px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28.562px;
                                /* 120% */
                            }

                            span {
                                color: #000;
                                font-family: "Poppins";
                                font-size: 13.884px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20.827px;
                                /* 150% */
                                letter-spacing: 0.139px;
                                text-transform: uppercase;
                            }
                        }

                    }

                    .card-paragrapgh {
                        padding-left: 20px;
                        padding-top: 23px;
                        padding-bottom: 48px;
                        padding-right: 10px;

                        p {
                            color: #7E817E;
                            font-family: "Poppins";
                            font-size: 14.876px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 22.314px;
                            /* 150% */
                        }
                    }

                }
            }

            .showMore-btn {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-top: 68px;
                padding-bottom: 101px;

                button {
                    width: 169px;
                    height: 46.222px;
                    flex-shrink: 0;
                    border-radius: 6.5px;
                    border: 0.722px solid #000;
                    color: #000;
                    text-align: center;
                    font-family: "Poppins";
                    font-size: 17.333px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    background: #F5F5F7;
                }
            }
        }
    }
}





// agency banner end