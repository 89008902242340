.home-banner {
  padding: 100px 223px;
  background: #f5f5f7;

  .left {
    width: 70%;
  }

  .right {
    width: 30%;

    .banner-img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1650px) {
  .home-banner {
    padding: 100px 150px;
    background: #f5f5f7;

    .left {
      width: 70%;
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .home-banner {
    padding: 100px 100px;
    background: #f5f5f7;

    .left {
      width: 70%;

      h1 {
        font-size: 50px;
      }

      h3 {
        font-size: 30px;
      }
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .home-banner {
    padding: 100px 40px;
    background: #f5f5f7;

    .left {
      width: 70%;

      h1 {
        font-size: 50px;
      }

      h4 {
        font-size: 27px;
      }
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .home-banner {
    padding: 100px 20px;
    background: #f5f5f7;

    .left {
      width: 70%;

      h1 {
        font-size: 40px;
      }

      h4 {
        font-size: 22px;
      }
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .home-banner {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .left {
      width: 100%;
      h1 {
        font-size: 24px;
        text-align: center;
      }
      h4 {
        font-size: 16px;
        text-align: center;
      }
      button {
        font-size: 18px;
        width: 100%;
      }
    }
    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      .banner-img {
        width: 100%;
      }
    }
  }
}

// trusted css start
.trusted {
  padding: 40px 250px;
  text-align: center;
  background: #000;

  span {
    color: #fff;
    text-align: center;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // letter-spacing: -2.4px;
  }

  .img-sec {
    gap: 70px;
  }
}

// people css start
.people {
  padding: 83px 235px;
  background: #f5f5f7;
  gap: 10px;

  .left {
    width: 50%;
  }

  .right {
    width: 50%;
  }
}

@media screen and (max-width: 1700px) {
  .people {
    padding: 83px 150px;
    background: #f5f5f7;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1500px) {
  .people {
    padding: 83px 100px;
    background: #f5f5f7;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1400px) {
  .people {
    padding: 83px 80px;
    background: #f5f5f7;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;

      h1 {
        font-size: 50px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .people {
    padding: 83px 40px;
    background: #f5f5f7;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;

      h1 {
        font-size: 40px;
      }

      p {
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .people {
    padding: 83px 40px;
    background: #f5f5f7;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .left {
      width: 100%;
      // width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .right {
      // width: 50%;
      width: 100%;

      h1 {
        font-size: 40px;
      }

      p {
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .people {
    padding: 30px 20px; 
    background: #f5f5f7;

    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .left {
      width: 100%;
      // width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .right {
      // width: 50%;
      width: 100%;

      h1 {
        font-size: 21px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}

// people css end

// social cards start
.social-cards {
  padding: 80px 74px 240px 72px;
  background: #000;
  position: relative;

  h1 {
    margin-bottom: 140px;
  }

  .social-card-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    gap: 35px;
    z-index: 2;

    img {
      width: 324px;
    }
  }

  .platform {
    position: absolute;
    z-index: 1;
    top: 65%;
    
  }
}

// .image-container {
//   position: relative;
//   display: inline-block;
// }

// .hover-content {
//   display: none;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: white;
//   color: white;
//   text-align: center;
//   padding: 20px;
//   border-radius: 10px;
// }

// .image-container:hover .hover-content {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }
.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.hover-content {
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  text-align: center;
  padding: 10px;
  transition: bottom 0.5s ease;
  border-radius: 10px;
  text-align: center;
}

.image-container:hover .hover-content {
  bottom: 0;
}

@media screen and (max-width: 1550px) {
  .social-cards {
    padding: 160px 100px;

    h1 {
      margin-bottom: 50px;
    }

    .social-card-content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #f5f5f7;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);
        padding: 36px 54px 29px 34px;
      }
    }

    .platform {
      top: 68%;
    }
  }
}

@media screen and (max-width: 1200px) {
  .social-cards {
    padding: 100px 40px;

    .social-card-content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #f5f5f7;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);
        padding: 36px 54px 29px 34px;
      }
    }

    .platform {
      top: 66%;
    }
  }
}

@media screen and (max-width: 600px) {
  .social-cards {
    padding: 50px 20px;

    h1 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .social-card-content {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: center;
      align-items: center;

      .content {
        width: 100%;
        // height: 100%;
        // width: 355.494px;
        // height: 363.394px;
        border-radius: 6.32px;
        background: #f5f5f7;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);
        padding: 36px 20px 29px 20px;
      }
    }

    .platform {
      top: 96%;
    }
  }
}

// social cards end

// usechart start
.usechat {
  padding: 64px 97px 224px 97px;

  .usechat-title {
    h1 {
      width: 1136px;
    }
  }

  .usechat-content {
    // .right {
    //   width: 50%;
    //   margin-left: 18px;
    // }
    .right {
      width: 50%;
      margin-left: 18px;
      position: relative;
      cursor: pointer;

      .image-container {
        position: relative;
        overflow: hidden;

        .img-fluid {
          display: block;
          width: 100%;
          height: auto;
        }
        

        

        .overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: #daaffb;
          // background-color: var(--dull-white);
          text-align: center;
          transition: height 0.5s ease-in-out; // Make the height transition slower

          transform-origin: center bottom;
          transform: scaleY(0);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 40px;

          .overlayContent {
            h4 {
              display: none;
            }

            p {
              display: none;
            }
          }
        }
      }

      &:hover {
        .overlay {
          height: 100%; // Expand the white layer to cover the entire image on hover

          transform: scaleY(1);

          .overlayContent {
            // padding-left: 90px;
            // padding-right: 90px;
            // padding-top: 30px;

            h4 {
              display: block;
              color: white;
              font-family: "Poppins";
              font-size: 40px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 10px;
            }

            p {
              display: block;
              color: white;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }

    .left {
      width: 50%;
      position: relative;
      cursor: pointer;

      .image-container {
        position: relative;
        overflow: hidden;

        .img-fluid {
          display: block;
          width: 100%;
          height: auto;
        }

        .overlay {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background: white;
          // background-color: var(--dull-white);
          text-align: center;
          transition: height 0.5s ease-in-out; // Make the height transition slower
          pointer-events: none;
          transform-origin: center bottom;
          transform: scaleY(0);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 40px;

          .overlayContent {
            h4 {
              display: none;
            }

            p {
              display: none;
            }
          }
        }
      }

      &:hover {
        .overlay {
          height: 100%; // Expand the white layer to cover the entire image on hover

          transform: scaleY(1);

          .overlayContent {
            // padding-left: 90px;
            // padding-right: 90px;
            // padding-top: 30px;

            h4 {
              display: block;
              color: black;
              font-family: "Poppins";
              font-size: 40px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            p {
              display: block;
              color: #000;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .free-btn {
    margin-top: 242px;
  }
}

@media screen and (max-width: 1700px) {
  .usechat {
    padding: 64px 97px 200px 97px;
    background: #f5f5f7;

    .usechat-title {
      h1 {
        width: 100%;
      }
    }

    .usechat-content {
      .right {
        width: 50%;
        margin-left: 18px;
      }

      .left {
        width: 50%;
      }
    }

    .free-btn {
      margin-top: 200px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .usechat {
    padding: 64px 100px;
    background: #f5f5f7;

    .usechat-content {
      .right {
        width: 50%;
        margin-left: 18px;
      }

      .left {
        width: 50%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .usechat {
    padding: 64px 40px;
    background: #f5f5f7;

    .usechat-content {
      .right {
        width: 50%;
      }

      .left {
        width: 50%;
      }
    }
  }
}

// @media screen and (max-width: 1000px) {
//   .usechat {
//     padding: 64px 40px;
//     background: #f5f5f7;
//     .usechat-content {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       .right {
//         width: 100%;
//       }
//       .left {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         img {
//           width: 100%;
//           max-width: 400px;
//         }
//       }
//     }
//     .second {
//       display: flex;
//       flex-direction: column-reverse;
//       align-items: center;
//       row-gap: 20px;
//       .left {
//         width: 100%;
//         margin: 0px;
//         display: flex;
//         flex-direction: column;
//       }
//       .right {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//         img {
//           width: 100%;
//           max-width: 400px;
//         }
//       }
//     }
//   }
// }
@media screen and (max-width: 600px) {
  .usechat {
    padding: 50px 20px;
    background: #f5f5f7;

    .usechat-title {
      h1 {
        font-size: 20px;
      }
    }

    .usechat-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .right {
        width: 100%;
        margin-left: 0;
        margin-top: 48px;
      }

      .left {
        width: 100%;
      }
    }

    .second {
      flex-direction: column-reverse;

      .left {
        margin-top: 48px;
      }
    }
  }
}

// usechart end

// Discover
.discover {
  padding: 90px 224px;
  background: #000;

  .discover-content {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .content {
      p {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1720px) {
  .discover {
    padding: 90px 150px;

    .discover-content {
      // gap: 100px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .content {
        p {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .discover {
    padding: 90px 50px;

    .discover-content {
      // gap: 100px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .content {
        p {
          text-align: center;
          font-size: 19px;
        }

        h3 {
          font-size: 30px;
        }

        h4 {
          font-size: 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 1130px) {
  .discover {
    padding: 90px 20px;

    h2 {
      font-size: 28px;
    }

    .discover-content {
      // gap: 100px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .content {
        p {
          text-align: center;
          font-size: 17px;
        }

        h3 {
          font-size: 25px;
        }

        h4 {
          font-size: 19px;
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .discover {
    padding: 90px 20px;

    h2 {
      font-size: 28px;
    }

    .discover-content {
      // gap: 100px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      .content {
        p {
          text-align: center;
          font-size: 17px;
        }

        h3 {
          font-size: 25px;
        }

        h4 {
          font-size: 19px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .discover {
    padding: 90px 20px;

    h2 {
      font-size: 20px;
    }

    .discover-content {
      // gap: 100px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;

      .content {
        p {
          text-align: center;
          font-size: 17px;
        }

        h3 {
          font-size: 25px;
        }

        h4 {
          font-size: 19px;
        }
      }
    }
  }
}

// Enterpreneur
.enterpreneur {
  background: #f5f5f7;
  padding-left: 424px;
  padding-right: 196px;
  padding-top: 108px;
  // padding-bottom: 108px;

  .enterpreneus-content {
    display: flex;

    .right {
      width: 100%;
      display: flex;

      .background-div {
        width: 100%;
        height: 657px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #000;
        display: flex;

        .enterpreneur-img {
          position: absolute;
          left: 189px;
          padding-block: 44px;
          border-radius: 8px;
        }

        .right-content {
          padding-top: 102px;
          padding-bottom: 81px;
          padding-right: 61px;
          padding-left: 333px;

          p {
            color: #000;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .subheading {
            h3 {
              color: #000;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1650px) {
  .enterpreneur {
    background: #f5f5f7;
    padding-left: 424px;
    padding-right: 196px;
    padding-top: 108px;
    // padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 189px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .enterpreneur {
    background: #f5f5f7;
    padding-left: 400px;
    padding-right: 172px;
    padding-top: 108px;
    // padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 159px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .enterpreneur {
    background: #f5f5f7;
    padding-left: 200px;
    padding-right: 86px;
    padding-top: 108px;
    // padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 40px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 10px;
            padding-left: 400px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .enterpreneur {
    background: #f5f5f7;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 108px;
    // padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: unset;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .enterpreneur-img {
            position: unset;
            // left: 350px;
            // padding-block: 180px;
            text-align: center;

            border-radius: 8px;
            width: 50%;
          }

          .right-content {
            padding: unset;
            // width: 906px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 17px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .enterpreneur .enterpreneus-content .right .background-div .right-content {
    padding: 20px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card .cardTitle{
    font-size: 25px;
  }
  .introMessengerMainDiv .firstMainDiv .indust-card .cardText{
    font-size: 14px;
  }
  .enterpreneur .enterpreneus-content .right .background-div {
    padding: 0 !important;
  }
  .enterpreneur .enterpreneus-content .right .background-div .right-content p{
    font-size: 14px;
  }
  .enterpreneur .enterpreneus-content .right .background-div .right-content .subheading h3 {
    font-size: 14px;
  }
}

//enterpreneur end css

// Activity-Cards
.activity-card {
  background: #f5f5f7;
  padding: 91px 100px 113px 100px;

  .multi-cards {
    gap: 44px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .card {
      margin-top: 101px;
      padding: 7px 7px 26px 7px;
      border-radius: 6.658px;
      background: #fff;
      box-shadow: 0px 6.65833px 19.975px 0px rgba(0, 0, 0, 0.2);
      width: 446.108px;
      height: 382.854px;

      .card-body {
        padding-top: 5px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .activity-card {
    background: #f5f5f7;
    padding: 91px 130px 113px 130px;

    .multi-cards {
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;

      .card {
        margin-top: 101px;
        padding: 7px 7px 26px 7px;
        border-radius: 6.658px;
        background: #fff;
        box-shadow: 0px 6.65833px 19.975px 0px rgba(0, 0, 0, 0.2);
        width: 446.108px;
        height: 382.854px;

        .card-body {
          padding-top: 5px;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .activity-card {
    background: #f5f5f7;
    padding: 91px 20px 113px 20px;

    .multi-cards {
      gap: 20px;
      justify-content: center;
      flex-wrap: wrap;

      .card {
        margin-top: 101px;
        padding: 7px 7px 26px 7px;
        border-radius: 6.658px;
        background: #fff;
        box-shadow: 0px 6.65833px 19.975px 0px rgba(0, 0, 0, 0.2);
        width: 446.108px;
        height: 382.854px;

        .card-body {
          padding-top: 5px;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .activity-card {
    background: #f5f5f7;
    padding: 50px 20px 0px 20px;

    .multi-cards {
      gap: 10px;
      justify-content: center;
      flex-wrap: wrap;

      .card {
        margin-top: 15px;
        padding: 7px 7px 26px 7px;
        border-radius: 6.658px;
        background: #fff;
        box-shadow: 0px 6.65833px 19.975px 0px rgba(0, 0, 0, 0.2);
        // width: 446.108px;
        // height: 382.854px;
        width: 100%;
        height: 100%;

        .card-head {
          img {
            width: 100%;
          }
        }

        .card-body {
          padding-top: 5px;
          padding-left: 15px;
          padding-right: 15px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

// Activity card end

// Time
.time {
  padding: 102px 275px 59px 189px;

  .left {
    // width: 60%;
    width: 55%;
  }

  .right {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 45%;
    // width: 40%;
    margin-left: 74px;
  }
}

@media screen and (max-width: 1600px) {
  .time {
    padding: 102px 137.5px 59px 94.5px;

    .left {
      // width: 60%;
      width: 55%;

      h1 {
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .right {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 45%;
      // width: 40%;
      margin-left: 74px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .time {
    padding: 102px 100px 59px 70px;

    .left {
      // width: 60%;
      width: 55%;

      h1 {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .right {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 45%;
      // width: 40%;
      margin-left: 74px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .time {
    padding: 102px 30px 59px 30px;

    .left {
      // width: 60%;
      width: 55%;

      h1 {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    .right {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 45%;
      // width: 40%;
      margin-left: 74px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .time {
    padding: 102px 20px 59px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .left {
      // width: 60%;
      width: 100%;

      h1 {
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
      }
    }

    .right {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 50%;
      margin: 0;
      // width: 40%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

// Time section end

// Step-Cards
.home-stepcards {
  .stepCard-title {
    h1 {
      color: #000;
      font-family: "Poppins";
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      &.main-header {
        max-width: 65%;
        margin: auto;
      }
    }
  }
  .step-cards {
    padding: 81px 200px 0px 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .social-card-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #000;

        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;

            span {
              color: #fff;
              font-family: "Poppins";
              font-size: 25.168px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            // width: 151.158px;
            // height: 124.468px;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            // padding: 20px 29px 81px 29px;
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 20px;

            p {
              color: #fff;
              text-align: center;
              font-family: "Poppins";
              font-size: 18.876px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;
    margin-bottom: 80px;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #000;
      background: #000;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .stepCard-title {
    // margin-top: 50px;

    h1 {
      color: #000;
      font-family: "Poppins";
      font-size: 64px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;

      &.main-header {
        width: 100%;
      }
    }
  }

  .step-cards {
    // padding: 161px 392px;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 59px;
    padding-bottom: 59px;

    .social-card-content {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #420061;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;

            span {
              color: #fff;
              font-family: "Poppins";
              font-size: 25.168px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            // width: 151.158px;
            // height: 124.468px;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            // padding: 20px 29px 81px 29px;
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 20px;

            p {
              color: #fff;
              text-align: center;
              font-family: "Poppins";
              font-size: 18.876px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #8a6eff;
      background: #8a6eff;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .stepCard-title {
    // margin-top: 50px;

    h1 {
      color: #000;
      font-family: "Poppins";
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .step-cards {
    // padding: 161px 392px;
    padding-left: 170px;
    padding-right: 170px;
    padding-top: 59px;
    padding-bottom: 59px;

    .social-card-content {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #420061;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;

            span {
              color: #fff;
              font-family: "Poppins";
              font-size: 25.168px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            // width: 151.158px;
            // height: 124.468px;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            // padding: 20px 29px 81px 29px;
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 20px;

            p {
              color: #fff;
              text-align: center;
              font-family: "Poppins";
              font-size: 18.876px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #8a6eff;
      background: #8a6eff;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .stepCard-title {
    // margin-top: 50px;

    h1 {
      color: #000;
      font-family: "Poppins";
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .step-cards {
    // padding: 161px 392px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 59px;
    padding-bottom: 59px;

    .social-card-content {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #420061;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;

            span {
              color: #fff;
              font-family: "Poppins";
              font-size: 25.168px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            // width: 151.158px;
            // height: 124.468px;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            // padding: 20px 29px 81px 29px;
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 20px;

            p {
              color: #fff;
              text-align: center;
              font-family: "Poppins";
              font-size: 18.876px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #8a6eff;
      background: #8a6eff;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}

@media screen and (max-width: 600px) {
  .home-stepcards {
    .stepCard-button {
      margin-bottom: 0;
    }
  }

  .stepCard-title {
    // margin-top: 50px;

    h1 {
      color: #000;
      font-family: "Poppins";
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .step-cards {
    // padding: 161px 392px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 59px;
    padding-bottom: 59px;

    .social-card-content {
      display: flex;
      flex-wrap: wrap;
      gap: 25px;
      justify-content: center;
      align-items: center;

      .content {
        width: 100%;
        // height: 100%;
        // width: 355.494px;
        // height: 363.394px;
        border-radius: 6.32px;
        background: #420061;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);

        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;

            span {
              color: #fff;
              font-family: "Poppins";
              font-size: 21px !important;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            // width: 151.158px;
            // height: 124.468px;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            // padding: 20px 29px 81px 29px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 20px;

            p {
              color: #fff;
              text-align: center;
              font-family: "Poppins";
              font-size: 15px !important;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #8a6eff;
      background: #8a6eff;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}

// step-Cards end

//free bot  start
.freebotdm {
  .freebotdm-title {
    text-align: center;
    padding-top: 54px;
    padding-bottom: 45px;
    padding-left: 200px;
    padding-right: 200px;

    button {
      padding: 12px 29px;
      border-radius: 36px;
      border: 1px solid #fff;
      background: #000;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media screen and (max-width: 1450px) {
  .freebotdm {
    .freebotdm-title {
      text-align: center;
      padding-top: 146px;
      padding-bottom: 45px;
      padding-left: 180px;
      padding-right: 180px;

      // h2 {
      //   color: #000;
      //   font-family: "Poppins";
      //   font-size: 64px;
      //   font-style: normal;
      //   font-weight: 700;
      //   line-height: normal;
      // }

      // p {
      //   color: #000;
      //   text-align: center;
      //   font-family: "Poppins";
      //   font-size: 24px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      // }

      // button {
      //   padding: 12px 29px;
      //   border-radius: 36px;
      //   border: 1px solid #8a6eff;
      //   background: #8a6eff;
      //   color: #fff;
      //   text-align: center;
      //   font-family: "Coco Gothic";
      //   font-size: 20px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      //   // display: flex;
      //   // justify-content: center;
      //   // align-items: center;
      // }
    }
  }
}

@media screen and (max-width: 1000px) {
  .freebotdm {
    .freebotdm-title {
      text-align: center;
      padding-top: 146px;
      padding-bottom: 45px;
      padding-left: 100px;
      padding-right: 100px;

      h2 {
        color: #000;
        font-family: "Poppins";
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      // button {
      //   padding: 12px 29px;
      //   border-radius: 36px;
      //   border: 1px solid #8a6eff;
      //   background: #8a6eff;
      //   color: #fff;
      //   text-align: center;
      //   font-family: "Coco Gothic";
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      //   // display: flex;
      //   // justify-content: center;
      //   // align-items: center;
      // }
    }
  }
}

@media screen and (max-width: 750px) {
  .freebotdm {
    .freebotdm-title {
      text-align: center;
      padding: 50px 20px;
      width: 100%;

      h2 {
        color: #000;
        font-family: "Poppins";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      // button {
      //   padding: 12px 29px;
      //   border-radius: 36px;
      //   border: 1px solid #8a6eff;
      //   background: #8a6eff;
      //   color: #fff;
      //   text-align: center;
      //   font-family: "Coco Gothic";
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      // }
    }
  }
}

@media screen and (max-width: 500px) {
  .freebotdm {
    .freebotdm-title {
      text-align: center;
      padding: 50px 20px;
      width: 100%;

      h2 {
        color: #000;
        font-family: "Poppins";
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      // button {
      //   padding: 12px 29px;
      //   border-radius: 36px;
      //   border: 1px solid #8a6eff;
      //   background: #8a6eff;
      //   color: #fff;
      //   text-align: center;
      //   font-family: "Coco Gothic";
      //   font-size: 18px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: normal;
      //   // display: flex;
      //   // justify-content: center;
      //   // align-items: center;
      // }
    }
  }
}

//free bot  end
