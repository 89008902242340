.smartdelay {
    padding: 0px;

    .smartDelay {
        .smartdelay-content {
            .delayTopbar {
                background: #FFB8AA;
                width: 100%;
                height: 100px;
                display: grid;
                justify-content: center;
                align-items: center;

                h6 {
                    font-size: 20px;
                    font-weight: 700;
                    font-family: "poppins";
                    color: #323946;
                }

            }

            .middleContent {
                padding: 20px 10px 5px 40px;

                .title {
                    display: flex;
                    align-items: center;

                    h6 {
                        font-size: 20px;
                        font-weight: 500;
                        font-family: "poppins ";
                        color: #525964;


                    }

                    .orderidcancel-head {
                        margin-left: 10px;


                        .selectoptionid {
                            cursor: pointer;
                            // border: none;
                            border: 1px solid #525964;
                            padding: 5px 20px 5px 0px;
                            border-radius: 5px;
                            font-size: 20px;
                            font-weight: 500;
                            font-family: "poppins ";
                            color: #0084FF;



                            option {
                                font-size: 20px;
                                font-weight: 500;
                                font-family: "poppins ";

                                span {
                                    color: #0084FF;


                                    &:hover {
                                        text-decoration: 2px solid underline #D6D9DE;
                                        cursor: pointer;
                                        text-underline-offset: 5px;
                                    }
                                }
                            }
                        }
                    }


                }

                .idContent {
                    padding: 10px 0px 10px 0px;

                    .durationContent {


                        .durationTitle {
                            h6 {
                                font-size: 20px;
                                font-weight: 500;
                                font-family: "poppins ";
                                color: #525964;
                            }


                        }

                        .durationInput {
                            display: flex;
                            margin: 10px 0px 20px 0px;
                            gap: 10px;


                            input {
                                width: 200px;
                                padding: 10px;
                                border: 1px solid #BCC3CC;
                                border-radius: 5px;


                                &.red-border {
                                    border: 1px solid #EB5038;
                                }

                                &::placeholder {
                                    color: #8592A3;
                                    font-size: 15px;
                                }
                            }

                            .formSelect {
                                color: #8592A3;
                                font-size: 15px;


                            }

                        }

                        .durationcheckbox {

                            // .durationbox {
                            //     width: 50px;
                            //     height: 50px;
                            // }
                            .boxContent {
                                display: flex;
                                align-items: center;

                                .checkboxs {

                                    display: block;
                                    position: relative;
                                    padding-left: 35px;
                                    margin-bottom: 12px;
                                    cursor: pointer;
                                    font-size: 15px;

                                    font-family: "Poppins";
                                    user-select: none;



                                    input {
                                        position: absolute;
                                        opacity: 0;
                                        cursor: pointer;
                                        height: 0;
                                        width: 0;
                                        width: 50px;
                                        height: 50px;
                                    }

                                    .checkmark {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        // padding: 50px 50px 50px 50px;
                                        height: 25px;
                                        width: 25px;
                                        background-color: #eee;
                                        border-radius: 5px;

                                        &:after {
                                            content: "";
                                            position: absolute;
                                            display: none;
                                        }
                                    }

                                    &:hover {
                                        input~.checkmark {
                                            background-color: #ccc;
                                        }
                                    }

                                    input:checked {


                                        ~.checkmark {
                                            background-color: green;
                                        }

                                        ~.checkmark:after {
                                            display: block;
                                        }
                                    }

                                    .checkmark:after {
                                        left: 9px;
                                        top: 5px;
                                        width: 5px;
                                        height: 10px;
                                        border: solid white;
                                        border-width: 0 3px 3px 0;
                                        transform: rotate(45deg);
                                    }
                                }
                            }

                            .checkedContent {
                                padding: 10px 0px 10px 0px;

                                .mainContent {
                                    display: flex;
                                    gap: 10px;
                                    padding: 10px 0px 10px 0px;

                                    .formSelects {
                                        color: #8592A3;
                                        font-size: 15px;
                                        // font-weight: 500;
                                        font-family: "poppins";

                                    }
                                }

                                .all-timeorderfirst {
                                    // border: 1px solid red;

                                    .all-timesorder {
                                        width: 100%;
                                        border-radius: 10px;
                                        height: 41px;
                                        border: 1px solid pink;
                                        display: flex;
                                        justify-content: space-evenly;
                                        align-items: center;
                                        cursor: pointer;
                                    }

                                    .bottom-divordera {
                                        width: 100%;
                                        padding: 10px 5px 10px 10px;
                                        background: white;

                                        .div-weekdropdown2 {

                                            &:hover {
                                                background: #D6D9DE;
                                                cursor: pointer;
                                            }

                                            .bottomdiv-week2 {
                                                margin-left: 10px;
                                            }
                                        }
                                    }
                                }

                                span {
                                    color: #808A9B;
                                    font-size: 15px;
                                    font-family: "poppins";
                                }
                            }
                        }




                    }

                    .dateContent {
                        .upperContent {
                            .textboxDate {
                                width: 100%;
                                border: 1px solid #808A9B;
                                height: 41px;
                                padding: 0px 10px 0px 10px;

                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width:600px) {
    .smartdelay{
        .smartDelay{
            .smartdelay-content{
                .delayTopbar{
                    height: 70px ;
                    h6{
                        font-size: 17px;
                    }
                }
                .middleContent{
                    padding: 17px;
                    .title{
                        justify-content: space-between !important;
                        h6{
                            font-size: 18px !important;
                        }
                        .orderidcancel-head{
                            .selectoptionid{
                                font-size: 18px;
                            }
                        }
                    }
                    .idContent{
                        .durationContent{
                            .durationTitle{
                                h6{
                                    font-size: 18px;
                                }
                            }
                            .durationInput{
                                flex-direction: column !important;
                                input{
                                    width: 100%;
                                }
                            }
                            .durationcheckbox{
                                .boxContent{
                                    .checkboxs{
                                        padding-left: 0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}