//webnarFreebot start
.webnar-courses {
    background: var(--black);

    .courses {
        padding: 0px 300px 72px 300px;
        background: var(--dull-white);

        .courses-contentweb {
            width: 100%;
            // border-radius: 9px;
            background: #000;
            // height: 560px;
            display: flex;
            padding: 81px 71px 81px 71px;

            .left {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 50px;
                // align-items: center;

                h6 {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 48px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }

                p {
                    color: var(--white);
                    font-family: "Poppins";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }

            .right {
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

}

@media only screen and (max-width: 1650px) {
    .webnar-courses {
        background: var(--black);

        .courses {
            padding: 0px 100px 72px 100px;
            background: var(--dull-white);


        }

    }
}

@media only screen and (max-width: 1350px) {
    .webnar-courses {
        background: var(--black);

        .courses {
            padding: 0px 40px 72px 40px;
            background: var(--dull-white);

            .courses-contentweb {
                width: 100%;
                // border-radius: 9px;
                background: #000;
                // height: 560px;
                display: flex;
                padding: 81px 71px 81px 71px;

                .left {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 10px;
                    // align-items: center;

                    h6 {

                        font-size: 30px;

                    }

                    p {

                        font-size: 22px;

                    }
                }

                .right {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

    }
}

@media only screen and (max-width: 850px) {
    .webnar-courses {
        background: var(--black);

        .courses {
            padding: 0px 20px 72px 20px;
            background: var(--dull-white);

            .courses-contentweb {
                width: 100%;
                // border-radius: 9px;
                background: #000;
                // height: 560px;
                display: flex;
                flex-direction: column;
                padding: 81px 10px 81px 10px;

                .left {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 10px;
                    align-items: center;
                    order: 2;
                    padding-top: 20px;

                    h6 {

                        font-size: 25px;

                    }

                    p {

                        font-size: 18px;

                    }
                }

                .right {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

    }
}


//webnarFreebot end
//webnar tab start
.Tab {
    padding: 75px 268px 75px 268px;
    background: var(--dull-white);

    .tabs-container {
        .tabs {
            /* overflow-x: scroll; */
            gap: 10px;
            flex-wrap: nowrap;

            a {
                // font-family: $font4;
                color: #000;
                font-family: "Coco Gothic";
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                cursor: pointer;
                padding-inline: 20px;

                &.active {
                    // color: #ff7d05;
                    // text-decoration-line: underline;
                    color: #000;
                    font-family: "Coco Gothic";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-decoration-line: underline;

                }
            }
        }
    }



}

// First Tab1 
.tab1 {
    padding-top: 44px;


    .tab1-content {
        .tabsImage {
            display: flex;
            // display: grid;
            // grid-template-columns: repeat(1, 1fr);
            gap: 37px;

            .leftImage {
                width: 70%;
                float: left;




                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .rightImage {

                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .first-div {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .image-content {
                        position: absolute;
                        top: 70%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        text-align: center;
                        height: 58.325px;

                        margin-left: 88px;
                        margin-right: 88px;

                        span {
                            color: #FFF;
                            text-align: center;
                            font-family: "Poppins";
                            font-size: 12.961px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }





            }
        }

        .second-image {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 19px;
            padding-top: 30px;
            padding-bottom: 60px;

            .first-image {
                width: 100%;
                position: relative;

                img {
                    width: 100%;
                }

                .cardText {
                    position: absolute;
                    width: 100%;
                    top: 70%;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding-left: 52px;
                    padding-right: 52px;
                    padding-bottom: 33px;
                    text-align: center;

                    span {
                        color: #FFF;
                        // text-align: center;
                        font-family: "Poppins";
                        font-size: 12.961px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .Tab {
        padding: 75px 40px 75px 40px;
        background: var(--dull-white);

        .tabs-container {
            .tabs {
                /* overflow-x: scroll; */
                gap: 10px;
                flex-wrap: nowrap;

                a {
                    // font-family: $font4;
                    color: #000;
                    font-family: "Coco Gothic";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    cursor: pointer;
                    padding-inline: 20px;

                    &.active {
                        // color: #ff7d05;
                        // text-decoration-line: underline;
                        color: #000;
                        font-family: "Coco Gothic";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;

                    }
                }
            }
        }



    }

    .tab1 {
        padding-top: 44px;


        .tab1-content {
            .tabsImage {
                display: flex;
                // display: grid;
                // grid-template-columns: repeat(1, 1fr);
                gap: 37px;

                .leftImage {
                    width: 70%;
                    float: left;




                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightImage {

                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .first-div {
                        width: 100%;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        .image-content {
                            position: absolute;
                            top: 70%;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            text-align: center;
                            height: 58.325px;

                            margin-left: 10px;
                            margin-right: 10px;

                            span {
                                color: #FFF;
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 12.961px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }





                }
            }

            .second-image {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 19px;
                padding-top: 30px;
                padding-bottom: 60px;

                .first-image {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .cardText {
                        position: absolute;
                        width: 100%;
                        top: 70%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-bottom: 33px;
                        text-align: center;

                        span {
                            color: #FFF;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 12.961px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .Tab {
        padding: 75px 20px 75px 20px;
        background: var(--dull-white);

        .tabs-container {
            .tabs {
                /* overflow-x: scroll; */
                gap: 10px;
                flex-wrap: nowrap;

                a {
                    // font-family: $font4;
                    color: #000;
                    font-family: "Coco Gothic";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    cursor: pointer;
                    padding-inline: 20px;

                    &.active {
                        // color: #ff7d05;
                        // text-decoration-line: underline;
                        color: #000;
                        font-family: "Coco Gothic";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;

                    }
                }
            }
        }



    }

    .tab1 {
        padding-top: 20px;


        .tab1-content {
            .tabsImage {
                display: flex;
                // display: grid;
                // grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .leftImage {
                    width: 70%;
                    float: left;




                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightImage {

                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .first-div {
                        width: 100%;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        .image-content {
                            position: absolute;
                            top: 70%;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            text-align: center;
                            height: 58.325px;

                            margin-left: 10px;
                            margin-right: 10px;

                            span {
                                color: #FFF;
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }





                }
            }

            .second-image {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
                padding-top: 20px;
                padding-bottom: 60px;

                .first-image {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .cardText {
                        position: absolute;
                        width: 100%;
                        top: 70%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-bottom: 33px;
                        text-align: center;

                        span {
                            color: #FFF;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .Tab {
        padding: 75px 20px 75px 20px;
        background: var(--dull-white);

        .tabs-container {
            .tabs {
                /* overflow-x: scroll; */
                gap: 10px;
                flex-wrap: nowrap;

                a {
                    // font-family: $font4;
                    color: #000;
                    font-family: "Coco Gothic";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    cursor: pointer;
                    padding-inline: 20px;

                    &.active {
                        // color: #ff7d05;
                        // text-decoration-line: underline;
                        color: #000;
                        font-family: "Coco Gothic";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;

                    }
                }
            }
        }



    }

    .tab1 {
        padding-top: 20px;


        .tab1-content {
            .tabsImage {
                display: flex;
                flex-direction: column;
                // display: grid;
                // grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .leftImage {
                    width: 100%;
                    float: left;




                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightImage {

                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .first-div {
                        width: 100%;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        .image-content {
                            position: absolute;
                            top: 70%;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            text-align: center;
                            height: 58.325px;

                            margin-left: 10px;
                            margin-right: 10px;

                            span {
                                color: #FFF;
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }





                }
            }

            .second-image {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                padding-top: 20px;
                padding-bottom: 60px;

                .first-image {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .cardText {
                        position: absolute;
                        width: 100%;
                        top: 70%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-bottom: 33px;
                        text-align: center;

                        span {
                            color: #FFF;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .Tab {
        padding: 75px 20px 75px 20px;
        background: var(--dull-white);

        .tabs-container {
            .tabs {
                /* overflow-x: scroll; */
                gap: 10px;
                flex-wrap: nowrap;

                a {
                    // font-family: $font4;
                    color: #000;
                    font-family: "Coco Gothic";
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    cursor: pointer;
                    padding-inline: 20px;

                    &.active {
                        // color: #ff7d05;
                        // text-decoration-line: underline;
                        color: #000;
                        font-family: "Coco Gothic";
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        text-decoration-line: underline;

                    }
                }
            }
        }



    }

    .tab1 {
        padding-top: 20px;


        .tab1-content {
            .tabsImage {
                display: flex;
                flex-direction: column;
                // display: grid;
                // grid-template-columns: repeat(1, 1fr);
                gap: 10px;

                .leftImage {
                    width: 100%;
                    float: left;




                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .rightImage {

                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .first-div {
                        width: 100%;
                        position: relative;

                        img {
                            width: 100%;
                        }

                        .image-content {
                            position: absolute;
                            top: 70%;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            text-align: center;
                            height: 58.325px;

                            margin-left: 10px;
                            margin-right: 10px;

                            span {
                                color: #FFF;
                                text-align: center;
                                font-family: "Poppins";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                        }
                    }





                }
            }

            .second-image {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
                padding-top: 20px;
                padding-bottom: 60px;

                .first-image {
                    width: 100%;
                    position: relative;

                    img {
                        width: 100%;
                    }

                    .cardText {
                        position: absolute;
                        width: 100%;
                        top: 70%;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        padding-left: 10px;
                        padding-right: 10px;
                        padding-bottom: 33px;
                        text-align: center;

                        span {
                            color: #FFF;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}

// webnar tab end 