.automation {
  padding: 15px 64px 421px 64px;
}

.automation .firstDiv {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.automation .firstDiv .text {
  color: #a3a3a3;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.automation .firstDiv .text:hover {
  color: #000;
  text-decoration-line: underline;
}

.automation .secondDiv .text {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: start;
  margin: 0;
  margin-top: 10px;
}

.automation .secondDiv .secondInner {
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background: #fff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 20px 0px 20px 20px;
  margin-top: 15px;
}

.automation .secondDiv .secondInner .EmmahopDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.automation .secondDiv .secondInner .EmmahopDiv .text {
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.automation .secondDiv .secondInner .text {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-top: 4px;
}
.automation .bannerMain {
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background: #fff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.automation .bannerMain .heading {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 140px;
}
.automation .bannerMain .text {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 400px;
  margin: 0;
  margin-top: 5px;
}

.automation .bannerMain .button {
  width: 205px;
  height: 41px;
  border-radius: 8px;
  background: #000;
  padding: 9px 21px 9px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 70px;
  cursor: pointer;
}

.automation .bannerMain .button .buttonText {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;
}

/* Tab two styles */

.automation .bannerMainTwo {
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #b5b5b5;
  background: #fff;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  padding: 30px 80px 30px 250px;
  gap: 10px;
}

.automation .bannerMainTwo .heading {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
.automation .bannerMainTwo .text {
  color: #000;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 100%;
  margin: 0;
}

.automation .bannerMainTwo .buttonTwo {
  width: 102px;
  height: 35px;
  border-radius: 8px;
  background: #000;
  padding: 9px 21px 9px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.automation .bannerMainTwo .buttonTwo .buttonTwoText {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .automation .add-Flow{
    width: 35% !important;
  }
  .automation {
    padding: 20px 10px 20px 10px;
  }
  
  .automation .firstDiv {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 95%;
  }
  .automation .thirdDiv h4 {
    font-size: 20px !important;
    font-family: "Poppins";
  }
  .automation .secondDiv .text {
    font-size: 18px;
  }
  .automation .firstDiv .text {
    font-size: 16px;
  }
  .automation .bannerMain .heading {
    font-size: 18px;
    margin-top: 50px;
    text-align: center;
  }
  .automation .bannerMain .text {
    font-size: 14px;
    width: 100%;
    margin-top: 10px;
    padding: 10px;
  }

  .automation .bannerMain .button .buttonText {
    font-size: 10px;
  }

  .automation .bannerMainTwo {
    flex-direction: column;
    margin-top: 15px;
    padding: 5px 10px 10px 10px;
    gap: 10px;
  }
  .automation .bannerMainTwo .heading {
    font-size: 14px;
  }
  .automation .secondDiv .secondInner .EmmahopDiv .text {
    font-size: 12px;
  }

  .automation .bannerMainTwo .buttonTwo .buttonTwoText {
    font-size: 10px;
  }
}

.automation .list {
  /* align-items: center; */
  /* padding-left: 20px; */
  /* padding-top: 10px; */
  /* gap: 20px; */
  /* color: black; */
  width: 100%;
  min-height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fff;
  border: 1px solid darkgray;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  padding: 0 40px;
  /* padding-bottom: 10px; */
  align-items: center;
  display: flex;
  margin-right: 10px;
}

.automation .list:hover {
  background-color: lightgray;
  cursor: pointer;
}

.automation .add-Flow {
  /* align-items: center; */
  /* padding-left: 20px; */
  padding-top: 10px;
  /* color: black; */
  width: 20%;
  min-height: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fff;
  border: 1px solid darkgray;
  border-radius: 8px;
  display: flex;
  background-color: green;
  align-items: center;
  justify-content: center;

  padding-bottom: 10px;
  color: white;
}

.automation .add-Flow:hover {
  border: 2px solid darkred;
  cursor: pointer;
}
.automation .thirdDiv {
  display: flex;
  justify-content: space-between;
}

.automation .list-container {
  display: flex;
  flex-direction: row;
  min-height: 50px;
  align-items: center;
}

.automation button {
  height: 50px;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  background-color: darkred;
  color: white;
}
