.msg {
  margin-top: 50px;
}
.msg .msgtop {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-bottom: 15px;
}
.msg .msgtop h2 {
  color: #000;
  font-family: "poppins";
  font-size: 16px;
}
.msg .msgtop img {
  width: 25px;
}
.msg .msg1 {
  display: flex;
  /* height: 190px; */
  background: #fff;
  border: 0.01px solid #b5b5b5;
  border-radius: 6.502px 6.502px 1px 1px;
  padding: 32px;
  flex-direction: column;
}
.msg .msg1 .changes {
  padding: 20px;
}
.msg .msg1 .msg1clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg1 .msg1clm2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg1 .msg1clm2 .inpt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.msg .msg1 .msg1clm2 .inpt input {
  height: 60px;
  width: 430px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #b5b5b5;
  font-size: "poppins";
  color: #000;
  padding-left: 20px;
}

.msg .msg1 .msg1clm2 .seebtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.msg .msg1 .msg1clm2 .seebtn .seeright button {
  background: #0084ff;
  width: 59px;
  height: 32px;
  color: #fff;
  border-radius: 3px;
  border: none;
  font-family: "poppins";
  font-size: 14px;
}
.msg .msg1 .msg1clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg1 .msg1clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg1 .msg1clm3 p {
  width: 100%;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  font-family: "poppins";
  line-height: normal;
}
.msg .msg2 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.001px solid #b5b5b5;
  padding: 32px;
}
.msg .msg2 .msg2clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg2 .msg2clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}
.msg .msg2 .msg2clm3 a {
  color: blue;
}
.msg .msg2 .msg2clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg2 .msg2clm2 .messengbtn {
  display: flex;
}
.msg .msg2 .msg2clm2 .messengbtn button {
  background: #0084ff;
  width: 100%;
  height: 36px;
  font-size: 15px;
  color: #fff;
  font-family: "poppins";
  border: none;
  border-radius: 4px;
}
.msg .msg2 .msg2clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg2 .msg2clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg3 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg3 .msg3clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg3 .msg3clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg3 .msg3clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg3 .msg3clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg3 .msg3clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg3 .msg3clm2 > div {
  display: flex;
  column-gap: 10px;
}
.msg .msg3 .msg3clm2 > div a {
  text-decoration: underline;
  text-decoration-color: #dbeeff;
  text-decoration-thickness: 3px;
}
.msg .msg4 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg4 .msg4clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg4 .msg4clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg4 .msg4clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg4 .msg4clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg4 .msg4clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg4 .msg4clm2 > div {
  display: flex;
  column-gap: 10px;
}
.msg .msg4 .msg4clm2 > div a {
  text-decoration: underline;
  text-decoration-color: #dbeeff;
  text-decoration-thickness: 3px;
}
.msg .msg5 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg5 .msg5clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg5 .msg5clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg5 .msg5clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg5 .msg5clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg5 .msg5clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg5 .msg5clm2 .msg5editbtn button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 0.1px solid lightgray;
  border-radius: 5px;
  color: #0084ff;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg5 .msg5clm2 .msg5editbtn button:hover {
  background: #f0f0f0;
  border: 1px solid gray;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.msg .msg5 .msg5clm2 > div a {
  text-decoration: underline;
  text-decoration-color: lightgreen;
  text-decoration-thickness: 2px;
}
.msg .msg6 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg6 .msg6clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg6 .msg6clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg6 .msg6clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg6 .msg6clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg6 .msg6clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg6 .msg6clm2 .msg6editbtn button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 0.1px solid lightgray;
  border-radius: 5px;
  color: #0084ff;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg6 .msg6clm2 .msg6editbtn button:hover {
  background: #f0f0f0;
  border: 1px solid gray;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.msg .msg6 .msg6clm2 > div a {
  text-decoration: underline;
  text-decoration-color: lightgreen;
  text-decoration-thickness: 2px;
}
.msg .msg7 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg7 .msg7clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg7 .msg7clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg7 .msg7clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg7 .msg7clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg7 .msg7clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg7 .msg7clm2 .msg7editbtn button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 0.1px solid lightgray;
  border-radius: 5px;
  color: #0084ff;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg7 .msg7clm2 .msg7editbtn button:hover {
  background: #f0f0f0;
  border: 1px solid gray;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.msg .msg7 .msg7clm2 > div a {
  text-decoration: underline;
  text-decoration-color: lightgreen;
  text-decoration-thickness: 2px;
}
.msg .msg8 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg8 .msg8clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg8 .msg8clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg8 .msg8clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg8 .msg8clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg8 .msg8clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg8 .msg8clm2 .msg8editbtn button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 0.1px solid lightgray;
  border-radius: 5px;
  color: #0084ff;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg8 .msg8clm2 .msg8editbtn button:hover {
  background: #f0f0f0;
  border: 1px solid gray;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.msg .msg8 .msg8clm2 > div a {
  text-decoration: underline;
  text-decoration-color: lightgreen;
  text-decoration-thickness: 2px;
}

.msg .msg9 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.001px solid #b5b5b5;
  padding: 32px;
}
.msg .msg9 .msg9clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg9 .msg9clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}
.msg .msg9 .msg9clm3 a {
  color: blue;
}
.msg .msg9 .msg9clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg9 .msg9clm2 .messengbtn {
  display: flex;
}
.msg .msg9 .msg9clm2 .showbox {
  display: flex;
  column-gap: 9px;
}
.msg .msg9 .msg9clm2 .showbox a {
  color: black;
  font-size: 13px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg9 .msg9clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg9 .msg9clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg10 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.001px solid #b5b5b5;
  padding: 32px;
}
.msg .msg10 .msg10clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg10 .msg10clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}
.msg .msg10 .msg10clm3 a {
  color: blue;
}
.msg .msg10 .msg10clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg10 .msg10clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg10 .msg10clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg11 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.001px solid #b5b5b5;
  padding: 32px;
}
.msg .msg11 .msg11clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg11 .msg11clm2 p {
  color: black;
  font-size: 14px;
  font-family: "poppins";
  font-weight: 400;
}
.msg .msg11 .msg11clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}
.msg .msg11 .msg11clm3 a {
  color: blue;
}
.msg .msg11 .msg11clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg11 .msg11clm1 span {
  background-color: #0084ff;
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  font-family: "poppins";
  font-weight: 500;
  padding: 1px 5px;
}
.msg .msg11 .msg11clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg11 .msg11clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg12 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.001px solid #b5b5b5;
  padding: 32px;
}
.msg .msg12 .msg12clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg12 .msg12clm2 p {
  color: black;
  font-size: 15px;
  font-family: "poppins";
  font-weight: 600;
}
.msg .msg12 .msg12clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}
.msg .msg12 .msg12clm3 a {
  color: blue;
}
.msg .msg12 .msg12clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}
.msg .msg12 .msg12clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg12 .msg12clm3 {
  width: 40%;
  padding: 0 16px;
}

.msg .msg13 {
  display: flex;
  background: #fff;
  border-radius: 2.502px;
  border: 0.01px solid #b5b5b5;
  padding: 32px;
}
.msg .msg13 .msg13clm1 {
  width: 20%;
  display: flex;
  text-align: left;
  padding: 0 16px;
}
.msg .msg13 .msg13clm2 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
}

.msg .msg13 .msg13clm1 h2 {
  font-size: 16px;
  font-family: "poppins";
  color: #000;
  font-weight: 500;
}

.msg .msg13 .msg13clm3 p {
  font-size: 14px;
  font-family: "poppins";
  width: 100%;
  color: #000;
  font-weight: 400;
  line-height: normal;
}
.msg .msg13 .msg13clm3 {
  width: 40%;
  padding: 0 16px;
}
.msg .msg13 .msg13clm2 .msg13editbtn button {
  width: 100%;
  height: 50px;
  background-color: transparent;
  border: 0.1px solid lightgray;
  border-radius: 5px;
  color: red;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 500;
}
.msg .msg13 .msg13clm2 .msg13editbtn button:hover {
  background: #f0f0f0;
  border: 1px solid gray;
  transition: background-color 0.5s ease, color 0.5s ease;
}
.msg .msg13 .msg13clm2 > div a {
  text-decoration: underline;
  text-decoration-color: lightgreen;
  text-decoration-thickness: 2px;
}

@media screen and (max-width: 1200px) {
  .msg .msg1 {
    height: 150px;
    padding: 20px;
  }
  .msg .msg1 .msg1clm1 h2 {
    font-size: 14px;
  }
  .msg .msg1 .msg1clm3 p {
    font-size: 11px;
  }
  .msg .msg1 .msg1clm2 .inpt input {
    height: 35px;
    width: 100%;
    font-size: 11px;
  }
  .msg .msg2 {
    padding: 20px;
  }
  .msg .msg1 .msg1clm2 .seebtn .seeright button {
    width: 51px;
    height: 26px;
    font-size: 11px;
  }
  .msg .msg2 .msg2clm1 h2 {
    font-size: 13px;
  }
  .msg .msg2 .msg2clm2 .messengbtn button {
    width: 100%;
    height: 30px;
    font-size: 12px;
  }
  .msg .msg2 .msg2clm3 p {
    font-size: 11px;
  }
  .msg .msg3 {
    padding: 20px;
  }
  .msg .msg3 .msg3clm1 h2 {
    font-size: 13px;
  }
  .msg .msg3 .msg3clm2 > div a {
    font-size: 11px;
  }
  .msg .msg3 .msg3clm3 p {
    font-size: 11px;
  }
  .msg .msg4 {
    padding: 20px;
  }
  .msg .msg4 .msg4clm1 h2 {
    font-size: 13px;
  }
  .msg .msg4 .msg4clm2 > div a {
    font-size: 11px;
  }
  .msg .msg4 .msg4clm3 p {
    font-size: 11px;
  }
  .msg .msg5 {
    padding: 20px;
  }
  .msg .msg5 .msg5clm1 h2 {
    font-size: 13px;
  }
  .msg .msg5 .msg5clm2 .msg5editbtn button {
    height: 40px;
  }
  .msg .msg5 .msg5clm3 p {
    font-size: 11px;
  }
  .msg .msg5 .msg5clm2 .msg5editbtn button {
    font-size: 11px;
  }
  .msg .msg6 {
    padding: 20px;
  }
  .msg .msg6 .msg6clm1 h2 {
    font-size: 13px;
  }
  .msg .msg6 .msg6clm2 .msg6editbtn button {
    height: 40px;
  }
  .msg .msg6 .msg6clm3 p {
    font-size: 11px;
  }
  .msg .msg6 .msg6clm2 .msg6editbtn button {
    font-size: 11px;
  }
  .msg .msg7 {
    padding: 20px;
  }
  .msg .msg7 .msg7clm1 h2 {
    font-size: 13px;
  }
  .msg .msg7 .msg7clm2 .msg7editbtn button {
    height: 40px;
  }
  .msg .msg7 .msg7clm3 p {
    font-size: 11px;
  }
  .msg .msg7 .msg7clm2 .msg7editbtn button {
    font-size: 11px;
  }
  .msg .msg8 {
    padding: 20px;
  }
  .msg .msg8 .msg8clm1 h2 {
    font-size: 13px;
  }
  .msg .msg8 .msg8clm2 .msg8editbtn button {
    height: 40px;
  }
  .msg .msg8 .msg8clm3 p {
    font-size: 11px;
  }
  .msg .msg8 .msg8clm2 .msg8editbtn button {
    font-size: 11px;
  }
  .msg .msg9 .msg9clm1 h2 {
    font-size: 13px;
  }
  .msg .msg9 .msg9clm3 p {
    font-size: 11px;
  }
  .msg .msg9 {
    padding: 20px;
  }
  .msg .msg10 {
    padding: 20px;
  }
  .msg .msg10 .msg10clm1 h2 {
    font-size: 13px;
  }
  .msg .msg10 .msg10clm3 p {
    font-size: 11px;
  }
  .msg .msg11 .msg11clm1 h2 {
    font-size: 13px;
  }
  .msg .msg11 .msg11clm1 span {
    font-size: 8px;
    padding: 1px 3px;
  }
  .msg .msg11 .msg11clm2 p {
    font-size: 12px;
  }
  .msg .msg11 {
    padding: 20px;
  }
  .msg .msg11 .msg11clm3 p {
    font-size: 11px;
  }
  .msg .msg12 {
    padding: 20px;
  }
  .msg .msg12 .msg12clm1 h2 {
    font-size: 13px;
  }
  .msg .msg12 .msg12clm2 p {
    font-size: 13px;
  }
  .msg .msg12 .msg12clm3 p {
    font-size: 11px;
  }
  .msg .msg13 {
    padding: 20px;
  }
  .msg .msg13 .msg13clm1 h2 {
    font-size: 13px;
  }
  .msg .msg13 .msg13clm2 .msg13editbtn button {
    height: 35px;
    font-size: 12px;
  }
  .msg .msg13 .msg13clm3 p {
    font-size: 11px;
  }
}

@media screen and (max-width: 600px) {
  .msg .msg1 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg1 .msg1clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg1 .msg1clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg1 .msg1clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg1 {
    flex-direction: column;
  }
  .msg .msg1 .msg1clm3 p {
    font-size: 9px;
  }
  .msg .msg1 .msg1clm2 .inpt input {
    height: 30px;
    font-size: 9px;
  }
  .msg .msg1 .msg1clm2 .seebtn {
    padding-top: 10px;
  }
  .msg .msg1 .msg1clm1 h2 {
    font-size: 12px;
  }
  .msg .msg2 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg2 .msg2clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg2 .msg2clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg2 .msg2clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg2 {
    flex-direction: column;
  }
  .msg .msg2 .msg2clm3 p {
    font-size: 9px;
  }
  .msg .msg2 .msg2clm1 h2 {
    font-size: 12px;
  }
  .msg .msg2 .msg2clm2 .messengbtn button {
    width: 100%;
    height: 25px;
    font-size: 10px;
  }

  .msg .msg3 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg3 .msg3clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg3 .msg3clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg3 .msg3clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg3 {
    flex-direction: column;
  }
  .msg .msg3 .msg3clm3 p {
    font-size: 9px;
  }
  .msg .msg3 .msg3clm1 h2 {
    font-size: 12px;
  }
  .msg .msg3 .msg3clm2 > div a {
    font-size: 14px;
  }
  .msg .msg4 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg4 .msg4clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg4 .msg4clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg4 .msg4clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg4 {
    flex-direction: column;
  }
  .msg .msg4 .msg4clm3 p {
    font-size: 9px;
  }
  .msg .msg4 .msg4clm1 h2 {
    font-size: 12px;
  }
  .msg .msg4 .msg4clm2 > div a {
    font-size: 14px;
  }

  .msg .msg5 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg5 .msg5clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg5 .msg5clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg5 .msg5clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg5 {
    flex-direction: column;
  }
  .msg .msg5 .msg5clm3 p {
    font-size: 9px;
  }
  .msg .msg5 .msg5clm1 h2 {
    font-size: 12px;
  }
  .msg .msg5 .msg5clm2 .msg5editbtn button {
    height: 25px;
  }
  .msg .msg5 .msg5clm2 .msg5editbtn button {
    font-size: 10px;
  }

  .msg .msg6 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg6 .msg6clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg6 .msg6clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg6 .msg6clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg6 {
    flex-direction: column;
  }
  .msg .msg6 .msg6clm3 p {
    font-size: 9px;
  }
  .msg .msg6 .msg6clm1 h2 {
    font-size: 12px;
  }
  .msg .msg6 .msg6clm2 .msg6editbtn button {
    height: 25px;
  }
  .msg .msg6 .msg6clm2 .msg6editbtn button {
    font-size: 10px;
  }

  .msg .msg7 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg7 .msg7clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg7 .msg7clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg7 .msg7clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg7 {
    flex-direction: column;
  }
  .msg .msg7 .msg7clm3 p {
    font-size: 9px;
  }
  .msg .msg7 .msg7clm1 h2 {
    font-size: 12px;
  }
  .msg .msg7 .msg7clm2 .msg7editbtn button {
    height: 25px;
  }
  .msg .msg7 .msg7clm2 .msg7editbtn button {
    font-size: 10px;
  }

  .msg .msg8 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg8 .msg8clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg8 .msg8clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg8 .msg8clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg8 {
    flex-direction: column;
  }
  .msg .msg8 .msg8clm3 p {
    font-size: 9px;
  }
  .msg .msg8 .msg8clm1 h2 {
    font-size: 12px;
  }
  .msg .msg8 .msg8clm2 .msg8editbtn button {
    height: 25px;
  }
  .msg .msg8 .msg8clm2 .msg8editbtn button {
    font-size: 10px;
  }

  .msg .msg9 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg9 .msg9clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg9 .msg9clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg9 .msg9clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg9 {
    flex-direction: column;
  }
  .msg .msg9 .msg9clm3 p {
    font-size: 9px;
  }
  .msg .msg9 .msg9clm1 h2 {
    font-size: 12px;
  }
  .msg .msg9 .msg9clm2 .msg9editbtn button {
    height: 25px;
  }
  .msg .msg9 .msg9clm2 .msg9editbtn button {
    font-size: 10px;
  }

  .msg .msg10 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg10 .msg10clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg10 .msg10clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg10 .msg10clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg10 {
    flex-direction: column;
  }
  .msg .msg10 .msg10clm3 p {
    font-size: 9px;
  }
  .msg .msg10 .msg10clm1 h2 {
    font-size: 12px;
  }
  .msg .msg10 .msg10clm2 .msg10editbtn button {
    height: 25px;
  }

  .msg .msg11 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg11 .msg11clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg11 .msg11clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg11 .msg11clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg11 {
    flex-direction: column;
  }
  .msg .msg11 .msg11clm3 p {
    font-size: 9px;
  }
  .msg .msg11 .msg11clm1 h2 {
    font-size: 12px;
  }
  .msg .msg11 .msg11clm2 p {
    font-size: 10px;
  }

  .msg .msg12 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg12 .msg12clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg12 .msg12clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg12 .msg12clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg12 {
    flex-direction: column;
  }
  .msg .msg12 .msg12clm3 p {
    font-size: 9px;
  }
  .msg .msg12 .msg12clm1 h2 {
    font-size: 12px;
  }
  .msg .msg12 .msg12clm2 p {
    font-size: 10px;
  }

  .msg .msg13 {
    height: 100%;
    padding: 10px 15px;
    row-gap: 20px;
  }
  .msg .msg13 .msg13clm3 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg13 .msg13clm2 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg13 .msg13clm1 {
    width: 100%;
    padding: 0 5px;
  }
  .msg .msg13 {
    flex-direction: column;
  }
  .msg .msg13 .msg13clm3 p {
    font-size: 9px;
  }
  .msg .msg13 .msg13clm1 h2 {
    font-size: 12px;
  }
  .msg .msg13 .msg13clm2 .msg13editbtn button {
    height: 25px;
  }
  .msg .msg13 .msg13clm2 .msg13editbtn button {
    font-size: 9px;
  }
}
