.TermModal{
     height: 95vh;
     text-align: justify;
     width: 100vw;  
    display: flex;
    align-items: center;
  }
.TermModal .modal-dialog {
  width: 80vw;
  margin: 0;
 
}
.TermModal .modal-content {
  width: 50vw;
  margin-left: 26vw;
  margin-top: 5vh;
}
.TermModal .modal-header {
    width: 100%; /* Adjust the width value as needed */
  }
  .TermModal .modal-body {
    height: 100%; /* Adjust the height value as needed */
    overflow-y: auto;
  }
  
  .TermModal .modal-body::-webkit-scrollbar {
    width: 0.3em;
     /* Set the width of the scrollbar */
    /* Set the background color of the scrollbar */
  }
  .TermModal .modal-body::-webkit-scrollbar-thumb {
    background-color: #4ECCAA;
    border-radius: 5px;
    
    /* Set the color of the scrollbar thumb */
  }

.TermModal p{
    color: black;
    padding: 0 30px;
    text-justify: distribute;
}
.TermModal h5{
    text-align: left;
    padding: 0 30px;
    color: rgb(49, 49, 130) !important;
    font-weight: 600;
}
.TermModal h6{
  color: rgb(49, 49, 130) !important;
  padding: 0 30px;
}
.ListAlpha li{
    list-style: decimal !important;
    padding: 0 30px;
}

.TermModal ul{
  margin: 0 30px;
  margin-bottom: 10px;
}
.TermModal ul li{
    list-style: circle;
    padding: 0 30px !important;
    font-size: 16px;
} 
.TermModal ol {
  margin: 0 30px;
  margin-bottom: 10px;
}
.TermModal ol li {
  padding: 0 30px !important;
  list-style: lower-roman;
  font-size: 16px;
}
@media (max-width: 575px) {.TermModal ol{
  padding-left: 10px !important;
}
.TermModal ol li{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.TermModal ul li{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.TermModal ul{
  padding-left: 0px !important;
}
.TermModal p,h5,h6{
  padding:  0 4px !important;
}
  .TermModal h5 {
    font-size: 16px !important;
  }
  .TermModal .modal-content {
    width: 90vw;
    margin-left: 5vw;
    margin-top: 5vh;
  }
  .TermModal ol{
    padding-left: 15px !important;
  }
  .TermModal ol li{
    padding-left: 0px !important;
    padding-right: 8px !important;
  }
  .TermModal ul li{
    padding-left: 0px !important;
    padding-right: 5px !important;
  }
  .TermModal ul{
    padding-left: 20px !important;
  }
  .TermModal p,h5,h6{
    padding:  0 20px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .TermModal .modal-content {
    width: 80vw;
    margin-left: 10vw;
    margin-top: 5vh;
  }
  .TermModal ol{
    padding-left: 5px !important;
  }
  .TermModal ol li{
    padding-left: 0px !important;
    padding-right: 2px !important;
  }
  .TermModal ul li{
    padding-left: 0px !important;
    padding-right: 2px !important;
  }
  .TermModal ul{
    padding-left: 5px !important;
  }
  .TermModal p,h5,h6{
    padding:  0 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .TermModal .modal-content {
    width: 70vw;
    margin-left: 15vw;
    margin-top: 5vh;
  }
  .TermModal ol{
    padding-left: 10px !important;
  }
  .TermModal ol li{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .TermModal ul li{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .TermModal ul{
    padding-left: 0px !important;
  }
  .TermModal p,h5,h6{
    padding:  0 4px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .TermModal .modal-content {
    width: 65vw;
    margin-left: 17.5vw;
    margin-top: 5vh;
  }
  .TermModal ol{
    padding-left: 10px !important;
  }
  .TermModal ol li{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .TermModal ul li{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .TermModal ul{
    padding-left: 0px !important;
  }
  .TermModal p,h5,h6{
    padding:  0 6px !important;
  }
}
@media (min-width: 1200px) {
  .TermModal .modal-content {
    width: 60vw;
    margin-left: 20vw;
    margin-top: 5vh;
  }
}