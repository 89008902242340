:root {
  --black: #000000;
  --white: #ffffff;
  --purple: #8a6eff;
  --dark-purple: #420061;
  --light-purple: #493f72;
  --dull-white: #f5f5f7;
  --pink: #e0b9fe;
}

.bg-dull-light {
  background-color: var(--dull-white);
}
