.paycont {
    width: 95%;
    margin: auto;
    margin-top: 80px;
    height: 637px;
    border-radius: 6.303px;
    border: 0.788px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 12.60606px 3.15152px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 25px;
    padding: 0px 29px 10px 29px;
}
.payheader{
    border-bottom: 1px solid;
    color: #A4A4A4;
    padding: 0px;
}
.payheader h2{
    color: #000;
    font-family: "Poppins";
    font-size: 18.961px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 0px 0px 3px ;
}
.paycont .account {
    display: flex;
    justify-content: space-between;
}
.paycont .account h2{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.paycont .account button{
    color: #FFF;
    font-family: "Poppins";
    font-size: 12.606px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 174px;
    height: 32px;
    border-radius: 6.303px;
    background: #000;
    border: none;
}
.paycont .account p{
    max-width: 434px;
    color: #000;
    font-family: "Poppins";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.paycont .paypal {
    display: flex;
    justify-content: space-between;
}
.paycont .paypal h2{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.paycont .paypal .conect{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}
.paycont .paypal .conect .clientid input{
    width: 244px;
    height: 42px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    color: #BDBDBD;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
}
.paycont .paypal .conect .clientid input::placeholder{
    padding-left: 15px;
}
.paycont .paypal .conect .clientid2 input{
    width: 244px;
    height: 42px;
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    color: #BDBDBD;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
}
.paycont .paypal .conect .clientid2 input::placeholder{
    padding-left: 15px;
}
.paycont .paypal .conect button{
    width: 173px;
    height: 32px;
    border-radius: 6.303px;
    background: #000;
    color: #FFF;
    font-family: "Poppins";
    font-size: 12.606px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
}
.paycont .paypal  p{
    max-width: 434px;
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.paycont .currency {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.paycont .currency h2{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.paycont .currency input{
    color: #BDBDBD;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 244px;
    height: 42px;
    flex-shrink: 0; 
    border-radius: 8px;
    border: 1px solid #E0E0E0;
    background: #FFF;
    outline: none;
}
.paycont .currency input::placeholder{
    padding-left: 15px;
    
}
.paycont .currency .selcurrency{
    padding-left: 48px;

}


.paycont .currency .para p{
    max-width: 434px;
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 298px;
}
.paycont .neworder{
    display: flex;
    justify-content: space-between;
}
.paycont .neworder h2{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}
.paycont .neworder .check {
    display: flex;
    flex-direction: column;
}
.paycont .neworder .check span {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 8px;
}
.paycont .neworder p{
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 122px;
}
.paycont .send {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
}
.paycont .send .check2{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;

}
.paycont .send .check2 span{
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 5px;
}
.paycont .send h2{
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}
.paycont .send p{
    width: 434px;
    color: #000;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width:600px){
    .payheader h2 {
        font-size: 14.961px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px 0px 4px 0px;
    }
}

@media screen and (max-width:600px) {
    .paycont{
        margin: auto !important;
        padding: 0 15px;
        height: 100%;
    }
    .paycont .account h2{
        text-align: start;
    }
        .paycont .account{
            row-gap: 20px;
            flex-direction: column;
        }
        .paycont .account p{
            width: 100% !important;
            margin-bottom: 0;
            }
            .paycont .paypal{
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 20px;
        }
        .paycont .paypal p {
            width: 100% !important;
            margin-bottom: 0;
        }
        .paycont .currency{
            flex-direction: column;
            row-gap: 20px;
        }
        .paycont .currency .selcurrency {
            padding-left: 0px;
        }
        .paycont .neworder {
            flex-direction: column;
            row-gap: 20px;
        }
        .paycont .neworder h2{
            width: 100% !important;
        }
        .paycont .send{
            flex-direction: column;
            row-gap: 15px;
        }
        .paycont .send p {
            width: 100% !important;
        }
        .paycont .send h2{
            width: 100% !important;
        }
    }



@media screen and (max-width:1600px)  {
    .paycont .account h2 {
        font-size: 14px;
    }
    .paycont .account button {
        font-size: 10.606px;
        width: 155px;
        height: 30px;
    }
    .paycont .account p {
        max-width: 400px;
        font-size: 11px;
    }
    .paycont .paypal h2 {
        font-size: 14px;
    }
    .paycont .paypal .conect .clientid input {
        width: 200px;
        height: 35px;
        font-size: 11px;
    }
    .paycont .paypal p {
        max-width: 400px;
        font-size: 11px;
    }
    .paycont .paypal .conect .clientid2 input {
        width: 200px;
        height: 35px;
        font-size: 11px;
    }
    .paycont .paypal .conect button {
        width: 155px;
        height: 30px;
        font-size: 10.606px;
        
    }
    .paycont .currency h2 {
        font-size: 14px;

    }
    .paycont .currency input {
        font-size: 11px;
        width: 200px;
        height: 35px;
        flex-shrink: 0;
    }
    .paycont .currency .para p {
        width: 400px;
        font-size: 11px;
        padding-right: 0px;
    }
    .paycont .neworder h2 {
        font-size: 14px;
        width: 230px;
    }
    .paycont .neworder p {
        width: 400px;
        font-size: 11px;
    }
    .paycont .neworder .check span {
        font-size: 11px;
            
    }
    .paycont .send h2 {
        font-size: 14px;
        width: 250px;
    }
    .paycont .send p {
        width: 400px;
        font-size: 11px;
    }
    .paycont .send .check2 span {
        font-size: 11px;
    }
}

@media screen and (max-width:1400px) {
    .paycont .account h2 {
        font-size: 12px;
    }
    .paycont .account button {
        font-size: 9.606px;
        width: 135px;
        height: 25px;
    }
    .paycont .account p {
        width: 300px;
        font-size: 10px;
    }
    .paycont .paypal h2 {
        font-size: 12px;
    }
    .paycont .paypal .conect .clientid input {
        width: 170px;
        height: 30px;
        font-size: 10px;
    }
    .paycont .paypal p {
        width: 300px;
        font-size: 10px;
    }
    .paycont .paypal .conect .clientid2 input {
        width: 170px;
        height: 30px;
        font-size: 10px;
    }
    .paycont .paypal .conect button {
        width: 135px;
        height: 25px;
        font-size: 9px;
        
    }
    .paycont .currency h2 {
        font-size: 12px;

    }
    .paycont .currency input {
        font-size: 10px;
        width: 170px;
        height: 30px;
    }
    .paycont .currency .para p {
        width: 300px;
        font-size: 10px;
        padding-right: 0px;
    }
    .paycont .neworder h2 {
        font-size: 12px;
        width: 180px;
    }
    .paycont .neworder p {
        width: 300px;
        font-size: 10px;
        padding-right: 0px;
    }
    .paycont .neworder .check span {
        font-size: 10px;
            
    }
    .paycont .send h2 {
        font-size: 12px;
        width: 180px;
    }
    .paycont .send p {
        width: 300px;
        font-size: 10px;
    }
    .paycont .send .check2 span {
        font-size: 10px;
    }

}

@media screen and (max-width:1200px) {
    .paycont .account h2 {
        font-size: 11px;
    }
    .paycont .account button {
        font-size: 8px;
        width: 120px;
        height: 20px;
    }
    .paycont .account p {
        width: 260px;
        font-size: 9px;
    }
    .paycont .paypal h2 {
        font-size: 11px;
    }
    .paycont .paypal .conect .clientid input {
        width: 140px;
        height: 25px;
        font-size: 9px;
    }
    .paycont .paypal p {
        width: 260px;
        font-size: 9px;
    }
    .paycont .paypal .conect .clientid2 input {
        width: 140px;
        height: 25px;
        font-size: 9px;
    }
    .paycont .paypal .conect button {
        width: 135px;
        height: 25px;
        font-size: 8px;
        
    }
    .paycont .currency h2 {
        font-size: 11px;
    }
    .paycont .currency input {
        font-size: 9px;
        width: 140px;
        height: 25px;
    }
    .paycont .currency .para p {
        width: 260px;
        font-size: 9px;
        padding-right: 0px;
    }
    .paycont .neworder h2 {
        font-size: 11px;
        width: 160px;
    }
    .paycont .neworder p {
        width: 260px;
        font-size: 9px;
        padding-right: 0px;
    }
    .paycont .neworder .check span {
        font-size: 9px;
    }
    .paycont .send h2 {
        font-size: 11px;
        width: 160px;
    }
    .paycont .send p {
        width: 260px;
        font-size: 9px;
    }
    .paycont .send .check2 span {
        font-size: 9px;
    }
    .paycont .paypal .conect button {
        width: 120px;
        height: 20px;
        font-size: 9px;
    }
    
}