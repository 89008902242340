.autoasign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    height: 200px;
    border-radius: 4px;
    background: #F3F2F2;
    row-gap: 20px;
    padding: 20px 26px 22px 33px;
    margin: auto;
    margin-top: 60px;
}
.autoasign .tophead h2{
    color: #000;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}
.autoasign .tophead p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5px;

}
.autoasign .tophead span a {
    color: #656565;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
.autoasign .botmcont {
    display: flex;
    justify-content: space-between;
    padding-right: 50px ;
    padding-left: 25px;
}
.autoasign .botmcont .off {
    width: 294px;
    height: 80px;
    display: flex;
    border-radius: 4px;
    background: #FFF;
    padding: 10px 13px 3px 24px;
    column-gap: 8px;
    
}

.autoasign .botmcont .off .rightcont {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.autoasign .botmcont .off .rightcont h2 {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.autoasign .botmcont .off .rightcont p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.autoasign .botmcont .basic {
    width: 294px;
    height: 80px;
    display: flex;
    width: 294px;
    border-radius: 4px;
    background: #FFF;
    padding: 10px 13px 3px 24px;
    column-gap: 8px;
    
}
.autoasign .botmcont .basic .rightdiv {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.autoasign .botmcont .basic .rightdiv h2 {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.autoasign .botmcont .basic .rightdiv p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.autoasign .botmcont .advance {
    display: flex;
    width: 294px;
    height: 80px;
    display: flex;
    width: 294px;
    border-radius: 4px;
    background: #FFF;
    padding: 10px 13px 3px 24px;
    column-gap: 8px;
    
}
.autoasign .botmcont .advance .rightside {
    padding-top: 3px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.autoasign .botmcont .advance .rightside h2 {
    color: #000;
    font-family: "Poppins";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.autoasign .botmcont .advance .rightside p{
    color: #949494;
    font-family: "Poppins";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width:600px) {
    .autoasign {
        
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: #F3F2F2;
        row-gap: 20px;
        padding: 0 !important;
        margin-top: 30px;
        padding: 20px !important;
    }
    .autoasign .botmcont {
        flex-direction: column;
        padding-right: 0px;
        padding-left: 0px;
        row-gap: 10px;
    }
    .autoasign .botmcont .off {
        width: 100%;
        height: 90px;
        padding: 10px 15px;
    }
    .autoasign .botmcont .basic {
        width: 100%;
        height: 90px;
        padding: 10px 15px;
    }
    .autoasign .botmcont .advance {
        width: 100%;
        height: 90px;
        padding: 10px 15px;
    }
}
@media screen and (max-width:1800px) {
    .autoasign .botmcont {
        display: flex;
        justify-content: space-between;
        padding-right: 0px;
        padding-left: 0px;
    }
    .autoasign .botmcont .off {
        width: 260px;
        padding: 10px;
    }
    .autoasign .botmcont .basic {
        width: 260px;
        padding: 10px;
    }
    .autoasign .botmcont .advance {
        width: 260px;
        padding: 10px;
    }
    
}
@media screen and (max-width:1500px) {
    .autoasign {
        padding: 15px 20px;
    }
    .autoasign .botmcont .advance {
        width: 230px;
        padding: 10px;
    }
    .autoasign .botmcont .basic {
        width: 230px;
        padding: 10px;
    }
    .autoasign .botmcont .off {
        width: 230px;
        padding: 10px;
    }
}
@media screen and (max-width:1300px) {
    .autoasign {
        padding: 15px 20px;
    }
    .autoasign .botmcont .advance .rightside p {
        font-size: 8px;
    }
    .autoasign .botmcont .off .rightcont p {
        font-size: 8px;
    }
    .autoasign .botmcont .basic .rightdiv p {
        font-size: 8px;
    }
    .autoasign .botmcont .advance {
        width: 190px;
        padding: 10px 5px;
    }
    .autoasign .botmcont .basic {
        width: 190px;
        padding: 10px 5px;
    }
    .autoasign .botmcont .off {
        width: 190px;
        padding: 10px 5px;
    }
}
@media screen and (max-width:1100px) {
    .autoasign {
        padding: 15px 20px;
    }
    .autoasign .botmcont .advance .rightside p {
        font-size: 8px;
    }
    .autoasign .botmcont .off .rightcont p {
        font-size: 8px;
    }
    .autoasign .botmcont .basic .rightdiv p {
        font-size: 8px;
    }
    .autoasign .botmcont .advance {
        width: 170px;
        padding: 10px 5px;
    }
    .autoasign .botmcont .basic {
        width: 170px;
        padding: 10px 5px;
    }
    .autoasign .botmcont .off {
        width: 170px;
        padding: 10px 5px;
    }
}