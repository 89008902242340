// Tiktok Banner
.tiktok-banner {
  padding: 100px 228px;
  background: #f5f5f7;

  .two {
    .left {
      width: 70%;

      h4 {
        width: 800px;
      }
    }

    .right {
      width: 30%;

      .banner-img {
        width: 100%;
      }
    }
  }

  .drive {
    background: #fff;

    h3 {
      width: 992px;
    }
  }
}

// Turn
.turn {
  padding: 64px 97px;
  background: #f5f5f7;

  .usechat-title {
    h3 {
      width: 510px;
    }
  }

  .usechat-content {
    .right {
      margin-left: 13px;
      width: 50%;
    }

    .left {
      width: 50%;
    }
  }
}

// Drive
.drive {
  padding: 80px 140px 16px 196px;
  background: #000;

  .bottom {
    .left {
      width: 50%;

      .top {
        width: 100%;
        background: var(--white);
        padding: 15px 20px 6px 36px;

        h4 {
          width: 100%;
          color: #000;
          cursor: pointer;
        }
      }

      .bot {
        padding-left: 36px;

        h4 {
          margin-top: 31px;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 50%;
    }
  }
}

// enter
.tiktok-enterpreneur {
  background: #f5f5f7;
  padding-left: 424px;
  padding-right: 196px;
  padding-top: 108px;
  padding-bottom: 108px;

  .enterpreneus-content {
    display: flex;

    .right {
      width: 100%;
      display: flex;

      .background-div {
        width: 100%;
        height: 657px;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #000;
        display: flex;

        .enterpreneur-img {
          position: absolute;
          left: 189px;
          padding-block: 44px;
          border-radius: 8px;
        }

        .right-content {
          padding-top: 102px;
          padding-bottom: 81px;
          padding-right: 61px;
          padding-left: 333px;

          p {
            color: #000;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .subheading {
            h3 {
              color: #000;
              font-family: "Poppins";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .back-btn {
    margin-top: 91px;
  }
}

@media screen and (max-width: 1700px) {
  .tiktok-banner {
    padding: 100px 150px;
  }

  .turn {
    padding: 64px 80px;
  }
}

@media screen and (max-width: 1500px) {
  .tiktok-banner {
    padding: 100px;

    .two {
      .left {
        width: 60%;

        h1 {
          font-size: 48px;
        }

        h4 {
          width: 100%;
          font-size: 32px;
        }
      }

      .right {
        width: 40%;
      }
    }

    .drive {
      padding: 0;
    }
  }

  .turn {
    padding: 64px 100px;

    .usechat-content {
      .right {
        margin-left: 13px;
      }
    }
  }

  .drive {
    padding: 80px 100px 16px 100px;
  }
}

@media screen and (max-width: 1200px) {
  .tiktok-banner {
    padding: 50px;

    .two {
      .left {
        h1 {
          font-size: 44px;
        }
      }
    }
  }

  .turn {
    padding: 50px;
  }

  .drive {
    padding: 50px;
  }

  .freebotdm .freebotdm-title {
    text-align: center;
    padding: 50px 20px;
  }
}

@media screen and (max-width: 600px) {
  .tiktok-banner {
    padding: 20px;

    .two {
      flex-direction: column;

      .left {
        width: 100%;

        h1 {
          font-size: 25px;
        }

        h4 {
          font-size: 15px;
        }

        button {
          width: 100%;
        }
      }

      .right {
        width: 100%;
      }
    }

    .drive {
      h3 {
        width: 100%;
        font-size: 17px;
      }
    }
  }

  .turn {
    padding: 20px;

    .usechat-title {
      h3 {
        font-size: 20px;
        width: 100%;
      }

      button {
        width: 100%;
      }
    }

    .usechat-content {
      flex-direction: column-reverse;

      .left {
        width: 100%;
        margin-top: 48px;
      }

      .right {
        width: 100%;
        margin-left: 0;
      }
    }

    .second {
      flex-direction: column;
      margin-top: 0 !important;

      .right {
        width: 100%;
        margin-top: 48px;
      }

      .left {
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .drive {
    padding: 20px;

    .head {
      h3 {
        font-size: 22px;
        text-align: center;
      }

      button {
        width: 100%;
        margin-top: 1rem !important;
      }

      p {
        font-size: 20px;
        text-align: center;
      }
    }

    .bottom {
      flex-direction: column;

      .left {
        width: 100%;

        .top {
          p {
            font-size: 15px;
          }
        }

        .bot {
          width: 100% !important;
          padding-left: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          padding: 10px;
          justify-content: center;
          align-items: center;

          h4 {
            font-size: 17px;
            margin-top: 20px;
            color: #000;
          }
        }
      }

      .right {
        width: 100%;
      }
    }
  }
}

// --------------------------------------Enterprenuer---------------------

@media screen and (max-width: 1650px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 424px;
    padding-right: 196px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 189px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 400px;
    padding-right: 172px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 159px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 61px;
            padding-left: 333px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 200px;
    padding-right: 86px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;

      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: 657px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;

          .enterpreneur-img {
            position: absolute;
            left: 40px;
            padding-block: 44px;

            border-radius: 8px;
          }

          .right-content {
            padding-top: 102px;
            padding-bottom: 81px;
            padding-right: 10px;
            padding-left: 400px;
            // width: 906px;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 22px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
    .back-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .tiktok-enterpreneur {
    background: #f5f5f7;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 108px;
    padding-bottom: 108px;

    .enterpreneus-content {
      display: flex;
      .right {
        width: 100%;
        display: flex;

        .background-div {
          width: 100%;
          height: unset;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #000;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .enterpreneur-img {
            position: unset;
            // left: 350px;
            // padding-block: 180px;
            text-align: center;

            border-radius: 8px;
            width: 50%;
          }

          .right-content {
            padding: 30px;
            // width: 906px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
              color: #000;
              font-family: "Poppins";
              font-size: 17px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }

            .subheading {
              h3 {
                color: #000;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
    .back-btn {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 600px) {
  .tiktok-enterpreneur {
    padding: 20px;

    .back-btn {
      margin-top: 50px;
    }

    .enterpreneus-content .right .background-div .right-content {
      padding: 20px;
    }
  }
}
