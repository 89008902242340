@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins.ttf");
}
.top-dm {
  display: flex;
  padding: 32px 170px;
  background: #f2f2f4;
}
.head h2 {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: transparent;
}
.top-dm .left {
  width: 70%;
}
.top-dm .right {
  width: 30%;
  margin-left: 50px;
}
.top-dm .left img {
  width: 100%;
}
.top-dm .left p {
  color: #000;
  font-family: "Poppins";
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-top: 13px;
}
.top-dm .right li {
  list-style: none;
  padding-top: 8px;
}
.top-dm .right li a {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}
.res {
  background-image: url("../../assets/images/background.png");
  width: 100%;
}
.res .top-dm-para p,
li {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.res .top-dm-para {
  padding: 96px 190px 47px 170px;
}
.res .bot-para {
  padding: 0px 170px;
  padding-bottom: 79px;
}
.res .bot-para p {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.res .bot-para h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.res .bot-para p {
  padding-top: 40px;
  padding-bottom: 103px;
}
.res .bot-para .connect {
  width: 100%;
  height: 81px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}
.res .bot-para .connect a {
  color: #000;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: none;
}
@media screen and (max-width: 1800px) {
  .top-dm {
    /* padding: 29px 50px !important; */
  }
  .top-dm .right li a {
    font-size: 17px !important;
  }
  .res .top-dm-para ul li {
    font-size: 24px;
  }
}
@media screen and (max-width: 1500px) {
  .top-dm {
    padding: 29px 80px !important;
  }
  .top-dm .right li a {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1300px) {
  .head h2 {
    font-size: 33px !important;
  }

  .top-dm {
    padding: 27px 70px !important;
  }
  .top-dm .left p {
    font-size: 25px !important;
    padding-top: 10px;
  }
  .top-dm .right li a {
    font-size: 13px !important;
  }
  .top-dm .right ul li {
    padding-top: 2px;
  }
  .res .top-dm-para {
    padding: 60px 70px 40px 70px !important;
  }
  .res .bot-para {
    padding: 0px 70px !important;
    padding-bottom: 50px !important;
  }
  .res .bot-para p {
    font-size: 20px !important;
    padding-top: 17px !important;
    padding-bottom: 40px !important;
  }
  .res .top-dm-para ul li {
    font-size: 20px;
  }
  .top-dm .right li {
    list-style: none;
    padding-top: 0px;
  }
  .res .top-dm-para p,
  li,
  ul {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .head h2 {
    font-size: 18px !important;
  }
  .head{
    padding-top: 20px;
  }
  .top-dm {
    padding: 10px 23px !important;
    flex-direction: column;
  }
  .top-dm .left {
    width: 100% !important;
  }
  .top-dm .right {
    width: 100% !important;
    margin-left: 0;
  }
  .top-dm .left p {
    font-size: 17px !important;
    padding-top: 10px;
    text-align: center;
    font-weight: 500;
  }
  .res .top-dm-para p,
  li,
  ul {
    font-size: 13px !important;
  }
  .top-dm .right ul {
    list-style: none;
    padding-top: 8px;
    padding-left: 0px;
  }
  .top-dm .right li a {
    font-size: 13px !important;
  }
  .top-dm .right li {
    padding-bottom: 5px;
  }
  .res .top-dm-para {
    padding: 23px !important;
  }
  .res .bot-para {
    padding: 3px 23px !important;
    padding-bottom: 30px !important;
  }
  .res .bot-para h2 {
    font-size: 20px;
    font-weight: 500;
  }
  .res .bot-para p {
    font-size: 13px !important;
    padding-top: 12px !important;
    padding-bottom: 10px !important;
  }
  .res .bot-para .connect {
    height: 40px;
    text-align: center;
    padding: 10px;
  }
  .res .bot-para .connect a {
    font-size: 13px;
  }
}
