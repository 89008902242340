/* .mainFlow {
  background-color: ;
} */
.mainFlow .edittop {
  display: flex;
  justify-content: space-between;
  /* background: rgb(28, 4, 28); */
  background: #3b3c36;
  border-bottom: 1px solid #732473;
  padding: 10px 20px;
  align-items: center;
}
.mainFlow .edittop h3 {
  color: #fff;
  font-size: 30px;
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 600;
}
.mainFlow .edittop .topbtns .setlivebtn button {
  background: #0084ff;
  color: #fff;
  font-size: 16px;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  font-family: "Poppins";
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.mainFlow .edittop .topbtns .setlivebtn button:hover {
  background-color: #e1e5ea;
  color: #732473;
  transform: translateX(-5px);
}
.mainFlow .edittop .topbtns .addtrigbtn button {
  background: #0084ff;
  color: #fff;
  font-size: 16px;
  width: 160px;
  height: 40px;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  font-family: "Poppins";
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}
.mainFlow .edittop .topbtns .addtrigbtn button:hover {
  background-color: #e1e5ea;
  color: #732473;
  transform: translateX(-5px);
}
.mainFlow .edittop .topbtns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 35px;
}
.flowMain {
  height: 100vh;
  border: 2px solid blue;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.mainFlow {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100vh;
  background-color: #f2f5f7;
}
.mainFlow .two {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  /* margin-top: 50px; */
  width: 100%;
}
.flow {
  width: 400px;
  height: 250px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  /* &:hover {
    outline: 3px solid #47d78f;
  } */
}
.mainFlow .speedDial {
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 0px 0px 10px 0px;
}
.mainFlow .speedDial button {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: none;
  background: #0084ff;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
  cursor: pointer;
}
.flow .one {
  width: 90%;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 20px;
}

.flow .one .text {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.flow .two {
  width: 90%;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.flow .two .text {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.flow .three {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid #33c1ff;
  border-style: dashed;
  height: 50px;
  border-radius: 8px;
}
.flow .three .text {
  color: #33c1ff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

/* Modal Styling */
.mainFlow .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  /* animation: slide-up 0.3s ease; */
}

.mainFlow .modal .modal-content {
  width: 55%;
  height: 70%;
  padding: 50px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
}

.mainFlow .modal .modal-content .header {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  padding-right: 5px;
}
.mainFlow .modal .modal-content .header button {
  border: none;
  background: transparent;
  text-transform: capitalize;
}
.mainFlow .modal .modal-content .header .inputStyles {
  border: 1px solid #0084ff;
  height: 40px;
  width: 25%;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  outline-color: #0084ff;
}
.mainFlow .modal .modal-content .menuModal {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.mainFlow .modal .modal-content .menuModal .left {
  height: 100%;
  width: 20%;
  background: #fff;
}

.mainFlow .modal .modal-content .menuModal .left .divOne {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  gap: 8px;
  cursor: pointer;
}
.mainFlow .modal .modal-content .menuModal .left .divOne .text {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  margin: 0;
}
.mainFlow .modal .modal-content .menuModal .left .divTwo {
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  gap: 8px;
  cursor: pointer;
}
.mainFlow .modal .modal-content .menuModal .left .divTwo .text {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  margin: 0;
}
.mainFlow .modal .modal-content .menuModal .right {
  height: 100%;
  width: 80%;
  padding: 5px 5px 50px 10px;
  overflow: scroll;
  /* border: 2px solid red; */
  box-shadow: 0px 6.29207px 18.87622px 0px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
}

.mainFlow .modal .modal-content .menuModal .right .heading {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 10px;
}
.mainFlow .modal .modal-content .menuModal .right .subHeading {
  color: grey;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-top: 2px;
}

.mainFlow .modal .modal-content .menuModal .right .mapDiv {
  height: 70px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
  border: 1px solid grey;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    border: 2px solid #0084ff;
  }
}

.mainFlow .modal .modal-content .menuModal .right .mapDivInner {
  height: 100%;
}

.mainFlow .modal .modal-content .menuModal .right .mapDivInner .textOne {
  color: grey;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.mainFlow .modal .modal-content .menuModal .right .mapDivInner .textTwo {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

/* offcanvas css  */
.offcanvas {
  background: white;
}
.offcanvas .canvasHeader {
  width: 100%;
  background: #e1faec;
  display: flex;
  justify-content: center;
}
.canvasHeader span {
  color: #232b39;
}
.offcanvasBody {
  width: 100%;
}
.offcanvasBody hr {
  /* color: #e1e5ea; */
  /* height: 3px; */
}
.offcanvasBody .topSection {
  padding: 20px;
  border: 2px dashed #e1e5ea;
}
.topSection {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-block: 30px;
}
.offcanvasBody .topSection span {
  color: #0084ff;
  font-size: 15px;
  font-family: "Poppins";
}
.offcanvasBody .topSection:hover {
  background: #f1f3f5;
  cursor: pointer;
}
.middleSection span {
  font-size: 16px;
  font-weight: 500;
  color: #232b39;
}

.modalBody .topSections {
  margin-block: 10px;
  padding: 20px;
  border: 2px dashed #e1e5ea;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500px;
  font-family: "Poppins";
}
.topSections .iconMessenger {
  margin-right: 10px !important;
}
.topSections button {
  background: #0084ff;
  border: none;

  width: 50px;
  color: white;
  border-radius: 10px;
  margin-left: 20px;
}

.canvbody-right {
  width: 100%;
}
.canvbody-right .upperSection {
  width: 100%;
  display: flex;
  gap: 10px;
}
.upperSection .trigger {
  width: 50%;
  padding: 10px 10px 10px 10px;
  /* height: 50px; */
  /* display: flex;
  justify-content: center;
  align-content: center; */
  border: 2px dashed #e1e5ea;
  cursor: pointer;
  border-radius: 10px;
}
.upperSection .trigger:hover {
  background: #f1f3f5;
}
.upperSection .trigger span {
  color: #232b39;
  font-size: 16px;
  font-weight: 500px;
  font-family: "Poppins";
  margin-left: 10px;
}
.upperTitle {
  margin-block: 20px;
}
.middleSection {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
}
.middleSection .triggers {
  width: 100%;
  padding: 10px 10px 10px 10px;

  border: 2px dashed #e1e5ea;
  cursor: pointer;
  border-radius: 10px;
}
.middleSection .triggers span {
  color: #232b39;
  font-size: 16px;
  font-weight: 500px;
  font-family: "Poppins";
  margin-left: 10px;
}
.middleSection .triggers:hover {
  background: #f1f3f5;
}

@media screen and (max-width: 600px) {
  .mainFlow .edittop h3 {
    font-size: 25px;
  }
  .mainFlow .edittop {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    row-gap: 8px;
  }
  .flow {
    width: 320px;
  }
  .mainFlow .edittop .topbtns {
    column-gap: 15px;
    margin: auto;
  }
  .mainFlow .edittop .topbtns .addtrigbtn button {
    width: 130px;
    font-size: 13px;
  }
  .mainFlow .edittop .topbtns .setlivebtn button {
    width: 130px;
    font-size: 13px;
  }
}
