.navbar {
  padding: 31px 144px;
  background: #f5f5f7;

  .container-fluid {
    .collapse {
      margin-top: 30px;

      form {
        gap: 30px;
      }

      .navbar-nav {
        width: 100%;
        margin-left: 197px;
        gap: 30px;

        .nav-item {
          a {
            color: #000;
            font-size: 20px;
          }

          .dropdown {
            .btn-success {
              background: transparent;
              color: black;
              border: none;
              font-size: 20px;

              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .navbar {
    padding: 31px 70px;

    .container-fluid {
      .collapse {
        margin-top: 30px;


      }
    }

  }
}

@media screen and (max-width: 1670px) {
  .navbar {
    padding: 31px 20px;

    .container-fluid {
      .collapse {
        margin-top: 30px;

        form {
          gap: 10px;
        }

        .navbar-nav {
          width: 100%;
          margin-left: 120px;
          gap: 30px;

          .nav-item {
            .navbar-brand {
              color: var(--black) !important;
              font-size: 20px;


            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1570px) {
  .navbar {
    padding: 31px 20px;

    .container-fluid {
      .collapse {
        form {
          gap: 10px;
        }

        .navbar-nav {
          width: 100%;
          margin-left: 60px;
          gap: 10px;

          .nav-item {
            a {
              color: var(--black) !important;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .navbar {
    padding: 31px 10px;

    .container-fluid {
      flex-wrap: wrap;
      .collapse {
        form {
          gap: 5px;
        }

        .navbar-nav {
          width: 100%;
          margin-left: 0px;
          gap: 5px;

          .nav-item {
            a {
              color: var(--black) !important;
              font-size: 17px;
            }

            .dropdown {
              .btn-success {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .navbar {
    padding: 31px 10px;

    .container-fluid {
      .navbar-brand {
        .sitelogo {
          width: 100%;
        }
      }

      .collapse {
        form {
          gap: 5px;
        }

        .navbar-nav {
          width: 100%;
          margin-left: 0px;
          gap: 5px;

          .nav-item {
            a {
              color: var(--black) !important;
              font-size: 17px;
            }

            .dropdownToggles {
              padding: 0px !important;
              display: flex;
              align-items: center;
              padding-inline: 10px;
            }

            .dropdown {
              .btn-success {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .navbar {
    padding: 14px;

    .nav-item {
      margin-top: 30px;

      a {
        padding-left: 13px;
      }

      .dropdown {
        .btn-success {
          background: transparent;
          border: none;
        }
      }
    }
  }
}