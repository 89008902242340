//pricplan css start
.pricePlan {
  padding-top: 84px;
  padding-bottom: 69px;
  padding-left: 258px;
  padding-right: 258px;
  background: var(--dull-white);

  .priceplan-content {
    .topSection {
      text-align: center;
      flex-direction: column;
      h4{
        font-size: 39px !important;
        font-weight: 700;
      }
      h2 {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      h3 {
        color: #000;
        // text-align: right;
        font-family: "Poppins";
        font-size: 39.538px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      p {
        color: #000;
        text-align: center;
        font-family: "Poppins";
        font-size: 19.769px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .topLast {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;

        .bill-month {
          color: #000;
          font-family: "Inter";
          font-size: 19.769px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        .switchbtn {
          width: 69.191px;
          height: 29.653px;
          // background-color: #000;
          box-shadow: none;

          &:checked {
            background-color: #000000 !important;
            border-color: #000000 !important;
          }
        }

        .bill-annualy {
          color: #000;
          font-family: "Inter";
          font-size: 19.769px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    .priceCards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      // display: flex;
      gap: 39px;
      padding-top: 75px;
      padding-bottom: 69px;
      width: 100%;

      .pricecard {
        // width: 100%;
        // width: 30%;
        // width: 321.246px;
        // height: 515.229px;
        border-radius: 8px;
        background: #fff;
        width: 100%;

        .cardHeader {
          .left {
            display: flex;
            justify-content: space-between;
            padding-top: 30px;
            padding-left: 29px;
            padding-right: 29px;
            width: 100%;

            span {
              color: #493f72;
              font-family: "Inter";
              font-size: 29.653px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            button {
              border-radius: 4.942px;
              padding: 6.178px 9.884px;
              color: #8a6eff;
              font-family: "Inter";
              font-size: 14.827px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              background: #fff;
              border: none;
            }
          }
        }

        .MiddleContent {
          display: flex;
          // justify-content: space-evenly;
          // justify-content: space-between;
          gap: 18px;
          padding-left: 33px;
          padding-right: 63px;
          padding-top: 29px;
          width: 100%;

          .middleLeft {
            span {
              color: #493f72;
              font-family: "Inter";
              font-size: 19.769px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            h6 {
              color: #493f72;
              font-family: "Inter";
              font-size: 21.005px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .number {
              opacity: 0.96;
              color: #493f72;
              font-family: "Inter";
              font-size: 30.889px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .month {
              color: #493f72;
              font-family: "Inter";
              font-size: 19.769px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              opacity: 0.68;
            }
          }

          .middleLefts {
            img {
              opacity: 0.5;
            }

            span {
              color: #493f72;
              font-family: "Inter";
              font-size: 19.769px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              opacity: 0.5;
            }
          }
        }

        .card-footer {
          width: 100%;

          .lastBtn {
            display: flex;
            justify-content: center;
            padding: 29px;

            button {
              width: 261.939px;
              height: 59.307px;
              border-radius: 8px;
              background: #e3e3e3;
              color: #000;
              text-align: center;
              font-family: "Inter";
              font-size: 19.769px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              border: none;
            }
          }
        }
      }

      .pricecard3 {
        background: #493f72;

        margin-top: -30px;
        height: fit-content;

        // margin-bottom: 40px;
      }

      .thirdcardbtn {
        width: 261.939px;
        height: 59.307px;
        flex-shrink: 0;
        border-radius: 8px;
        background: #000 !important;
        color: #fff !important;
        text-align: center;
        font-family: "Inter";
        font-size: 24.711px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .pricePlan {
    padding-top: 84px;
    padding-bottom: 69px;
    padding-left: 150px;
    padding-right: 150px;
    background: var(--dull-white);
  }
}

@media screen and (max-width: 1550px) {
  .pricePlan {
    padding-top: 84px;
    padding-bottom: 69px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--dull-white);
    width: 100%;

    .priceplan-content {
      .topSection {
        text-align: center;

        h2 {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        h3 {
          color: #000;
          // text-align: right;
          font-family: "Poppins";
          font-size: 39.538px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 19.769px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .topLast {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          .bill-month {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .switchbtn {
            width: 69.191px;
            height: 29.653px;
            // background-color: #000;
            box-shadow: none;

            &:checked {
              background-color: #000000 !important;
              border-color: #000000 !important;
            }
          }

          .bill-annualy {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .priceCards {
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding-top: 75px;
        padding-bottom: 69px;
        // width: 100%;

        .pricecard {
          // width: 100%;
          // width: 30%;
          // width: 321.246px;
          // height: 515.229px;
          border-radius: 8px;
          background: #fff;
          width: 25%;

          .cardHeader {
            .left {
              display: flex;
              justify-content: space-between;
              padding-top: 30px;
              padding-left: 29px;
              padding-right: 29px;
              width: 100%;

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 29.653px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              button {
                border-radius: 4.942px;
                padding: 6.178px 9.884px;
                color: #8a6eff;
                font-family: "Inter";
                font-size: 14.827px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: #fff;
                border: none;
              }
            }
          }

          .MiddleContent {
            display: flex;
            // justify-content: space-evenly;
            // justify-content: space-between;
            gap: 18px;
            padding-left: 33px;
            padding-right: 63px;
            padding-top: 29px;
            width: 100%;

            .middleLeft {
              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              h6 {
                color: #493f72;
                font-family: "Inter";
                font-size: 21.005px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .number {
                opacity: 0.96;
                color: #493f72;
                font-family: "Inter";
                font-size: 30.889px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .month {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.68;
              }
            }

            .middleLefts {
              img {
                opacity: 0.5;
              }

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.5;
              }
            }
          }

          .card-footer {
            width: 100%;

            .lastBtn {
              display: flex;
              justify-content: center;
              padding: 29px;

              button {
                width: 261.939px;
                height: 59.307px;
                border-radius: 8px;
                background: #e3e3e3;
                color: #000;
                text-align: center;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
              }
            }
          }
        }

        .pricecard3 {
          background: #493f72;

          margin-top: -30px;
          height: fit-content;

          // margin-bottom: 40px;
        }

        .thirdcardbtn {
          width: 261.939px;
          height: 59.307px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #000 !important;
          color: #fff !important;
          text-align: center;
          font-family: "Inter";
          font-size: 24.711px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .pricePlan {
    padding-top: 84px;
    padding-bottom: 69px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--dull-white);
    width: 100%;

    .priceplan-content {
      .topSection {
        text-align: center;

        h2 {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        h3 {
          color: #000;
          // text-align: right;
          font-family: "Poppins";
          font-size: 39.538px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 19.769px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .topLast {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          .bill-month {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .switchbtn {
            width: 69.191px;
            height: 29.653px;
            // background-color: #000;
            box-shadow: none;

            &:checked {
              background-color: #000000 !important;
              border-color: #000000 !important;
            }
          }

          .bill-annualy {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .priceCards {
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        padding-top: 75px;
        padding-bottom: 69px;

        .pricecard {
          // width: 100%;
          // width: 30%;
          // width: 321.246px;
          // height: 515.229px;
          border-radius: 8px;
          background: #fff;
          width: 100%;

          .cardHeader {
            .left {
              display: flex;
              justify-content: space-between;
              padding-top: 30px;
              padding-left: 10px;
              padding-right: 10px;
              width: 100%;

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 29.653px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              button {
                border-radius: 4.942px;
                padding: 6.178px 9.884px;
                color: #8a6eff;
                font-family: "Inter";
                font-size: 14.827px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: #fff;
                border: none;
              }
            }
          }

          .MiddleContent {
            display: flex;
            // justify-content: space-evenly;
            // justify-content: space-between;
            gap: 18px;
            padding-left: 10px;
            padding-right: 33px;
            padding-top: 29px;
            width: 100%;

            .middleLeft {
              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              h6 {
                color: #493f72;
                font-family: "Inter";
                font-size: 21.005px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .number {
                opacity: 0.96;
                color: #493f72;
                font-family: "Inter";
                font-size: 30.889px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .month {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.68;
              }
            }

            .middleLefts {
              img {
                opacity: 0.5;
              }

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.5;
              }
            }
          }

          .card-footer {
            width: 100%;

            .lastBtn {
              display: flex;
              justify-content: center;
              padding: 29px;

              button {
                width: 100%;
                // width: 261.939px;
                height: 59.307px;
                border-radius: 8px;
                background: #e3e3e3;
                color: #000;
                text-align: center;
                font-family: "Inter";
                font-size: 19.769px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
              }
            }
          }
        }

        .pricecard3 {
          background: #493f72;

          margin-top: -30px;
          height: fit-content;

          // margin-bottom: 40px;
        }

        .thirdcardbtn {
          width: 261.939px;
          height: 59.307px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #000 !important;
          color: #fff !important;
          text-align: center;
          font-family: "Inter";
          font-size: 24.711px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .pricePlan {
    padding-top: 84px;
    padding-bottom: 69px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--dull-white);
    width: 100%;

    .priceplan-content {
      .topSection {
        text-align: center;

        h2 {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        h3 {
          color: #000;
          // text-align: right;
          font-family: "Poppins";
          font-size: 19.538px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .topLast {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          .bill-month {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .switchbtn {
            width: 69.191px;
            height: 29.653px;
            // background-color: #000;
            box-shadow: none;

            &:checked {
              background-color: #000000 !important;
              border-color: #000000 !important;
            }
          }

          .bill-annualy {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .priceCards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // display: flex;
        // justify-content: space-between;
        gap: 50px;
        padding-top: 75px;
        padding-bottom: 69px;
        // width: 100%;

        .pricecard {
          // width: 100%;
          // width: 30%;
          // width: 321.246px;
          // height: 515.229px;
          border-radius: 8px;
          background: #fff;
          width: 100%;

          .cardHeader {
            .left {
              display: flex;
              justify-content: space-between;
              padding-top: 30px;
              padding-left: 10px;
              padding-right: 10px;
              width: 100%;

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              button {
                border-radius: 4.942px;
                padding: 6.178px 9.884px;
                color: #8a6eff;
                font-family: "Inter";
                font-size: 11.827px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: #fff;
                border: none;
              }
            }
          }

          .MiddleContent {
            display: flex;
            // justify-content: space-evenly;
            // justify-content: space-between;
            gap: 18px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 29px;
            width: 100%;

            .middleLeft {
              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 15.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              h6 {
                color: #493f72;
                font-family: "Inter";
                font-size: 17.005px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .number {
                opacity: 0.96;
                color: #493f72;
                font-family: "Inter";
                font-size: 24.889px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .month {
                color: #493f72;
                font-family: "Inter";
                font-size: 16.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.68;
              }
            }

            .middleLefts {
              img {
                opacity: 0.5;
              }

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 16.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.5;
              }
            }
          }

          .card-footer {
            width: 100%;

            .lastBtn {
              display: flex;
              justify-content: center;
              padding: 29px;

              button {
                width: 100%;
                // width: 261.939px;
                height: 59.307px;
                border-radius: 8px;
                background: #e3e3e3;
                color: #000;
                text-align: center;
                font-family: "Inter";
                font-size: 15.769px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
              }
            }
          }
        }

        .pricecard3 {
          background: #493f72;

          margin-top: -30px;
          height: fit-content;

          // margin-bottom: 40px;
        }

        .thirdcardbtn {
          // width: 261.939px;
          height: 59.307px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #000 !important;
          color: #fff !important;
          text-align: center;
          font-family: "Inter";
          font-size: 24.711px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .pricePlan {
    padding-top: 25px;
    padding-bottom:30px;
    padding-left: 20px;
    padding-right: 20px;
    background: var(--dull-white);
    width: 100%;

    .priceplan-content {
      .topSection {
        text-align: center;
        row-gap: 10px;
        h4{
          font-size: 39px !important;
          font-weight: 700 !important;
        }
        h2 {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        h4 {
          color: #000;
          // text-align: right;
          font-family: "Poppins";
          font-size: 15.538px !important;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        p {
          color: #000;
          text-align: center;
          font-family: "Poppins";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .topLast {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          .bill-month {
            color: #000;
            font-family: "Inter";
            font-size: 14.769px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

          .switchbtn {
            width: 54.191px;
            height: 22.653px;
            // background-color: #000;
            box-shadow: none;

            &:checked {
              background-color: #000000 !important;
              border-color: #000000 !important;
            }
          }

          .bill-annualy {
            color: #000;
            font-family: "Inter";
            font-size: 19.769px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .priceCards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        // display: flex;
        // justify-content: space-between;
        gap: 50px;
        padding-top: 75px;
        padding-bottom: 69px;
        // width: 100%;

        .pricecard {
          // width: 100%;
          // width: 30%;
          // width: 321.246px;
          // height: 515.229px;
          border-radius: 8px;
          background: #fff;
          width: 100%;

          .cardHeader {
            .left {
              display: flex;
              justify-content: space-between;
              padding-top: 30px;
              padding-left: 10px;
              padding-right: 10px;
              width: 100%;

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              button {
                border-radius: 4.942px;
                padding: 6.178px 9.884px;
                color: #8a6eff;
                font-family: "Inter";
                font-size: 11.827px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                background: #fff;
                border: none;
              }
            }
          }

          .MiddleContent {
            display: flex;
            // justify-content: space-evenly;
            // justify-content: space-between;
            gap: 18px;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 29px;
            width: 100%;

            .middleLeft {
              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 15.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              h6 {
                color: #493f72;
                font-family: "Inter";
                font-size: 17.005px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .number {
                opacity: 0.96;
                color: #493f72;
                font-family: "Inter";
                font-size: 24.889px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }

              .month {
                color: #493f72;
                font-family: "Inter";
                font-size: 16.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.68;
              }
            }

            .middleLefts {
              img {
                opacity: 0.5;
              }

              span {
                color: #493f72;
                font-family: "Inter";
                font-size: 16.769px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 0.5;
              }
            }
          }

          .card-footer {
            width: 100%;

            .lastBtn {
              display: flex;
              justify-content: center;
              padding: 29px;

              button {
                width: 100%;
                // width: 261.939px;
                height: 59.307px;
                border-radius: 8px;
                background: #e3e3e3;
                color: #000;
                text-align: center;
                font-family: "Inter";
                font-size: 15.769px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
              }
            }
          }
        }

        .pricecard3 {
          background: #493f72;

          margin-top: -30px;
          height: fit-content;

          // margin-bottom: 40px;
        }

        .thirdcardbtn {
          // width: 261.939px;
          height: 59.307px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #000 !important;
          color: #fff !important;
          text-align: center;
          font-family: "Inter";
          font-size: 24.711px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  .feature{
    .feature-title{
      span{
        font-size: 35px !important;
      }
    }
  }
}

//priceplan css end
// detail css start

.detail {
  .detail-content{
    .detail-title{
      span{
        font-size: 30px !important;
      }
    }
  }
}
.detail {
  background: var(--black);
  width: 100%;
  // height: 100vh;

  .detail-content {
    padding-left: 220px;
    padding-right: 260px;
    padding-bottom: 30px !important;

    .detail-title {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
      text-align: center;

      span {
        color: var(--white);
        font-family: "Poppins";
        font-size: 35px !important;
        font-style: normal;
        font-weight: 600;
        line-height: 34.517px;
        padding: 30px 0 !important;
        /* 71.91% */
        text-transform: uppercase;
      }
    }

    .subdetail {
      display: flex;
      justify-content: space-between;
      gap: 109px;

      .left-content {
        // max-width: 390px;
        width: 100%;

        .left-title {
          padding-block: 28px;

          span {
            color: var(--white);
            font-family: "Poppins";
            font-size: 25.557px;
            font-style: normal;
            font-weight: 600;
            line-height: 33.225px;
            /* 130% */
          }
        }

        .left-links {
          ol {
            li {
              color: var(--white);
              font-family: "Inter" !important;
              font-size: 25.557px;
              font-style: normal;
              font-weight: 600;
              line-height: 38.336px;
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .detail {
    background: var(--black);
    width: 100%;
    // height: 100vh;

    .detail-content {
      padding-left: 150px;
      padding-right: 150px;
      padding-bottom: 77px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .detail {
    background: var(--black);
    width: 100%;
    // height: 100vh;

    .detail-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 77px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .detail {
    background: var(--black);
    width: 100%;
    // height: 100vh;

    .detail-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 77px;

      .detail-title {
        padding-top: 84px;
        text-align: center;
        padding-bottom: 110px;

        span {
          color: var(--white);
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 34.517px;
          /* 71.91% */
          text-transform: uppercase;
        }
      }

      .subdetail {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .left-content {
          // max-width: 390px;
          width: 100%;

          .left-title {
            padding-block: 28px;

            span {
              color: var(--white);
              font-family: "Poppins";
              font-size: 20.557px;
              font-style: normal;
              font-weight: 600;
              line-height: 33.225px;
              /* 130% */
            }
          }

          .left-links {
            ol {
              li {
                color: var(--white);
                // font-family: "Inter" !important;
                font-size: 17.557px;
                font-style: normal;
                font-weight: 600;
                line-height: 38.336px;
                padding-bottom: 12px;
                /* 150% */
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .detail {
    background: var(--black);
    width: 100%;
    // height: 100vh;

    .detail-content {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;

      .detail-title {
        padding-top: 84px;
        text-align: center;
        padding-bottom: 110px;

        span {
          color: var(--white);
          font-family: "Poppins";
          font-size: 48px;
          font-style: normal;
          font-weight: 600;
          line-height: 34.517px;
          /* 71.91% */
          text-transform: uppercase;
        }
      }

      .subdetail {
        display: flex;
        // flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // justify-content: space-between;

        gap: 20px;

        .left-content {
          // max-width: 390px;
          width: 100%;

          .left-title {
            padding-block: 28px;

            span {
              color: var(--white);
              font-family: "Poppins";
              font-size: 20.557px;
              font-style: normal;
              font-weight: 600;
              line-height: 33.225px;
              /* 130% */
            }
          }

          .left-links {
            ol {
              li {
                color: var(--white);
                font-family: "Inter";
                font-size: 17.557px;
                font-style: normal;
                font-weight: 600;
                line-height: 38.336px;
                padding-bottom: 12px;
                /* 150% */
              }
            }
          }
        }
      }
    }
  }
}

// detail css end

// feature css start

.feature {
  background: var(--dull-white);

  width: 100%;
  padding-left: 145px;
  padding-right: 145px;
  padding-bottom: 48px;
  padding-top: 64px;

  .feature-title {
    text-align: center;
    padding-bottom: 79px;

    span {
      color: #000;
      font-family: "Poppins";
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 34.517px;
      /* 71.91% */
      text-transform: uppercase;
    }
  }

  .feature-outer {
    min-width: 800px;
    overflow-x: auto;

    .feature-content {
      display: flex;

      align-items: center;
      padding-bottom: 36px;
      column-gap: 80px;
      width: 100%;

      .feature-first {
        width: 810px;
        display: flex;

        span {
          color: #000;
          font-family: "Inter";
          font-size: 24.449px;
          font-style: normal;
          font-weight: 400;
          line-height: 36.609px;
          /* 149.733% */
        }
      }

      .feature-second {
        text-align: center;
        width: 250px;

        span {
          color: #000;
          text-align: center;
          font-family: "Inter";
          font-size: 24.449px;
          font-style: normal;
          font-weight: 400;
          line-height: 36.609px;
          /* 149.733% */
        }
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .feature {
    background: var(--dull-white);

    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 48px;
    padding-top: 64px;

    .feature-title {
      text-align: center;
      padding-bottom: 79px;

      span {
        color: #000;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.517px;
        /* 71.91% */
        text-transform: uppercase;
      }
    }

    .feature-outer {
      .feature-content {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        column-gap: 40px;

        .feature-first {
          span {
            font-size: 20px;

            line-height: 120%;
          }
        }

        .feature-second {
          span {
            font-size: 20px;

            line-height: 120%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .feature {
    background: var(--dull-white);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 48px;
    padding-top: 64px;

    .feature-title {
      text-align: center;
      padding-bottom: 79px;

      span {
        color: #000;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.517px;

        text-transform: uppercase;
      }
    }

    .feature-outer-outer {
      display: flex;

      overflow-x: auto;

      .feature-outer {
        .feature-content {
          display: flex;
          // flex-direction: column;
          align-items: center;

          padding-bottom: 20px;
          column-gap: 40px;

          .feature-first {
            width: 1400px;

            span {
              font-size: 16px;

              // line-height: 120;
              img {
                width: 50px;
                height: 50px;
              }
            }
          }

          .feature-second {
            width: 50%;

            span {
              font-size: 16px;

              img {
                width: 30px;
                height: 30px;
              }

              // line-height: 120%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .feature {
    background: var(--dull-white);
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 48px;
    padding-top: 64px;

    .feature-title {
      text-align: center;
      padding-bottom: 79px;

      span {
        color: #000;
        font-family: "Poppins";
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 34.517px;

        text-transform: uppercase;
      }
    }

    .feature-outer-outer {
      display: flex;

      overflow-x: auto;

      .feature-outer {
        .feature-content {
          display: flex;
          // flex-direction: column;
          align-items: center;

          padding-bottom: 20px;
          column-gap: 40px;

          .feature-first {
            width: 1500px;

            span {
              font-size: 15px;

              // line-height: 120;
              img {
                width: 50px;
                height: 50px;
              }
            }
          }

          .feature-second {
            width: 50%;

            span {
              font-size: 15px;

              img {
                width: 50px;
                height: 50px;
              }

              // line-height: 120%;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1050px) {

//     .feature {
//         background: var(--dull-white);

//         width: 100%;
//         padding-left: 20px;
//         padding-right: 20px;
//         padding-bottom: 48px;
//         padding-top: 64px;

//         .feature-title {
//             text-align: center;
//             padding-bottom: 79px;

//             span {
//                 color: #000;
//                 font-family: "Poppins";
//                 font-size: 48px;
//                 font-style: normal;
//                 font-weight: 700;
//                 line-height: 34.517px;
//                 /* 71.91% */
//                 text-transform: uppercase;
//             }
//         }

//         .feature-outer {
//             display: flex;
//             // flex-direction: column;
//             // flex-wrap: nowrap;
//             overflow-x: auto;

//             .feature-content {
//                 display: flex;
//                 align-items: center;
//                 padding-bottom: 20px;
//                 column-gap: 40px;

//                 .feature-first {

//                     span {

//                         font-size: 18px;

//                         // line-height: 120;
//                         img {
//                             width: 50px;
//                             height: 50px;
//                         }
//                     }
//                 }

//                 .feature-second {

//                     span {
//                         font-size: 18px;

//                         img {
//                             width: 50px;
//                             height: 50px;
//                         }

//                         // line-height: 120%;
//                     }
//                 }
//             }
//         }

//     }
// }

// @media screen and (max-width: 1250px) {

//     .feature {
//         background: var(--dull-white);

//         width: 100%;
//         padding-left: 20px;
//         padding-right: 20px;
//         padding-bottom: 48px;
//         padding-top: 64px;

//         .feature-title {
//             text-align: center;
//             padding-bottom: 79px;

//             span {
//                 color: #000;
//                 font-family: "Poppins";
//                 font-size: 48px;
//                 font-style: normal;
//                 font-weight: 700;
//                 line-height: 34.517px;
//                 /* 71.91% */
//                 text-transform: uppercase;
//             }
//         }

//         .feature-outer {
//             min-width: 800px;
//             overflow-x: scroll;

//             .feature-content {
//                 display: flex;
//                 justify-content: space-between;

//                 align-items: center;
//                 padding-bottom: 36px;

//                 .feature-first {
//                     // display: flex;
//                     // align-items: center;
//                     // width: 80%;
//                     width: 610px;

//                     span {
//                         color: #000;
//                         font-family: "Inter";
//                         font-size: 19px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: 36.609px;
//                         /* 149.733% */
//                     }
//                 }

//                 .feature-second {
//                     width: 20%;
//                     text-align: center;

//                     span {
//                         color: #000;
//                         text-align: center;
//                         font-family: "Inter";
//                         font-size: 19px;
//                         font-style: normal;
//                         font-weight: 400;
//                         line-height: 36.609px;
//                         /* 149.733% */
//                     }
//                 }
//             }
//         }

//     }
// }

// feature css end
