// Happy
.happy {
  background: #f5f5f7;
  .freebotdm-title {
    text-align: center;
    padding: 50px 319px 29px 319px;
    p {
      width: 100%;
    }
    video {
      width: 1047px;
      margin-top: 50px;
    }
  }
}
// Step-cards
.agency-stepcards {
  background: #f5f5f7;
  .stepCard-title {
    h2 {
      width: 1070px;
    }
  }
  .step-cards {
    padding: 81px 150px;
    .social-card-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px;
      .content {
        width: 355.494px;
        height: 363.394px;
        border-radius: 6.32px;
        background: #fff;
        box-shadow: 0px 6.31989px 18.95967px 0px rgba(0, 0, 0, 0.2);
        .card-content {
          .card-title {
            text-align: center;
            margin-top: 11px;
            span {
              color: #000;
              font-family: "Poppins";
              font-size: 25.168px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .card-img {
            width: 100%;
            height: 100%;
            margin-top: 31px;
            padding-left: 100px;
            padding-right: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 151.158px;
              height: 124.468px;
              object-fit: cover;
            }
          }

          .card-paragraph {
            padding: 29px;

            p {
              color: #000;
              text-align: center;
              font-family: "Poppins";
              font-size: 18.876px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
      }
    }
  }

  .stepCard-button {
    display: flex;
    justify-content: center;

    button {
      padding: 11.213px 27.098px;
      border-radius: 33.639px;
      border: 0.934px solid #000;
      background: #000;
      color: #fff;
      text-align: center;
      font-family: "Coco Gothic";
      font-size: 18.689px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      height: 51px;
    }
  }
}
// Priority
.priority {
  padding: 0px 119px 81px 119px;
  background: #f5f5f7;
  h2 {
    width: 872px;
  }
}
// Combined
.combined {
  background: #000;
  padding: 54px 327px 80px 327px;
  p {
    width: 1219px;
  }
  .customer-cards {
    gap: 41px;
    .card {
      padding: 6px;
      width: 416.229px;
      height: 300px;
      border-radius: 6.212px;
      background: #fff;
      box-shadow: 0px 6.21237px 18.63711px 0px rgba(0, 0, 0, 0.2);

      //   .card-body {
      //     p {
      //       margin-bottom: 0;
      //       margin-top: 0;
      //     }
      //   }
    }
    .community {
      display: none;
    }
    .blog {
      display: none;
    }
    // .agencies {
    //   width: 100%;
    //   position: absolute;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   top: 60%;
    // }
  }
  .faq {
    .accor {
      .accordion {
        .accordion-item {
          background: transparent;
          border-color: #fff;
          .accordion-header {
            .accordion-button {
              background: transparent;
              color: #fff;
              &::after {
                display: none;
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
// ------------------------------------Media Queries---------------------------

@media screen and (max-width: 1700px) {
  .happy {
    .freebotdm-title {
      padding: 50px 150px;
      p {
        width: 100%;
      }
      video {
        width: 100%;
      }
    }
  }
  .agency-stepcards .step-cards .social-card-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
  .combined {
    background: #000;
    padding: 54px 80px 80px 80px;
    p {
      width: 100%;
    }
  }
}
@media screen and (max-width: 1500px) {
  .agency-stepcards .step-cards {
    padding: 80px 20px;
    .social-card-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
  }
  .combined {
    p {
      font-size: 28px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .agency-stepcards {
    .step-cards {
      padding: 80px 20px;
      .social-card-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        .content {
          width: 100%;
        }
      }
    }
    .stepCard-title {
      h2 {
        width: 100%;
      }
    }
  }
  .happy {
    .freebotdm-title {
      padding: 50px;
      p {
        font-size: 28px;
      }
    }
  }
  .foot {
    h2 {
      font-size: 40px;
    }
  }
  .combined {
    p {
      font-size: 24px;
    }
    button {
      margin-top: 0px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .happy {
    .freebotdm-title {
      padding: 50px 20px;
      h2 {
        font-size: 23px;
      }
      p {
        font-size: 15px;
      }
      button {
        width: 100%;
      }
    }
  }
  .agency-stepcards {
    .stepCard-title {
      h2 {
        font-size: 22px;
        padding:  10px;
      }
    }
    .step-cards {
      padding: 0px 20px 0px 20px;

      .social-card-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
      }
    }
  }
  .priority {
    padding: 50px 20px;

    h2 {
      width: 100%;
      font-size: 22px;
    }
  }
  .foot {
    h2 {
      font-size: 26px;
    }
  }
  .combined {
    padding: 50px 20px;
    h2 {
      font-size: 26px;
    }
    p {
      font-size: 15px;
    }
    .customer-cards {
      flex-direction: column;
      .card {
        width: 100%;
        height: 100%;
      }
    }
    button {
      width: 100%;
    }
  }

  .faq {
    h1 {
      font-size: 26px;
    }
    p {
      font-size: 17px;
    }
  }
}
