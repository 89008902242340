.btn-prime {
  color: #404040;
  font-family: "Coco Gothic";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.btn-primary {
  font-family: "Coco Gothic";
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--black);
  border: 1px solid var(--white);
  border-radius: 30px;
  // &:hover {
  //   color: var(--white);
  //   background: transparent;
  //   border: 1px solid var(--white);
  // }
}
.btn-secondary {
  font-family: "Coco Gothic";
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(--white);
  border: 1px solid #000;
  border-radius: 30px;
  color: var(--black);
  &:hover {
    color: var(--white);
    background: var(--purple);
    border: none;
    outline: none;
  }
}
.btn-primary-2 {
  font-family: "Coco Gothic";
  font-size: 20px;
  border-radius: 30px;
  background: var(--purple);
  border: 1px solid var(--black);
  color: var(--white);

  &:hover {
    color: var(--white);
    background: var(--purple);
    border: 1px solid var(--black);
  }
}
.btn-secondary-2 {
  font-family: "Coco Gothic";
  font-size: 16px;
  font-weight: 400;
  border-radius: 30px;
  background: var(--white);
  border: 1px solid var(--black);
  color: var(--black);
  border-radius: 30px;
  &:hover {
    color: var(--black);
    background: var(--transparent);
    border: 1px solid var(--black);
  }
}

.font-book {
  font-family: "Arboria-Book";
}
.btn-primary-3 {
  font-family: "Coco Gothic";
  font-size: 20px;
  border-radius: 30px;
  background: var(--purple);
  border: 1px solid var(--purple);
  color: var(--white);

  &:hover {
    color: var(--white);
    background: var(--purple);
    border: 1px solid var(--purple);
  }
}
