.fbook h2 {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.fbook {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 528px;
    width: 98%;
    border-radius: 8px;
    border: 1px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.10);
    margin: auto;
}
.fbook p {
    width: 461px;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.generl {  

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 528px;
    width: 98%;
    border-radius: 8px;
    border: 1px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.10);
}
.generl h2 {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.generl p {
    width: 461px;
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.teamMember {
    padding: 75px 10px 38px 10px;
    background: var(--transparent);
}

@media screen and (max-width:600px) {
    .fbook h2 {
        font-size: 22px;
        font-weight: 500;
        padding: 0 10px;
    }

    .fbook {
        height: 350px;
    }
    .fbook p {
        width: 100%;
        font-size: 12px;
        padding: 5px 8px;
        
    }
    .generl p {
        width: 100%;
        font-size: 12px;
        padding: 5px 8px;
        
    }
    .generl {
       
        height: 350px;
        width: 100%;
       
    }
    .generl h2 {
        font-size: 22px;
        font-weight: 500;
    }
    .teamMember {
        padding: 0;
        margin-top: 30px;
        background: var(--transparent);
    }
}