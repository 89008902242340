
.modal-content {
    width: 120%;
}
.toptext h2 {
    color: #000;
    font-size: 17px;
    padding-left: 60px;
}
.remid {
    padding: 10px 48px;
}

.remid .contact {
    display: flex;
    column-gap: 13px;
    width: 100%;
    padding: 10px;
    background: #F2D2BD;
    border-radius: 5px;
    border: 1px solid rgb(242, 242, 145);
}
.remid .contact .rpara span{
        font-size: 15px;
        font-weight: 600;
}
.remid .contact img {
    width: 25px;
    height: 25px;
}
.remid .contact p {
    color: #000;
    font-size: 15px;
    font-family: "poppins";
    margin-bottom: 0;
}
.remid .under .remvacc {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.remid .under .remvacc .remthird {
    row-gap: 7px;
    padding-top: 13px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}
.remid .under .remvacc .remthird h2 {
    font-size: 16px;
    color: #000;
    text-align: left;
    font-weight: 500;
    font-family: "poppins";
    
}
.remid .under .remvacc .remthird .entext input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 4px 8px;
    font-family: "poppins";
    font-weight: 400;
}
.remid .under .remvacc .remthird .entext input:focus {
    border: 1px solid rgb(107, 107, 193) ;
}
.fot {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px;
    align-items: center;
    border-top: 1px solid lightgray;
}
.fot .confirm button {
    width: 94px;
    height: 36px;
    border-radius: 5px;
    background: #FFB6C1;
    
}
.fot .confirm button:hover {
    border: 1px solid palevioletred;
    background: lightpink;

}
.fot .cancel button {
    width: 94px;
    height: 36px;
    border-radius: 5px;
    background: transparent;
    border: 1px solid lightgray;
    color: #000;
    font-size: 16px;
    font-weight: 400;
}
.fot .cancel button:hover {
    border: 1px solid gray;
    background: rgb(238, 235, 235);
}
hr {
    margin: 0;
}
.modal-content {
    width: 120%;
}