.tag {
    width: 96%;
    margin: auto;
    margin-top: 80px;
    height: 429.154px;
    border-radius: 6.502px;
    border: 0.813px solid #B5B5B5;
    background: #FFF;
    box-shadow: 0px 0px 13.00467px 3.25117px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.tag .notag h2 {
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 19.507px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.tag .notag p{
    color: #000;
    text-align: center;
    font-family: "Poppins";
    font-size: 11.379px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.tag .notag button{
    width: 143.864px;
    height: 33.324px;
    flex-shrink: 0; 
    border-radius: 6.502px;
    background: #000;
    color: #FFF;
    font-family: "Poppins";
    font-size: 13.005px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
   
}
.tag .notag .botbtn{
    padding-top: 10px;
}

@media screen and (max-width:600px) {
    .tag .notag h2 {
        font-size: 15.507px;
    }
    .tag {
        height: 350px;
        margin-top: 30px !important;
    }
    .tag .notag p {
        
        font-size: 10px;
       
    }
    .tag .notag button {
        width: 110px;
        font-size: 11px;
    }
    
}
