// Free bot Dm
.increase {
  background: #f5f5f7;
  .freebotdm-title {
    text-align: center;
    padding: 118px 200px 59px 200px;
    p {
      width: 1226px;
    }
  }
}
// Pink Sec
.pink-sec {
  padding: 64px 96px;
  background: #f5f5f7;
  .usechat-content {
    .right {
      margin-left: 18px;
      width: 50%;
    }
    .left {
      width: 50%;
    }
  }
}
// Why
.why {
  .dm-bot {
    padding: 90px 224px;
    // background: #000;
    .discover-content {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .content {
        p {
          text-align: center;
          color: #fff;
        }
      }
    }
  }
  .freebotdm {
    .freebotdm-title {
      padding: 100px 366px;
      button {
        background: #fff;
        color: #000;
      }
    }
  }
  .faq {
    .accor {
      .accordion {
        .accordion-item {
          background: transparent;
          border-color: #fff;
          .accordion-header {
            .accordion-button {
              background: transparent;
              color: #fff;
              &::after {
                display: none;
              }
            }
          }
          .accordion-collapse {
            .accordion-body {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

// --------------------------Media Queries---------------------------------------------
@media screen and (max-width: 1700px) {
  .increase {
    .freebotdm-title {
      padding: 118px 200px 59px 200px;
      p {
        width: 100%;
      }
    }
  }
  .pink-sec {
    padding: 64px 200px;
  }
  .grow {
    padding: 64px 150px;
  }
  .why {
    .dm-bot {
      padding: 50px 200px;
    }
    .freebotdm {
      .freebotdm-title {
        padding: 60px 300px;
      }
    }
    .faq {
      padding: 60px 200px;
    }
  }
}
@media screen and (max-width: 1500px) {
  .increase {
    .freebotdm-title {
      padding: 50px 150px;
    }
  }
  .pink-sec {
    padding: 50px 150px;
    .usechat-content {
      .right {
        margin-left: 18px;
      }
    }
  }
  .grow {
    padding: 50px 100px;
  }
  .why {
    .dm-bot {
      padding: 50px 100px;
      .discover-content {
        .content {
          h3 {
            font-size: 30px;
          }
          h4 {
            font-size: 30px;
          }
        }
      }
    }
    .freebotdm {
      .freebotdm-title {
        padding: 50px 150px;
      }
    }
    .faq {
      padding: 50px 150px;
    }
  }
}
@media screen and (max-width: 1200px) {
  .increase {
    .freebotdm-title {
      padding: 50px 100px;
    }
  }
  .pink-sec {
    padding: 50px 100px;
    .usechat-content {
      .right {
        margin-left: 18px;
      }
    }
  }
  .grow {
    padding: 50px 100px;
  }
  .why {
    .dm-bot {
      padding: 50px 80px;
      .discover-content {
        .content {
          h3 {
            font-size: 25px;
          }
          h4 {
            font-size: 25px;
          }
        }
      }
    }
    .freebotdm {
      .freebotdm-title {
        padding: 50px 100px;
        h2 {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }
    .faq {
      padding: 50px 100px;
    }
  }
}
@media screen and (max-width: 600px) {
  .increase {
    .freebotdm-title {
      padding: 30px 20px;
      h2 {
        font-size: 25px;
        text-align: center;
      }
      p {
        font-size: 15px;
        margin-top: 10px;
        text-align: justify;
      }
      button {
        width: 100%;
      }
    }
  }
  .pink-sec {
    padding: 0px 20px;
    .usechat-content {
      flex-direction: column-reverse;
      .left {
        width: 100%;
        margin-top: 48px;
      }
      .right {
        width: 100%;
        margin-left: 0;
      }
    }
    .second {
      flex-direction: column;
      margin-top: 0 !important;
      .right {
        margin-top: 48px;
      }
    }
  }
  .grow {
    padding: 50px 20px;
  }
  .why {
    .dm-bot {
      padding: 50px 20px;
      margin-top: 50px;
      h2{
        font-size: 38px ;
      }
      .discover-content {
        flex-direction: column;
        margin-top: 20px !important;
        .content {
      
          h3 {
            font-size: 15px;
          }
          h4 {
            font-size: 15px;
          }
          p {
            font-size: 15px;
            color: #fff;
          }
        }
      }
    }
    .freebotdm {
      .freebotdm-title {
        padding: 10px 20px;
        h2 {
          color: #fff;
          font-size: 25px;
        }
        p {
          color: #fff;
          font-size: 15px;
        }
      }
    }
    .faq {
      padding: 50px 20px;
      h1 {
        font-size: 25px;
      }
      p {
        font-size: 15px;
      }
      .accor {
        margin-top: 10px;
      }
    }
  }
}
