.ecommerce {
    background: var(--dull-white);
}

//mobilesale start
.mobilesale {
    background: var(--dull-white);
    width: 100%;
    padding-left: 320px;
    padding-right: 320px;

    .mobilesale-content {
        padding-top: 50px;
        text-align: center;

        h6 {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 92px;
            margin-bottom: 10px;
        }

        p {
            color: #000;
            text-align: center;
            font-family: "Poppins";
            font-size: 32px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        button {
            border-radius: 36px;
            border: 1px solid #000;
            background: #000;
            width: 244px;
            height: 51px;
            padding: 12px 29px;
            color: #FFF;
            text-align: center;
            font-family: "Coco Gothic";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 65px;
            margin-bottom: 43px;
        }
    }
}

@media screen and (max-width: 1700px) {
    .mobilesale {
        background: var(--dull-white);
        width: 100%;
        padding-left: 200px;
        padding-right: 200px;

    }
}

@media screen and (max-width: 1500px) {
    .mobilesale {
        background: var(--dull-white);
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;


    }
}

@media screen and (max-width: 650px) {
    .mobilesale {
        background: var(--dull-white);
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;

        .mobilesale-content {
            padding-top: 10px;
            text-align: center;

            h6 {

                font-size: 22px;

            }

            p {

                font-size: 20px;

            }

            button {

                width: 200px;
                height: 51px;
                padding: 12px 29px;

                margin-top: 20px;
                margin-bottom: 43px;
            }
        }
    }
}

//mobilesale end
//imagecard start
.imagecard {
    background: var(--dull-white);

    .turn {
        padding: 64px 290px;
        background: #f5f5f7;

        .usechat-content {
            display: flex;


            .right {
                margin-left: 13px;
                width: 50%;
                margin-top: 10px;

                h6 {

                    color: #000;
                    font-family: "Poppins";
                    font-size: 100.58px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 168.333px;
                    /* 114.062% */
                }
            }

            .left {
                width: 50%;
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 1850px) {
    .imagecard {


        .turn {
            padding: 64px 290px;
            background: #f5f5f7;

            .usechat-content {
                .right {
                    margin-left: 13px;
                    width: 50%;

                    h6 {


                        font-size: 80px;


                        line-height: 100px;

                    }
                }

                .left {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .imagecard {


        .turn {
            padding: 64px 150px;


        }
    }
}

@media screen and (max-width: 1300px) {
    .imagecard {


        .turn {
            padding: 64px 50px;

            .usechat-content {
                .right {

                    width: 50%;

                    h6 {


                        font-size: 60px;


                        line-height: 70px;

                    }
                }

                .left {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .imagecard {


        .turn {
            padding: 64px 20px;

            .usechat-content {

                .right {

                    width: 50%;

                    h6 {


                        font-size: 60px;


                        line-height: 70px;

                    }
                }

                .left {
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .imagecard {


        .turn {
            padding: 64px 20px;

            .usechat-content {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .right {
                    margin: 0px;
                    width: 100%;

                    h6 {
                        text-align: center;

                        font-size: 80px;


                        line-height: 100px;

                    }
                }

                .left {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .imagecard {


        .turn {
            padding: 64px 20px;

            .usechat-content {
                display: flex;

                gap: 10px;

                .right {
                    margin: 0px;
                    width: 100%;

                    h6 {
                        text-align: center;

                        font-size: 50px;


                        line-height: 100px;

                    }
                }

                .left {
                    width: 100%;
                }
            }
        }
    }
}

//imagecard end
//courses start
.courses {
    padding: 0px 300px 72px 300px;
    background: var(--dull-white);

    .courses-content {
        width: 100%;
        // border-radius: 9px;
        background: #FFF;
        // height: 560px;
        display: flex;
        padding: 81px 71px 81px 71px;

        .left {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 50px;
            // align-items: center;

            h6 {
                color: #493F72;
                font-family: "Poppins";
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }

            p {
                color: #483F70;
                font-family: "Poppins";
                font-size: 32px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

        .right {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: 1650px) {
    .courses {
        padding: 0px 100px 72px 100px;
        background: var(--dull-white);


    }
}

@media screen and (max-width: 1250px) {
    .courses {
        padding: 0px 20px 72px 20px;
        background: var(--dull-white);

        .courses-content {

            padding: 81px 50px 81px 50px;

            .left {

                padding-left: 10px;
                // align-items: center;


            }

            .right {
                width: 50%;


                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .courses {
        padding: 0px 20px 72px 20px;
        background: var(--dull-white);

        .courses-content {

            padding: 81px 20px 81px 20px;

            .left {

                padding-left: 10px;

                // align-items: center;
                h6 {
                    font-size: 30px;
                }

                p {
                    font-size: 22px;
                }
            }

            .right {
                width: 50%;


                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .courses {
        padding: 0px 20px 72px 20px;
        background: var(--dull-white);

        .courses-content {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 81px 20px 0px 20px;

            .left {
                width: 100%;
                padding-left: 10px;
                order: 2;
                text-align: center;


                h6 {
                    font-size: 25px;
                }

                p {
                    font-size: 13px;
                }
            }

            .right {
                width: 100%;


                video {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// courses end 