.settings {
  /* height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  height: 100%;
}
.settings .settingContent {
  display: flex;
  width: 100%;
}
.settings .showbtn{
  display: none;
}
.settingContent .leftSection {
  width: 25%;
  background: var(--dull-white);
  /* height: 100%; */
  padding-bottom: 43px;
}
.settingContent .leftSection .topSection ul {
  padding-left: 51px;
  padding-top: 44px;
}
.leftSection .topSection ul li {
  padding-block: 5px;
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.leftSection .topSection ul li .active-tab {
  color: #420061; /* Change this to the desired color */
}
.leftSection .topSection ul .first-li {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.settingContent .rightSection {
  width: 75%;
}


@media screen and (max-width:600px) {
  .settings .settingContent{
    margin: auto;
    margin-top: 45px;
    width: 100%;
  }
  .settings .showbtn{
    display: block;
    margin: 10px;
    position:absolute;
    right: 8px;
  }
  .showleftsection{
    display: block;
  }
  .hideleftsection{
    display: none;
  }
  .settingContent .rightSection {
    width: 95%;
    margin: auto;
  }
  .settingContent .leftSection {
    position: absolute;
    /* height: 100% !important; */
}
.settingContent .leftSection {
  width: 60%;
  padding-bottom: 10px !important;
  background: #F3F2F2;
}
.topSection {
  margin-block: 0 !important;
}
.settingContent .leftSection .topSection ul {
  padding-top: 30px;
}
}
@media screen and (max-width:1300px) {
  .settingContent .leftSection .topSection ul {
    padding-left: 40px;
    padding-top: 0px !important;
} 
.leftSection .topSection ul li{
  font-size: 15px !important;
}
}