.ebookMainDiv {
  background-color: #f2f2f4;
  padding: 60px 200px;
}
.ebookMainDiv .headingMain {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  align-self: center;
}
.ebookMainDiv .headingSub {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  align-self: center;
  margin-top: 5px;
}
.ebookMainDiv .firstMainDiv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  gap: 25px;
}

.ebookMainDiv .firstMainDiv .cardDiv {
  border-radius: 7.211px;
  background: #fff;
  box-shadow: 0px 7.21053px 21.63158px 0px rgba(0, 0, 0, 0.15);
  padding: 38px 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 389.368px;
  height: 383.059px;
}

.ebookMainDiv .firstMainDiv .cardDiv .cardHeading {
  text-align: center;
  color: #000;
  width: 100%;
  text-align: center;
  font-family: "Poppins";
  font-size: 20.632px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ebookMainDiv .firstMainDiv .cardDiv .cardText {
  color: black;
  font-family: "Poppins";
  font-size: 21.632px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.ebookMainDiv .firstMainDiv .cardDiv .cardButton {
  width: 307.349px;
  height: 48.671px;
  flex-shrink: 0;
  border-radius: 53.178px;
  background: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 10px;
}
.ebookMainDiv .firstMainDiv .cardDiv .cardButton .cardButtonText {
  color: #fff;
  text-align: center;
  font-family: " Coco Gothic";
  font-size: 14.421px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.ebookMainDiv .try {
  margin-top: 50px;
}
@media screen and (max-width: 1700px) {
  .ebookMainDiv .firstMainDiv .cardDiv {
    width: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .ebookMainDiv {
    background-color: #f2f2f4;
    padding: 60px 100px;
  }
  .ebookMainDiv .firstMainDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1250px) {
  .ebookMainDiv {
    padding: 60px 60px;
  }
}

@media screen and (max-width: 1050px) {
  .ebookMainDiv .firstMainDiv {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 700px) {
  .ebookMainDiv {
    padding: 60px 60px;
  }

  .ebookMainDiv .headingMain {
    font-size: 25px;
  }
  .ebookMainDiv .headingSub {
    margin-top: 20px;
  }
  .ebookMainDiv .firstMainDiv {
    grid-template-columns: repeat(1, 1fr);
  }
  .ebookMainDiv .firstMainDiv .cardDiv {
    padding: 10px 15px 15px 10px;
    width: 100%;
  }
  .ebookMainDiv .firstMainDiv .cardDiv .cardButton {
    width: 75%;
    height: 40.671px;
  }
  .ebookMainDiv .firstMainDiv .cardDiv .cardButton .cardButtonText {
    font-size: 11px;
  }
}
@media screen and (max-width: 600px) {
  .ebookMainDiv {
    padding: 50px 20px;
  }
  .ebookMainDiv .headingSub {
    font-size: 18px;
  }
  .ebookMainDiv .firstMainDiv .cardDiv .cardText {
    font-size: 17.632px;
    text-align: center;
  }
  .ebookMainDiv .firstMainDiv .cardDiv {
    width: 100%;
    height: 300px;
    padding: 20px 15px;
  }
}
