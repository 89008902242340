.home {
  padding: 40px 319px 100px 147px;
}

.home .heading {
  color: #000;
  font-family: Vidaloka;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.home .subHeadingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  width: 260px;
}
.home .subHeadingDiv .subHeadingOne {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.home .subHeadingDiv .subHeadingTwo {
  color: #420061;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.home .titleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
}

.home .titleDiv .titleTextOne {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home .titleDiv .titleTextTwo {
  color: #420061;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home .mainDivCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 23px;
  background-color: #edf2ed;
}
.home .mainDivCard .indust-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #3eb095;
  box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.3);
  padding: 10px;
  min-width: 150px;
}
.home .mainDivCard .indust-card .imggg {
  object-fit: contain;
  width: 161.46px;
  height: 121.46px;
  align-self: center;
}
.home .mainDivCard .indust-card .cardTitle {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  text-align: start;
  margin: 0;
}

.home .mainDivCard .indust-card .cardText {
  width: 100%;
  color: #fff;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  text-align: start;
  margin-top: 8px;
}

/* Modal Styling */
.home .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 1;
  /* animation: slide-up 0.3s ease; */
}

.home .modal .modal-content {
  width: 90%;
  height:auto;
  flex-shrink: 0;
  padding: 20px 35px 20px 35px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.25);
}

.home .modal .modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.home .modal .modal-content .textOne {
  width: 456px;
  color: #000;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.home .modal .modal-content .textTwoCopy {
  width: 429px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-top: 5px;
}
.home .modal .modal-content .textTwo {
  width: 429px;
  flex-shrink: 0;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.home .modal .modal-content .subHeading {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}
.home .modal .modal-content .modalDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.home .modal .modal-content .modalDiv .textTwo {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  margin-left: 10px;
}

.home .modal .modal-content .modalButtonMainDiv {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.home .modal .modal-content .modalButtonMainDiv .modalButtonDivLeft {
  width: 180px;
  height: 41px;
  border-radius: 8px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.home .modal .modal-content .modalButtonMainDiv .modalButtonDivRight {
  width: 180px;
  height: 41px;
  border-radius: 8px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.home
  .modal
  .modal-content
  .modalButtonMainDiv
  .modalButtonDivLeft
  .buttonText {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}
.home
  .modal
  .modal-content
  .modalButtonMainDiv
  .modalButtonDivRight
  .buttonText {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1000px) {
  .home {
    padding: 20px 10px 30px 10px;
  }
}

@media screen and (max-width: 650px) {
  .home {
    padding: 20px 10px 30px 10px;
  }
  .home .heading {
    font-size: 20px;
  }
  .home .subHeadingDiv {
    width: 100%;
  }

  .home .titleDiv .titleTextOne {
    font-size: 13px;
    width: 60%;
  }
  .home .subHeadingDiv .subHeadingOne {
    font-size: 12px;
  }
  .home .subHeadingDiv .subHeadingTwo {
    font-size: 12px;
  }
  .home .titleDiv .titleTextTwo {
    font-size: 12px;
  }

  .home .mainDivCard {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    background-color: #edf2ed;
  }
  .home .mainDivCard .indust-card {
    width: 100%;
    min-width: 0px;
  }

  .home .mainDivCard .indust-card .cardTitle {
    font-size: 14px;
  }

  .home .modal .modal-content {
    padding: 19px;
    overflow: auto;
    row-gap: 10px;
  }
  .home .modal .modal-content .modalButtonMainDiv{
    justify-content: center !important;
  }
  .home .modal .modal-content .textOne {
    width: 90%;
    font-size: 20px;
    align-self: center;
  }
  .home .modal .modal-content .textTwo {
    font-size: 12px;
    text-align: center;
    margin-left: 0px;
    width: 100%;
    align-self: center;
  }
  .home .modal .modal-content .subHeading {
    font-size: 13px;
    margin-top: 5px;
  }
  .home .modal .modal-content .textTwoCopy {
    width: 90%;
    font-size: 13px;
    margin-top: 2px;
    align-self: center;
    text-align: center;
  }
  .home .modal .modal-content .modalDiv .textTwo {
    font-size: 12px;
    text-align: start;
    margin-left: 5px;
    width: 95%;
  }

  .home
    .modal
    .modal-content
    .modalButtonMainDiv
    .modalButtonDivLeft
    .buttonText {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0;
  }
  .home
    .modal
    .modal-content
    .modalButtonMainDiv
    .modalButtonDivRight
    .buttonText {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin: 0;
  }
}
