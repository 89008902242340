.facebookSection {
    .facebookContent {
        width: 100%;
        display: flex;
        padding: 30px;

        .leftContent {
            width: 50%;
            background: var(--dull-white);
            padding: 50px 0px 250px 0px;


            .content {

                padding-left: 20px;
                display: flex;
                flex-direction: column;
                gap: 100px;

                .title {
                    h6 {
                        color: #000;
                        // text-align: center;
                        font-family: "Poppins";
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
                }

                .midSection {

                    .titleHeading {
                        h6 {
                            color: gray;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 40px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                        }

                        p {
                            color: gray;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                    }
                }

                .bottomSection {
                    display: flex;
                    align-items: center;

                    span {
                        color: gray;
                        // text-align: center;
                        font-family: "Poppins";
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        cursor: pointer;
                    }
                }
            }
        }

        .rightContent {
            width: 50%;
            background: white;
            // padding: 0px 100px 0px 200px;
            display: flex;
            align-items: center;
            justify-content: center;

            .content {
                display: flex;
                flex-direction: column;
                gap: 28px;
            }

            .combine {
                .profilesection {
                    // border-bottom: 1px solid black;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0px 10px 0px;

                    .left {
                        display: flex;
                        align-items: center;


                        span {
                            color: #000;
                            // text-align: center;
                            font-family: "Poppins";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            margin-left: 15px;
                        }
                    }

                    .right {
                        button {
                            padding: 10px 20px;
                            border: none;
                            background: gainsboro;
                            font-family: "Poppins";
                            font-size: 16px;
                            font-weight: 500;
                            color: gray;
                            border-radius: 30px;
                        }
                    }
                }

                // hr {
                //     height: 5px;
                // }
            }
        }

        .links {
            a {
                padding-inline: 20px;
                cursor: pointer;
                font-family: "Poppins";
            }
        }
    }
}
@media screen and (max-width:1600px) {
    .facebookSection{
        .facebookContent{
            padding: 40px ;
            .leftContent{
                .content{
                    padding-left: 0px;
                }
            }
        }
    }
}
@media screen and (max-width:800px) {
    .facebookSection{
        .facebookContent{
            flex-direction: column;
            padding: 20px;
            .leftContent{
                width: 100%;
                padding: 10px 0;

                .content{
                    gap: 25px;
                    .title{
                        h6{
                            font-size: 28px;
                        }
                    }
                   .midSection{
                    .msngicon{
                        
                    }
                    .titleHeading{
                        h6{
                            font-size: 28px;
                        }
                    }
                   }
                    }
                }
                .rightContent{
                    width: 100%;
                    .content{
                        margin-top: 30px;
                    }
                    .combine{
                        .profilesection{
                            .left{
                                span{
                                    font-size: 15px;
                                }
                            }
                            .right{
                                button{
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
                .links{
                    a{
                        display: flex !important;
                        justify-content: flex-start;
                        padding: 7px 0;
                    }
                }
            }
           
        }
    }
