.messenger-input-node {
  /* align-items: center; */
  /* padding-left: 20px; */
  padding-top: 10px;
  gap: 20px;
  /* color: black; */
  width: 320px;
  min-height: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  padding-bottom: 10px;
}

.messenger-input-node .text-1 {
  color: gray;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

.messenger-input-node .text-2 {
  color: black;
  margin-top: -5px;
  padding: 0;
}
.messenger-input-node .header {
  display: flex;
  gap: 10px;
  padding-left: 10px;
}

.messenger-input-node .input-area {
  margin: 10px auto;
  width: 90%;
  border: 1px dashed gray;
  min-height: 80px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
}

.messenger-input-node .input-area p {
  color: gray;
  font-size: 15px;
}

.messenger-input-node:hover {
  outline: 3px solid green;
}
