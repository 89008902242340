.widget {
  padding: 61px 52px 0px 39px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  width: 100%;
}
.widget .first {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background: #f3f2f2;
  padding: 20px 41px 7px 33px;
  column-gap: 20px;
}
.widget .first h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}
.widget .first input {
  width: 315px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #a5a3a3;
  outline: none;
  color: #585555;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 10px;
}
.widget .first p {
  width: 100%;
  color: #949494;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.second {
  background: #f3f2f2;
  /* padding: 16px 227px 24px 33px; */
  border-radius: 4px;
}
.second .web {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 16px 33px;
}
.second .web h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.second .web .webinput input {
  color: #585555;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.second .web .webinput .input1 input {
  width: 95px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 76px;
  border: 1px solid #a5a3a3;
  outline: none;
  text-align: center;
}
.second .web .webinput .input2 input {
  width: 108px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 76px;
  border: 1px solid #a5a3a3;
  outline: none;
  text-align: center;
}
.second .web .webinput .input3 input {
  width: 95px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 76px;
  border: 1px solid #a5a3a3;
  outline: none;
  text-align: center;
}
.second .webinput {
  display: flex;
  column-gap: 7px;
}
.second .web .para p {
  color: #949494;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.second .input4 {
  display: flex;
  padding-top: 48px;
  justify-content: center;
  column-gap: 8px;
  padding-right: 55px;
  padding-bottom: 24px;
}
.second .input4 input {
  width: 315px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #a5a3a3;
  color: #585555;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  outline: none;
  padding: 0 10px;
}
.second .input4 .addbtn {
  width: 42px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #420061;
  color: #fff;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}
.widget .third .enablebtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 10px;
  /* padding-left: 10px;  */
  width: 173px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #a5a3a3;
}
.widget .third .enablebtn label {
  color: #585555;
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.widget .third {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px;
  background: #f3f2f2;
  padding: 16px 41px 38px 33px;
}
.widget .third h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.widget .third input {
  width: 17.438px;
  height: 8.438px;
  margin-left: 1px;
}
.widget .third p {
  width: 445px;
  color: #949494;
  font-family: "Poppins";
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

@media screen and (max-width:1500px) {
  .widget .first{
    flex-direction: column;
    row-gap: 20px;
  }
  .second .web{
    flex-wrap: wrap;
    gap: 15px;
  }
  .second .input4{
    justify-content: flex-start;
    padding-left: 37px !important;
  }
}

@media screen and (max-width:600px) {
  .widget{
    padding: 8px;
  }
  .widget .first{
    flex-direction: column;
    row-gap: 15px;
    padding: 10px;
  }
  .widget .first p {
    width: 100% !important;
  }
  .widget .first input{
    width: 100%;
  }
  .second .web {
    flex-direction: column;
    row-gap: 15px;
    padding: 10px ;
  }
  .second .web h2{
    text-align: start;
    font-size: 16px;
  }
  .second .webinput {
    flex-direction: column;
    row-gap: 10px;
  }
  .second .web .webinput .input1 input {
    width: 100%;
    height: 24px;
  }
  .second .web .webinput .input2 input {
    width: 100%;
    height: 24px;
  }
  .second .web .webinput .input3 input {
    width: 100%;
    height: 24px;
  }
  .second .input4 {
    flex-direction: column;
    padding-top: 10px;
    row-gap: 15px;
    padding-left: 10px !important;
    padding: 10px;
  }
  .second .input4 input{
    width: 100%;
  }
  .widget .third {
    padding: 10px;
    flex-direction: column;
    row-gap: 15px;
  }
  .widget .third .enablebtn {
    width: 100%;
  }
  .widget .third p {
    width: 100%;
    margin-bottom: 0;
  }
}

@media screen and (max-width:1500px) {
  .widget .third{
    flex-wrap: wrap;
  }
}