.dashboard {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

.dashboard .left {
  background-color: #3eb095;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard .left .headSection {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #fff;
}

.dashboard .left .otherContentSection {
  width: 60%;
  /* height: 66%; */
  display: flex;
  flex-direction: column;
  padding-bottom: 453px;
}

.dashboard .left .otherContentSection .menuOptionsText {
  color: #fff;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-top: 22px;
}
.dashboard .left .otherContentSection .menuOptionsText:hover {
  text-decoration: underline;
  cursor: pointer;
  /* text-decoration-color: black; 
  text-decoration-style: underline; 
  color: inherit; */
}

.dashboard .left .profileDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  width: 100%;
  gap: 10px;
  padding: 0px 10px 10px 0px;
  cursor: pointer;
}
.dashboard .left .profileDiv:hover {
  background: transparent !important;
}

.dashboard .left .innerProfileDiv {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: #d9d9d9;
}

.dashboard .left .profileDiv .profileDivText {
  color: #fff;
  font-family: "Poppins";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.dashboard .right {
  width: 80%;
  background: "#FFFFFF";
}

.dashboard .right .headSection {
  padding: 20px 50px 28px 5px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #3eb095;
}
.dashboard .right .headSection .headInnerFirst {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.dashboard .right .headSection h3 {
  color: #000;
  margin-left: 20px;
}

.dashboard .right .headSection .headInnerFirst .selectTabText {
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  margin-left: 20px;
}
.dashboard .right .otherContentSection {
  width: 99%;
  display: flex;
  flex-direction: column;
}

.dashboard .right .headSection .buttonsParentDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.dashboard .right .headSection .buttonContact {
  width: 192px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard .right .headSection .buttonContact .text {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  margin: 0;
}

/* Below buttons are for broad cast */

.dashboard .right .headSection .buttonBroadcaseOne {
  width: 192px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dashboard .right .headSection .buttonBroadcaseOne .text {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}

.dashboard .right .headSection .buttonBroadcase {
  width: 192px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.dashboard .right .headSection .buttonBroadcase .text {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin: 0;
}

/* SearchBar for LiveChat */

.dashboard .right .headSection .liveChatSearch {
  width: 315px;
  padding-left: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}
.dashboard .right .headSection .liveChatSearch .inputStyle {
  width: 100%;
  border: none;
  outline: none;
  padding: 5px 5px 5px 20px;
}

.dashboard .right .headSection .liveChatSearch .inputStyle::placeholder {
  color: "grey";
  font-family: "Poppins";
  font-size: 15px;
  margin: 0;
  font-weight: 400;
}
/*----------------------------------- Growth Tool Modal ------------------------*/
/* .modal-content {
  width: 120% !important;
} */
.modal-body {
  background: #f6f7f9;
}
.growth-modal-header {
  /* background: red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.growth-modal-header .right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.growth-modal-header .right label {
  display: flex;
  align-items: center;
  gap: 5px;
}
.growth-modal-header .right label p {
  margin-bottom: 0;
  font-size: 13px;
}
.growth-modal-header .right label p:hover {
  color: #0084ff !important;
}
.growth-modal-header .right .scratch-btn button {
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  color: #0084ff;
  background: transparent;
}
.growth-modal-header .right .scratch-btn button:hover {
  background: #f1f3f5;
  border: 1px solid #d9d9d9;
}
.growth-modal-body {
  width: 100%;
}
.growth-modal-body input {
  width: 100%;
  padding-left: 20px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.growth-modal-body input:hover {
  border-color: #0084ff;
}
.growth-modal-body .all-templates {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.growth-modal-body .all-templates .capture {
  width: 250px;
  padding: 30px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;
}
.growth-modal-body .all-templates .capture:hover {
  border-color: #0084ff;
}
.growth-modal-body .all-templates .capture img {
  width: 100%;
}
.growth-modal-body .all-templates .capture h6 {
  font-size: 15px;
  margin-top: 20px;
}
.growth-modal-body .all-templates .capture h6:hover {
  color: #0084ff;
}
.growth-modal-body .all-templates .capture .two {
  gap: 10px;
  align-items: center;
}
.growth-modal-body .all-templates .capture .two .left {
  gap: 10px;
  align-items: center;
  color: #6a7589;
}
.growth-modal-body .all-templates .capture .two .left span {
  padding-left: 10px;
}
@media screen and (max-width:1500px) {
  .growth-modal-body .all-templates {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    place-items: center;
}

}
@media screen and (max-width:1200px) {
  .growth-modal-body .all-templates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    place-items: center;
}
}
@media screen and (max-width: 1000px) {
  .growth-modal-body .all-templates {
    grid-template-columns: repeat(1, 1fr);
}
  .dashboard .left {
    background-color: #3eb095;
    width: 240px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s linear;
    z-index: 1;
    position: fixed;
    transform: translateX(0);
  }

  .dashboard .right {
    width: 100%;
  }
  .dashboard .right .headSection {
    padding: 22px 10px !important;
    gap: 15px;
  }
  .dashboard .right .headSection h3 {
    color: #000;
    font-size: 18px;
  }

  .dashboard .left .otherContentSection .menuOptionsText {
    font-size: 16px;
  }
  .dashboard .left .otherContentSection .menuOptionsText:hover {
    font-size: 18px;
  }

  .dashboard .right .headSection .headInnerFirst .selectTabText {
    font-size: 18px;
    margin-left: 8px;
  }
  .dashboard .right .headSection .buttonContact {
    width: 35%;
    height: 35px;
    padding: 2px;
  }
  .dashboard .right .headSection .buttonContact .text {
    font-size: 11px;
  }
  .dashboard .right .headSection .liveChatSearch .inputStyle {
    padding: 2px 2px 2px 5px;
  }
  .dashboard .right .headSection .liveChatSearch .inputStyle::placeholder {
    font-size: 9px;
  }

  .dashboard .right .headSection .buttonsParentDiv {
    width: 60%;
    flex-direction: column;
    gap: 6px;
  }
  .dashboard .right .headSection .buttonBroadcaseOne {
    width: 100%;
    height: 35px;
    padding: 2px;
  }

  .dashboard .right .headSection .buttonBroadcaseOne .text {
    font-size: 10px;
    line-height: 1;
  }
  .dashboard .right .headSection .buttonBroadcase .text {
    font-size: 10px;
    line-height: 1;
  }

  .dashboard .right .headSection .buttonBroadcase {
    width: 100%;
    height: 35px;
    padding: 2px;
  }
}

@media screen and (max-width:600px) {
  .growth-modal-header h4{
    font-size: 23px;
  }
  .growth-modal-header{
    flex-direction: column;
  }
  .growth-modal-header .right {
    gap: 20px;
    margin-top: 10px;
}
  .growth-modal-header .right label {
    align-items: flex-start;
}
  .dashboard .left {
    height: 100%;
  }
  .dashboard .left .otherContentSection {
    padding-bottom: 22px;
  }
  .dashboard .left .profileDiv{
    padding: 0px 10px 10px 8px;
  }
  .growth-modal-body .all-templates {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboard .left .headSection {
  padding: 10px ;
}
}
