.blog .bloghead {
  padding: 174px 233px 74px 233px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blog .bloghead h2 {
  color: #000;
  text-align: center;
  font-family: "Poppins";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 30px;
}
.blog .bloghead img {
  width: 100%;
}
.blog .mid {
  /* padding: 55px 365px; */
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
}
.blog .mid .first {
  width: 32%;
  display: flex;
  padding-right: 11px;
}
.blog .mid .first img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}
.blog .mid .first p {
  color: #000;
  font-family: "Poppins";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 15px;
}
.blog .mid .second {
  width: 63%;
}
.blog .mid .second h2 {
  color: #000;
  font-family: "Poppins";
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 30px 0px;
  display: flex;
}
.blog .mid .second p,
ul,
li {
  color: #000;
  font-family: "Poppins";
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.blog .mid .second ul li span {
  font-weight: 600;
}

.blog .mid .third {
  width: 7%;
  display: flex;
  flex-direction: column;
  padding-left: 35px;
  align-items: center;
}
.blog .mid .third a img {
  padding: 20px;
  padding-right: 0px;
}
.blog .mid .second img {
  width: 100%;
}

@media screen and (max-width: 1700px) {
  .blog .bloghead h2 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1500px) {
  .blog .bloghead h2 {
    font-size: 35px;
    width: 100%;
  }
  .blog .mid {
    margin: auto;
    width: 70%;
  }
  .blog .bloghead {
    padding: 174px 200px 74px 200px;
  }
}
@media screen and (max-width: 1300px) {
  .blog .bloghead h2 {
    font-size: 28px;
    width: 100%;
  }
  .blog .bloghead {
    padding: 174px 100px 74px 100px;
  }
  .blog .mid {
    width: 80%;
  }
  .blog .mid .third {
    padding-left: 0px;
  }
  .blog .mid .first img {
    width: 70px;
    height: 70px;
  }
  .blog .mid .first {
    display: flex;
  }
  .blog .mid .first p {
    font-size: 13px;
  }
  .blog .mid .second p,
  ul,
  li {
    font-size: 13px;
  }
  .blog .mid .second h2 {
    font-size: 18px;
    padding: 20px 0px;
  }
}
@media screen and (max-width: 600px) {
  .blog .mid .first p {
    font-size: 11px;
    padding-top: 10px;
  }
  .blog .bloghead h2 {
    font-size: 18px;
    width: 100%;
    padding-bottom: 5px;
  }
  .blog .bloghead {
    padding: 23px;
  }
  .blog .mid {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 23px;
  }
  .blog .mid .first {
    width: 100%;
  }
  .blog .mid .second {
    width: 100%;
  }
  .blog .mid .third {
    width: 100%;
  }
  .blog .mid .second h2 {
    font-size: 13px;
    font-weight: 500;
    padding: 8px 0px;
  }
  .blog .mid .second p,
  ul,
  li {
    font-size: 13px;
    font-weight: 300;
  }
  .blog .mid .third {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }
}
